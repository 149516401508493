import { IDateRange } from 'src/app/shared/Interfaces/DateRange';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { pausable, PausableObservable } from 'rxjs-pausable';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { DEFAULT_CONFIG } from '@angular/flex-layout';
import { HttpHeaders } from '@angular/common/http';
import { actions, keyItems } from '@app-core/services/helpers/config-data';
import { isDefined, getStartDayOfWeek, getEndDayOfWeek } from '@app-core/services/helpers/helpers';
import _ from 'lodash';
import { TopicFilter } from 'src/app/features/analyze/analyze/models/kid-filter.interface';

export class QuoteModel {
  quote: String;
  date: string;

  constructor(quote: String, date: string) {
    this.quote = quote;
    this.date = date;
  }
}

interface GradeSelected {
  grade_id: any;
  subject_id: number;
  subject_name: string;
  work_id: number;
  work_name: string;
}

// Each property can be overridden by the consumer
interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() toggledFullscreen = new EventEmitter();
  @Output() saveChangesOfChild = new EventEmitter();

  @Output() onGradeSelected = new EventEmitter();
  @Output() onAnalyzeGradeSelected = new EventEmitter();
  @Output() onAnalyzeWorkSelected = new EventEmitter();
  @Output() onAnalyzeTaskRedo = new EventEmitter();

  // @Output() onSelectDateRange = new EventEmitter();
  onSelectDateRange = new BehaviorSubject<IDateRange>(this.getDateRange());
  onSelectCategory = new BehaviorSubject<string>(this.getCategory().name);

  @Output() onSelectGroups = new EventEmitter();
  @Output() onSelectTopics = new EventEmitter();
  @Output() onClickGroup = new EventEmitter();

  @Output() onSelectTopic = new EventEmitter();
  @Output() onWeeklySelectSubject = new EventEmitter();

  @Output() onTodoInserted = new EventEmitter();
  @Output() onReflectionInserted = new EventEmitter();
  @Output() refreshNotification = new EventEmitter();
  @Output() onTaskChange = new EventEmitter();
  @Output() onNextVariation = new EventEmitter();
  @Output() onVariationChange = new EventEmitter();

  @Output() refreshTopicChange = new EventEmitter();
  @Output() refreshTagChange = new EventEmitter();
  @Output() refreshPhaseChange = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  @Output() onActiveDemoPage = new EventEmitter();
  @Output() onClosePhase = new EventEmitter();

  @Output() onBookmarkChange = new EventEmitter();

  @Output() onSearchKidChange = new EventEmitter();
  @Output() onScrollBottom = new EventEmitter();

  @Output() refreshColleague = new EventEmitter();
  @Output() protocolModified = new EventEmitter();

  kidIdChanged = new BehaviorSubject(null);
  kidDataChanged = new BehaviorSubject(null);
  kidWeeklyEvaluationChanged = new BehaviorSubject(null);

  achievementsDurationSelected = new BehaviorSubject(null);
  selectedPeriodTimeChanged = new BehaviorSubject<boolean>(null);

  topicSelected = new BehaviorSubject(null);

  notificationNumber = 0;
  activeDemoPage: string;
  isDemoActive = JSON.parse(localStorage.getItem('isDemoActive'));
  quoteList: QuoteModel[] = [];
  private daysOfTheWeeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
  gradeSelected: GradeSelected;
  paused = true;
  pausable: PausableObservable<number>;

  analyzeGrade: string;
  analyzeWork: number;
  category: string;
  startDate: string;
  endDate: string;
  analyzeTopic: number;

  notificationVariationId: number;

  kidIdForWorks: number;

  constructor(private overlay: Overlay) {
    this.getAnalyzeGradeWork();
    if (localStorage.getItem(keyItems.grade_selected)) {
      this.gradeSelected = JSON.parse(localStorage.getItem(keyItems.grade_selected));
    }
    this.notificationNumber = parseInt(localStorage.getItem('notifications'));
    this.pausable = interval(800).pipe(pausable()) as PausableObservable<number>;
    this.pausable.subscribe(this.shoot.bind(this));
    this.pausable.pause();
  }

  open(config: FilePreviewDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Instantiate remote control
    return this.createOverlay(dialogConfig);
  }

  private createOverlay(config: FilePreviewDialogConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }
  private getOverlayConfig(config: FilePreviewDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  /**
   * Confetti section
   */
  toggle() {
    if (this.paused) {
      this.pausable.resume();
    } else {
      this.pausable.pause();
    }
    this.paused = !this.paused;
  }

  shoot() {
    try {
      this.confetti({
        angle: this.random(60, 120),
        spread: this.random(10, 50),
        particleCount: this.random(40, 50),
        origin: {
          y: 0.6
        }
      });
    } catch (e) {
      // noop, confettijs may not be loaded yet
    }
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confetti(args: any) {
    return window['confetti'].apply(this, arguments);
  }

  addNewQuote(quote: String) {
    const date = new Date();
    const dayOfTheWeek = this.daysOfTheWeeks[date.getDate()];
    const day = date.getDay();
    const year = date.getFullYear();
    this.quoteList.push(new QuoteModel(quote, `${dayOfTheWeek} ${day}, ${year}`));
  }

  getQuote() {
    return this.quoteList;
  }

  removeQuote(index) {
    this.quoteList.splice(index, 1);
  }

  shareGradeSelected(gradeSelected) {
    this.saveGradeSelected(gradeSelected);
    this.handleAnalyzeGradeWorkSelected();

    this.onGradeSelected.emit(gradeSelected);
  }
  saveGradeSelected(gradeSelected) {
    this.gradeSelected = gradeSelected;
    localStorage.setItem(keyItems.grade_selected, JSON.stringify(this.gradeSelected));
  }
  handleAnalyzeGradeWorkSelected() {
    this.saveAnalyzeGradeSelected({ grade: this.gradeSelected.grade_id });
    this.saveAnalyzeWorkSelected({
      work_id: this.gradeSelected.work_id,
      name: this.gradeSelected.work_name
    });
  }
  getAnalyzeGradeWork() {
    this.analyzeGrade = localStorage.getItem(keyItems.analyzeGrade);
    this.analyzeWork = localStorage.getItem(keyItems.analyzeWork) ? parseInt(localStorage.getItem(keyItems.analyzeWork)) : null;
  }

  analyzeGradeSelected(analyzeGrade) {
    this.saveAnalyzeGradeSelected(analyzeGrade);
    this.handelWorkAlreadySelected(analyzeGrade);
    this.onAnalyzeGradeSelected.emit(analyzeGrade);
  }
  saveAnalyzeGradeSelected(analyzeGrade) {
    this.analyzeGrade = analyzeGrade.grade;
    localStorage.setItem(keyItems.analyzeGrade, this.analyzeGrade);
  }
  handelWorkAlreadySelected(analyzeGrade) {
    if (this.analyzeWork) {
      const workIndex = _.findIndex(analyzeGrade.works, ['work_id', this.analyzeWork]);
      if (workIndex === -1) {
        this.removeAnalyzeWorkSelected();
      }
      this.handelQAGradeSelected();
    }
  }
  analyzeWorkSelected(analyzeWork) {
    this.saveAnalyzeWorkSelected(analyzeWork);
    this.handelQAGradeSelected();
    this.onAnalyzeWorkSelected.emit(analyzeWork);
  }
  handelQAGradeSelected() {
    const gradeSelected = {
      grade_id: this.analyzeGrade,
      subject_id: null,
      subject_name: null,
      work_id: this.analyzeWork,
      work_name: this.category
    };
    this.saveGradeSelected(gradeSelected);
  }
  saveAnalyzeWorkSelected(work) {
    this.analyzeWork = work.work_id;
    this.category = work.name;
    localStorage.setItem(keyItems.analyzeWork, JSON.stringify(this.analyzeWork));
    localStorage.setItem(keyItems.category, this.category);
    this.onSelectCategory.next(this.category);
  }

  getCategory() {
    return {
      work_id: localStorage.getItem(keyItems.analyzeWork),
      name: localStorage.getItem(keyItems.category)
    };
  }

  activateDemo(steps) {
    this.onActiveDemoPage.emit(steps);
  }

  changeStatusOfSidebar() {
    this.saveChangesOfChild.emit();
  }

  closePhaseInfo(status) {
    this.onClosePhase.emit(status);
  }

  removeGradeSelected() {
    this.gradeSelected = null;
    localStorage.removeItem(keyItems.grade_selected);
    this.removeAnalyzeGradeSelected();
    this.removeAnalyzeWorkSelected();
    this.onGradeSelected.emit(null);
  }

  removeAnalyzeGradeSelected() {
    this.analyzeGrade = null;
    localStorage.removeItem(keyItems.analyzeGrade);
  }
  removeAnalyzeWorkSelected() {
    this.analyzeWork = null;
    this.category = null;
    localStorage.removeItem(keyItems.analyzeWork);
    localStorage.removeItem(keyItems.category);
  }

  selectDateRange(dateRange: IDateRange) {
    this.onSelectDateRange.next(dateRange);
    this.startDate = dateRange.startDate;
    this.endDate = dateRange.endDate;
    localStorage.setItem(keyItems.dateRange, JSON.stringify({ startDate: this.startDate, endDate: this.endDate }));
  }

  getDateRange() {
    let startDate, endDate: string;
    const dateRange = JSON.parse(localStorage.getItem(keyItems.dateRange));

    if (isDefined(dateRange)) {
      startDate = dateRange.startDate;
      endDate = dateRange.endDate;
    } else {
      startDate = getStartDayOfWeek(new Date());
      endDate = getEndDayOfWeek(new Date());
    }

    return { startDate, endDate };
  }

  selectGroup(group) {
    this.onSelectGroups.emit(group);
  }

  clickGroup(group) {
    this.onClickGroup.emit(group);
  }

  selectTopic(type) {
    this.onSelectTopic.emit(type);
  }

  manageNotification(action: string, notificationNumber?: number) {
    if (action === actions.remove) {
      localStorage.removeItem('notifications');
      this.notificationNumber = 0;
    }
    if (action === actions.update) {
      localStorage.setItem('notifications', JSON.stringify(notificationNumber));
      this.notificationNumber = parseInt(localStorage.getItem('notifications'));
    }
  }
  getHttpOptions(params?) {
    return {
      headers: new HttpHeaders({}),
      params: params
    };
  }

  bookmarkListChange() {
    this.onBookmarkChange.emit();
  }
}
