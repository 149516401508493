import { importanceType } from 'src/app/shared/models/importance';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppService } from '@app-core/services/app.service';

@Component({
  selector: 'app-self-reflection',
  templateUrl: './self-reflection.component.html',
  styleUrls: ['./self-reflection.component.scss']
})
export class SelfReflectionComponent implements OnInit {
  kidTaskRating = 0;
  stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  readonly importanceType = importanceType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService, private dialogRef: MatDialogRef<SelfReflectionComponent>) {}

  ngOnInit() {}

  onConfirmClick(): void {
    this.markAsRecorrected();
  }

  onSelectRateStarClick(star) {
    if (this.kidTaskRating === star) {
      this.kidTaskRating = star - 1;
    } else {
      this.kidTaskRating = star;
    }
  }

  markAsRecorrected() {
    this.finishTask();
    const reflectModel = {
      self_rating: null,
      kid_comment: null,
      feel_safe: false,
      kid_task_rating: this.kidTaskRating
    };
    this.appService.newElement(`/kid/solutions/tasks/${this.data.taskId}/reflect`, reflectModel).subscribe(response => {
      this.dialogRef.close('finishTask');
    });
  }

  finishTask() {
    this.appService.newElement(`/kid/solutions/tasks/${this.data.taskId}/finish`, {}).subscribe();
  }
}
