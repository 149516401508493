import { animate, query, style, transition, trigger, stagger, sequence, group, state, animateChild } from '@angular/animations';
import { AnimationsService } from './animation.service';

export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';
const LEAVING_SPEED = '0.2s';
const ENTERING_SPEED = '0.3s';

const ELEMENTS_LEAVING_SPEED = '0.5s';
const ELEMENTS_ENTERING_SPEED = '0.8s';

const STEPS_ALL: any[] = [
  query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
    optional: true
  }),
  query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({ opacity: 0 }), {
    optional: true
  }),
  sequence([
    query(
      ':leave > *',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate(LEAVING_SPEED + ' ease-in-out', style({ transform: 'translateY(-3%)', opacity: 0 })),
        style({ position: 'fixed' })
      ],
      { optional: true }
    ),
    query(
      ':enter > *',
      [
        style({
          transform: 'translateY(-3%)',
          opacity: 0,
          position: 'static'
        }),
        animate(ENTERING_SPEED + ' ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ],
      { optional: true }
    )
  ]),
  query(
    ':enter .' + ROUTE_ANIMATIONS_ELEMENTS,
    stagger(75, [
      style({ transform: 'translateY(10%)', opacity: 0 }),
      animate(ENTERING_SPEED + ' ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
    ]),
    { optional: true }
  )
];
const STEPS_NONE = [];
const STEPS_PAGE = [STEPS_ALL[0], STEPS_ALL[2]];
const STEPS_ELEMENTS = [STEPS_ALL[1], STEPS_ALL[3]];

export const routeAnimations = trigger('routeAnimations', [
  transition(isRouteAnimationsAll, STEPS_ALL),
  transition(isRouteAnimationsNone, STEPS_NONE),
  transition(isRouteAnimationsPage, STEPS_PAGE),
  transition(isRouteAnimationsElements, STEPS_ELEMENTS)
]);

export function isRouteAnimationsAll() {
  return AnimationsService.isRouteAnimationsType('ALL');
}

export function isRouteAnimationsNone() {
  return AnimationsService.isRouteAnimationsType('NONE');
}

export function isRouteAnimationsPage() {
  return AnimationsService.isRouteAnimationsType('PAGE');
}

export function isRouteAnimationsElements() {
  return AnimationsService.isRouteAnimationsType('ELEMENTS');
}

const routeAnimationsArray = [];
const MAX_DEEPTH = 10;

for (let i = 0; i < MAX_DEEPTH; i++) {
  routeAnimationsArray.push(transition(`${i} => ${i + 1}`, slideTo('right')), transition(`${i + 1} => ${i}`, slideTo('left')));
}

export const slider = trigger(
  'routeAnimations',
  routeAnimationsArray
  // [
  // transition('* => isLeft', slideTo('left')),
  // transition('* => isRight', slideTo('right')),
  // transition('isRight => *', slideTo('left')),
  // transition('isLeft => *', slideTo('right'))
  // ]
);

function slideTo(direction) {
  const optional = { optional: false };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: '80px',
          [direction]: 0,
          width: '100%'
        })
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([
      query(':leave', [animate('200ms ease', style({ [direction]: '100%' }))], optional),
      query(':enter', [animate('200ms ease', style({ [direction]: '0%' }))])
    ])
    // Normalize the page style... Might not be necessary

    // Required only if you have child animations on the page
    // query(':leave', animateChild()),
    // query(':enter', animateChild()),
  ];
}

export const elementsAnimations = [
  trigger('items', [
    // transition(':enter', [
    //   style({ transform: 'scale(0.5)', opacity: 0 }), // initial
    //   animate(
    //     ELEMENTS_ENTERING_SPEED + ' cubic-bezier(.8, -0.6, 0.2, 1.5)',
    //     style({ transform: 'scale(1)', opacity: 1 })
    //   ) // final
    // ]),
    // transition(':leave', [
    //   style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    //   animate(
    //     ELEMENTS_LEAVING_SPEED + ' cubic-bezier(.8, 0, 0.2, 1.5)',
    //     // '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
    //     style({
    //       transform: 'scale(0.5)',
    //       opacity: 0,
    //       height: '0px'
    //       // margin: '0px' //margin doesn't work on edge and firefox
    //     })
    //   )
    // ])
  ]),
  trigger('list', [
    // transition(':enter', [query('@items', stagger(100, animateChild()))])
  ])
];

export const newElementsAnimations = [
  trigger('items', [
    transition(':enter', [
      style({ transform: 'scale(0.5)', opacity: 0 }), // initial
      animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })) // final
    ]),
    transition(':leave', [
      style({ transform: 'scale(1)', opacity: 1, height: '*' }),
      animate(
        '0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
        // '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          transform: 'scale(0.5)',
          opacity: 0,
          height: '0px'
          // margin: '0px' //margin doesn't work on edge and firefox
        })
      )
    ])
  ]),
  trigger('list', [transition(':enter', [query('@items', stagger(100, animateChild()), { optional: true })])])
];
export const FadeInFadeOut = [
  trigger('enterAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))])
  ])
];

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),

    query(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))], { optional: true }),

    query(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))], { optional: true })
  ])
]);
// export const SlideAnimation = [
//   trigger('slideAnimation', [
//     transition(':increment', group([
//       query(':enter', [
//         style({
//           transform: 'translateX(100%)',
//           position: 'fixed'
//         }),
//         animate('0.5s ease-out', style({ position: 'fixed'}))
//       ]),
//       query(':leave', [
//         animate('0.5s ease-out', style({
//           transform: 'translateX(-100%)',
//           position: 'fixed'
//         }))
//       ])
//     ])),
//     transition(':decrement', group([
//       query(':enter', [
//         style({
//           transform: 'translateX(-100%)',
//           position: 'fixed'
//         }),
//         animate('0.5s ease-out', style({ position: 'fixed'}))
//       ]),
//       query(':leave', [
//         animate('0.5s ease-out', style({
//           transform: 'translateX(100%)',
//           position: 'fixed'
//         }))
//       ])
//     ]))
//   ])]
//   ;
export const slideAnimation = trigger('slideAnimation', [
  transition(
    ':increment',
    group([
      query(':enter', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('0.5s ease-out', style('*'))
      ]),
      query(':leave', [
        animate(
          '0.5s ease-out',
          style({
            transform: 'translateX(-100%)'
          })
        )
      ])
    ])
  ),
  transition(
    ':decrement',
    group([
      query(':enter', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('0.5s ease-out', style('*'))
      ]),
      query(':leave', [
        animate(
          '0.5s ease-out',
          style({
            transform: 'translateX(100%)'
          })
        )
      ])
    ])
  )
]);

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden'
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px'
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ])
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px'
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ])
];

// horizontal slide animation
const statesSlidedIn = [state('fromLeft', style({})), state('fromRight', style({}))];
const styleSlidedLeft = style({
  transform: 'translateX(-100%)',
  display: 'none'
});
const styleSlidedRight = style({
  transform: 'translateX(100%)',
  display: 'none'
});
const stateSlidedLeft = state('left', styleSlidedLeft);
const stateSlidedRight = state('right', styleSlidedRight);
const transitionsSlideLeft = [
  transition('fromLeft => void', animate('.3s ease-out', styleSlidedRight)),
  transition('void => fromLeft', [styleSlidedLeft, animate('.3s ease-out')])
];
const transitionsSlideRight = [
  transition('fromRight => void', animate('.3s ease-out', styleSlidedLeft)),
  transition('void => fromRight', [styleSlidedRight, animate('.3s ease-out')])
];
export const slideHorizontal = trigger('slideHorizontal', [
  ...statesSlidedIn,
  stateSlidedLeft,
  stateSlidedRight,
  ...transitionsSlideLeft,
  ...transitionsSlideRight
]);

export const testAnimation = trigger('testAnimation', [
  // One time initial load. Move page from left -100% to 0%
  transition('-1 => *', [
    query(':enter', [
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(-100%)'
      }),
      animate(
        '500ms ease',
        style({
          opacity: 1,
          transform: 'translateX(0%)'
        })
      )
    ])
  ]),

  // Previous, slide left to right to show left page
  transition(':decrement', [
    // set new page X location to be -100%
    query(
      ':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(-100%)'
      })
    ),

    group([
      // slide existing page from 0% to 100% to the right
      query(
        ':leave',
        animate(
          '500ms ease',
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(100%)'
          })
        )
      ),
      // slide new page from -100% to 0% to the right
      query(
        ':enter',
        animate(
          '500ms ease',
          style({
            opacity: 1,
            transform: 'translateX(0%)'
          })
        )
      )
    ])
  ]),

  // Next, slide right to left to show right page
  transition(':increment', [
    // set new page X location to be 100%
    query(
      ':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(100%)'
      })
    ),

    group([
      // slide existing page from 0% to -100% to the left
      query(
        ':leave',
        animate(
          '500ms ease',
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(-100%)'
          })
        )
      ),
      // slide new page from 100% to 0% to the left
      query(
        ':enter',
        animate(
          '500ms ease',
          style({
            opacity: 1,
            transform: 'translateX(0%)'
          })
        )
      )
    ])
  ])
]);
