import { Injectable } from '@angular/core';
import { AppService } from '@app-core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { exportExcel } from '@app-core/services/helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class ParentsExportService {
  constructor(private appService: AppService, private translate: TranslateService) {}

  onParentsExport(url: String) {
    let newHeader: any = {};
    const kidsObj = {};
    const schoolObj = {};
    let rawHeader = {};
    const parentInfos = {};
    let parentData: any = [];
    const header = {
      parentId: 'ID',
      first_name: this.translate.instant('general.firstname'),
      last_name: this.translate.instant('general.lastname'),
      email: this.translate.instant('general.email'),
      terms_n_cond: this.translate.instant('general.terms_and_conditions'),
      add_permission: this.translate.instant('general.advertisement')
    };

    this.appService.getAll(url).subscribe(res => {
      res.data.map(info => {
        info.terms_n_cond === 1 ? (info.terms_n_cond = this.translate.instant('general.yes')) : (info.terms_n_cond = this.translate.instant('general.no'));

        info.add_permission === 1
          ? (info.add_permission = this.translate.instant('general.yes'))
          : (info.add_permission = this.translate.instant('general.no'));

        if (info.kids.length) {
          info.kids.map(kid => (kid.name = kid.first_name + ' ' + kid.last_name));
        }

        for (let i = 0; i < info.kids.length; i++) {
          const kidsKey = `linked_kid_account${i}`;
          const schoolKey = `school_name${i}`;
          kidsObj[kidsKey] = this.translate.instant('general.linked_kid_account');
          schoolObj[schoolKey] = this.translate.instant('general.school_name');

          rawHeader = Object.assign(header, kidsObj);
          newHeader = Object.assign(rawHeader, schoolObj);

          info.kids.map((kid, index) => {
            const myKidsKey = `linked_kid_account${index}`;
            const kidSchoolKey = `school_name${index}`;
            parentInfos[myKidsKey] = kid.name;
            parentInfos[kidSchoolKey] = kid.school;
          });

          parentData = Object.assign(info, parentInfos);
        }
      });
      exportExcel(this.translate.instant('activities.parents'), res.data, newHeader);
    });
  }
}
