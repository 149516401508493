import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CANCEL } from 'src/app/features/admin/models/admin.config';

@Component({
  selector: 'app-oidc-connect-info-dialog',
  templateUrl: './oidc-connect-info-dialog.component.html',
  styleUrls: ['./oidc-connect-info-dialog.component.scss']
})
export class OidcConnectInfoDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<OidcConnectInfoDialogComponent>) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close({ action: CANCEL });
  }
}
