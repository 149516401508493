import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { SharedService } from '@shared/services/shared.service';
import { Observable } from 'rxjs';

export class KidWorksInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.sharedService.kidIdForWorks) {
      return next.handle(req);
    }

    const cloneReq = req.clone({
      params: !req.params.get('kidId') ? req.params.set('kidId', this.sharedService.kidIdForWorks.toString()) : req.params
    });
    return next.handle(cloneReq);
  }
}
