import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }
}

export function positiveNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isNotOk = Number(control.value) < 0 || Number(control.value) > 1000000;
    return isNotOk ? { nonPositive: { value: control.value } } : null;
  };
}

export function integerNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isNotOk = !Number.isInteger(control.value) || Number(control.value) < 0;
    return isNotOk ? { nonIntegerPositive: { value: control.value } } : null;
  };
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let isNotOk = false; // this line makes fields optional and not required

    if (control.value) {
      const value = control.value.toString();
      isNotOk = value.length < 5 || value.length > 15 || !value.match(/^\+?\d+$/);
    }
    return isNotOk ? { nonIntegerPositive: { value: control.value } } : null;
  };
}

export function isAlpha(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isNotOk = /[^a-zA-Z]/.test(control.value);
    return isNotOk ? { nonAlpha: { value: control.value } } : null;
  };
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}
