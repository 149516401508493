import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { KidGoalsDialogComponent } from '../dialogs/kid-goals-dialog/kid-goals-dialog.component';
import { KidGoalsComponent } from './kid-goals.component';
import { KidGoalComponent } from './kid-goal/kid-goal.component';
import { NewUpdateKidGoalComponent } from './kid-goal/new-update-kid-goal/new-update-kid-goal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FileUploaderModule } from '@shared/features/file-uploader/file-uploader.module';

@NgModule({
  declarations: [KidGoalsDialogComponent, KidGoalsComponent, KidGoalComponent, NewUpdateKidGoalComponent],
  exports: [KidGoalsDialogComponent, KidGoalsComponent, KidGoalComponent, NewUpdateKidGoalComponent],
  entryComponents: [KidGoalsDialogComponent],
  imports: [CommonModule, SharedModule, FileUploaderModule]
})
export class KidGoalsModule {}
