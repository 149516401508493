import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatMenuTrigger } from '@angular/material';
import { eachDay, startOfWeek, endOfWeek, addDays, subDays } from 'date-fns';
import { FormControl, Validators } from '@angular/forms';
import { getSafe, isDefined } from '@app-core/services/helpers/helpers';
import { noWhitespaceValidator } from '@app-core/validators/custom-validators';

@Component({
  selector: 'app-add-todo-kid-dialog',
  templateUrl: './add-todo-kid-dialog.component.html',
  styleUrls: ['./add-todo-kid-dialog.component.scss']
})
export class AddTodoKidDialogComponent implements OnInit {
  @ViewChild('descriptionField', { static: false })
  descriptionField: ElementRef<HTMLInputElement>;
  @ViewChild('emojiMenuTrigger', { static: false })
  emojiMenuTrigger: MatMenuTrigger;

  daysOfWeek = [];
  dateSelected: Date;
  isWeeklyGoal = false;
  description = new FormControl(null, [Validators.required, noWhitespaceValidator()]);

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<AddTodoKidDialogComponent>) {
    if (data.id) {
      this.description.setValue(data.description);
      this.isWeeklyGoal = data.is_weekly_goal;
      // this.dateSelected = data.date;
      // this.getThisWeek();
    } else if (data.redirectUrl) {
      this.isWeeklyGoal = data.is_weekly_goal;
    }
  }

  ngOnInit() {}
  getThisWeek() {
    this.daysOfWeek = eachDay(startOfWeek(this.dateSelected), endOfWeek(this.dateSelected));
  }

  onFilterTodos(filter) {}
  onToggleTodo(todo) {
    todo.done = !todo.done;
  }
  onConfirm() {
    if (this.description.valid) {
      this.bottomSheetRef.dismiss({
        is_weekly_goal: this.isWeeklyGoal,
        description: this.description.value
      });
    } else {
      this.description.markAsTouched();
    }
  }
  onCancel() {
    this.bottomSheetRef.dismiss();
  }

  nextWeek() {
    this.dateSelected = addDays(this.dateSelected, 7);
    this.daysOfWeek = eachDay(startOfWeek(this.dateSelected), endOfWeek(this.dateSelected));
  }

  previousWeek() {
    this.dateSelected = subDays(this.dateSelected, 7);
    this.daysOfWeek = eachDay(startOfWeek(this.dateSelected), endOfWeek(this.dateSelected));
  }
  onEmojiAdd(event) {
    let note = this.description.value || '';
    const selectionStart = getSafe(() => this.descriptionField.nativeElement.selectionStart);
    if (isDefined(selectionStart)) {
      note = `${note.substring(0, selectionStart)}${event.emoji.native}${note.substring(selectionStart, note.length)}`;
    } else {
      note = `${note}${event.emoji.native}`;
    }
    this.description.setValue(note);
    this.emojiMenuTrigger.closeMenu();
  }
}
