import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '@app-core/services/app.service';
import { Resource } from '@shared/models/item.model';
import { KidGoal, KidGoals } from '../../../models/overview.model';

@Component({
  selector: 'app-kid-goals-dialog',
  templateUrl: './kid-goals-dialog.component.html',
  styleUrls: ['./kid-goals-dialog.component.scss']
})
export class KidGoalsDialogComponent implements OnInit {
  showGoalForm = false;
  kidGoals = {} as KidGoals;
  goalSelected: KidGoal;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<KidGoalsDialogComponent>, private appService: AppService) {}

  ngOnInit() {
    this.getGoals();
  }

  onClose() {
    this.dialogRef.close();
  }

  getGoals() {
    this.appService.getAll('/kid-goals', { isCompleted: 0 }).subscribe((response: Resource<KidGoals>) => {
      this.kidGoals = response.data || ({} as KidGoals);
    });
  }

  refreshGoalList() {
    this.showGoalForm = false;
    this.goalSelected = null;
    this.getGoals();
  }
}
