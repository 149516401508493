import { Toast, SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { storageKeys, styleClass } from '@app-core/services/helpers/config-data';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './../../services/app.service';
import { MenuService } from '@app-core/services/menu/menu.service';
import { isLead, getStorageObjectItem } from '@app-core/services/helpers/helpers';
import { Component, OnInit } from '@angular/core';
import { routeAnimations } from '@app-core/animations/route.animations';
import { MatDrawer, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SettingsService } from '@app-core/settings/settings.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AddColleagueDialogComponent } from 'src/app/features/admin/dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { Colleague } from 'src/app/features/admin/manage-schools/new-entity/colleagues/colleagues/colleagues.component';

@Component({
  selector: 'app-quick-access-layout',
  templateUrl: './quick-access-layout.component.html',
  styleUrls: ['./quick-access-layout.component.scss'],
  animations: [routeAnimations]
})
export class QuickAccessLayoutComponent implements OnInit {
  readonly isLead = isLead();
  readonly school = getStorageObjectItem(storageKeys.school);

  isWithFold = true;
  isUnfolded = false;
  menuItems = [];
  isComputer: boolean;

  isFilterLoaded = false;

  constructor(
    public router: Router,
    public sharedService: SharedService,
    private menuService: MenuService,
    private deviceService: DeviceDetectorService,
    public settingsService: SettingsService,
    private appService: AppService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.menuItems = this.menuService.getTeacherMenu();
    this.isComputer = this.deviceService.isDesktop();

    if (!this.isComputer) {
      this.settingsService.layout.isWithFold = false;
    }
    if (!this.settingsService.layout.isWithFold) {
      this.isUnfolded = true;
    }
  }

  toggleFolded(action) {
    if (this.settingsService.layout.isWithFold) {
      if (action === 'open') {
        this.isUnfolded = true;
      } else {
        this.isUnfolded = false;
      }
    }
  }

  toggleExpand() {
    this.settingsService.layout.isWithFold = !this.settingsService.layout.isWithFold;
    if (!this.settingsService.layout.isWithFold) this.isUnfolded = true;
  }

  toggleIfTablet(route: string, drawer: MatDrawer) {
    if (!this.isComputer) {
      drawer.close();
      if (route !== this.router.url) {
        setTimeout(() => {
          this.router.navigate([route]);
        }, 321);
      }
    } else {
      this.router.navigate([route]);
    }
  }
  onFilterLoaded() {
    this.isFilterLoaded = true;
  }

  onAddColleagues() {
    this.dialog
      .open(AddColleagueDialogComponent)
      .afterClosed()
      .subscribe((data: Colleague) => {
        if (data) {
          this.insertColleague(data);
        }
      });
  }
  insertColleague(colleague: Colleague) {
    delete colleague.id;
    this.appService.newElement(`/schools/${this.school.id}/colleagues`, colleague).subscribe(() => {
      const toast = {
        message: this.translate.instant(`general.invitation_sended`),
        styleClass: styleClass.success
      } as Toast;
      this.snackBarService.addCustom(toast);
      this.sharedService.refreshColleague.emit();
    });
  }
}
