import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {
  private static isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  private static routeAnimationType: RouteAnimationType = AnimationsService.isSafari ? 'ELEMENTS' : 'ELEMENTS'; // check if it is a safari
  // ? 'ELEMENTS'
  // : 'ALL'; // check if it is a safari

  constructor() {
    AnimationsService.routeAnimationType = 'ALL';
  }

  static isRouteAnimationsType(type: RouteAnimationType) {
    return AnimationsService.routeAnimationType === type;
  }

  updateRouteAnimationType(pageAnimations: boolean, elementsAnimations: boolean) {
    AnimationsService.routeAnimationType = pageAnimations && elementsAnimations ? 'ALL' : pageAnimations ? 'PAGE' : elementsAnimations ? 'ELEMENTS' : 'NONE';
  }
}

export type RouteAnimationType = 'ALL' | 'PAGE' | 'ELEMENTS' | 'NONE';
