import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material';
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild } from '@angular/core';
import { isDefined } from '@app-core/services/helpers/helpers';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';
import { formatDate } from '@app-core/services/helpers/helpers';
import { IDateRange, IDateRangeMoment } from '../../Interfaces/DateRange';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Subscription } from 'rxjs';
import { YEARLY } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dateRangeTrigger', { static: false }) dateRangeTrigger: MatMenuTrigger;
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  choosenDatesMoment: IDateRangeMoment;
  choosenDates: IDateRange = this.sharedService.getDateRange();
  durationSubscription: Subscription;
  configs = {
    locale: {
      format: 'DD-MM-YYYY',
      displayFormat: 'DD-MM-YYYY',
      firstDay: 1
    },
    opens: 'left',
    autoApply: true,
    alwaysShowCalendars: true,
    linkedCalendars: true,
    showRangeLabelOnInput: true
  };

  rangesLabelsMap = ['Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year'];
  ranges: any = {
    0: [moment(), moment()],
    1: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    2: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    3: [
      moment()
        .startOf('isoWeek')
        .subtract(7, 'days'),
      moment()
        .endOf('isoWeek')
        .subtract(7, 'days')
    ],
    4: [moment().startOf('month'), moment().endOf('month')],
    5: [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    6: [moment().startOf('year'), moment().endOf('year')],
    7: [
      moment()
        .subtract(1, 'year')
        .startOf('year'),
      moment()
        .subtract(1, 'year')
        .endOf('year')
    ]
  };

  constructor(private sharedService: SharedService, private translate: TranslateService) {}

  ngOnInit() {
    this.durationSubscription = this.sharedService.achievementsDurationSelected.subscribe(periodOfTime => {
      const timeSpan: moment.unitOfTime.StartOf = periodOfTime === YEARLY ? 'year' : 'isoWeek';
      this.choosenDatesMoment = {
        startDate: moment().startOf(timeSpan),
        endDate: moment().endOf(timeSpan)
      };
    });
    this.choosenDateMoments();

    this.setRangeLabels();
  }

  ngOnDestroy() {
    this.durationSubscription.unsubscribe();
  }

  choosenDateMoments(): void {
    this.choosenDatesMoment = {
      startDate: moment(this.choosenDates.startDate),
      endDate: moment(this.choosenDates.endDate)
    };
  }

  setRangeLabels() {
    this.rangesLabelsMap.map((element, index) => {
      delete Object.assign(this.ranges, { [this.translate.instant(`${element}`)]: this.ranges[index] })[index];
    });
  }

  onOpenDatepicker() {
    this.pickerDirective.open();
  }

  onUpdatePrevCalendar() {
    this.goPrevious();
    this.sharedService.selectedPeriodTimeChanged.next(true);
  }

  onUpdateNextCalendar() {
    this.goNext();
    this.sharedService.selectedPeriodTimeChanged.next(true);
  }

  goNext() {
    const { startDate, endDate } = this.choosenDatesMoment;

    this.choosenDatesMoment = {
      startDate: moment(startDate).add(7, 'days'),
      endDate: moment(endDate).add(7, 'days')
    };
  }

  goPrevious() {
    const { startDate, endDate } = this.choosenDatesMoment;

    this.choosenDatesMoment = {
      startDate: moment(startDate).subtract(7, 'days'),
      endDate: moment(endDate).subtract(7, 'days')
    };
  }

  onChange() {
    if (!this.choosenDatesMoment) {
      return;
    }
    const choosenDates = {
      startDate: formatDate(this.choosenDatesMoment.startDate),
      endDate: formatDate(this.choosenDatesMoment.endDate)
    };
    if (this.choosenDates && this.choosenDates.startDate === choosenDates.startDate && this.choosenDates.endDate === choosenDates.endDate) {
      return;
    }
    this.choosenDates = choosenDates;
    this.change.emit(this.choosenDates);
    this.sharedService.selectDateRange(this.choosenDates);
  }
}
