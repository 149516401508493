import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DialogActions } from '../../../models/dialogActions';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent {
  constructor(private translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    if (!data) {
      this.data = {
        title: this.translate.instant(`general.delete`),
        message: this.translate.instant(`general.deletemessage`),
        buttonText: {
          sure: this.translate.instant(`general.delete`),
          cancel: this.translate.instant(`general.cancel`)
        },
        okSureBtnColor: 'warn'
      };
    } else {
      this.data = data;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(DialogActions.CONFIRM);
  }
  onClose() {
    this.dialogRef.close(DialogActions.CANCEL);
  }
  onConfirmSureClick() {
    this.dialogRef.close(DialogActions.DELETE);
  }
  onConfirmShowClick() {
    this.dialogRef.close(DialogActions.SHOW);
  }
  onConfirmContinueClick() {
    this.dialogRef.close(DialogActions.CONTINUE);
  }
  onConfirmUploadClick() {
    this.dialogRef.close(DialogActions.UPLOAD);
  }
}
