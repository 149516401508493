import { IDateRange } from './../../Interfaces/DateRange';
import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDateRangeValue]'
})
export class DateRangeValueDirective {
  @Input() appDateRangeValue: IDateRange;
  @Output() appDateRangeValueChange = new EventEmitter();

  constructor() {}

  @HostListener('blur', ['$event.target.value'])
  onBlur() {
    if (!this.appDateRangeValue) {
      this.appDateRangeValueChange.emit({
        begin: null,
        end: null
      });
    }
  }
}
