import { AttachmentInterface } from '@shared/Interfaces/VariationInterface';
import { MatDialog } from '@angular/material';
import { UploadProgress } from './../services/upload.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { isDefined } from '@app-core/services/helpers/helpers';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { AudioRecorderService } from '../services/audio-recorder.service';
import { UploadService } from '../services/upload.service';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { emit } from 'process';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { DialogActions } from '@shared/models/dialogActions';
import { AppService } from '@app-core/services/app.service';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
  providers: [AudioRecorderService]
})
export class AudioRecorderComponent implements OnInit, OnDestroy {
  @Input() fileUploadUrl: string;
  @Input() audioNoteValue: AttachmentInterface;
  @Input() isIconMode = false;
  @Input() showDeleteAction = true;
  @Output() audioRefresh = new EventEmitter();

  isRecording = false;
  audioFile: File;
  audioUrl: string;
  recordedTime: string;

  uploadProgress: UploadProgress;

  recordFailedSubscription: Subscription;
  recordTimeSubscription: Subscription;
  recordDoneSubscription: Subscription;

  constructor(
    private audioRecorderService: AudioRecorderService,
    private uploadService: UploadService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.recordTimeSubscription = this.audioRecorderService.getRecordedTime().subscribe(time => {
      this.recordedTime = time;
    });

    this.recordDoneSubscription = this.audioRecorderService.getRecordedBlob().subscribe(data => {
      this.audioUrl = data.url;
      this.audioFile = data.file;
      this.doAudioUpload();
    });
    this.recordFailedSubscription = this.audioRecorderService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });
  }

  ngOnDestroy() {
    this.abortRecording();
    this.recordTimeSubscription.unsubscribe();
    this.recordDoneSubscription.unsubscribe();
    this.recordFailedSubscription.unsubscribe();
  }

  onAudioStartRecord() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecorderService.startRecording();
    }
  }

  onAudioStopRecord() {
    if (this.isRecording) {
      this.audioRecorderService.stopRecording();
      this.isRecording = false;
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecorderService.abortRecording();
    }
  }

  doAudioUpload() {
    if (isDefined(this.audioFile)) {
      const files: Set<File> = new Set();
      files.add(this.audioFile as File);

      this.uploadProgress = this.uploadService.upload(files, this.fileUploadUrl);

      forkJoin([this.uploadProgress[this.audioFile.name].progress]).subscribe(end => {
        this.audioRefresh.emit();
        this.snackBarService.addCustom({
          message: this.translate.instant(`general.uploaded`),
          styleClass: styleClass.success
        } as Toast);
      });
    }
  }

  onAudioConfirmDelete() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px'
      })
      .afterClosed()
      .subscribe((action: string) => {
        if (action === DialogActions.DELETE) {
          this.deletetFeedbackAttachment();
        }
      });
  }
  deletetFeedbackAttachment() {
    this.appService.deleteElement(this.fileUploadUrl).subscribe(() => {
      this.audioRefresh.emit();
    });
  }

  onOpenFileViewer() {
    this.dialog
      .open(FileViewerComponent, {
        width: '100vw',
        data: { files: this.audioNoteValue.url }
      })
      .afterClosed()
      .subscribe();
  }
}
