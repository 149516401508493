import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '@app-core/services/auth.service';
import { storageKeys } from '@app-core/services/helpers/config-data';
import { getStorageItem } from '@app-core/services/helpers/helpers';
import { MenuService } from '@app-core/services/menu/menu.service';
import { SettingsService } from '@app-core/settings/settings.service';
import { KidMenu } from '@shared/models/navigation/navigation.model';

@Component({
  selector: 'app-new-kid-layout',
  templateUrl: './new-kid-layout.component.html',
  styleUrls: ['./new-kid-layout.component.scss']
})
export class NewKidLayoutComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer: MatDrawer;
  menuItems = [];
  isMenuOpened = false;

  languages = ['de', 'en', 'ru', 'ua'];
  language: string;
  constructor(public router: Router, private menuService: MenuService, private authService: AuthService, private settingsService: SettingsService) {}

  ngOnInit() {
    this.menuItems = this.menuService.getKidMenu();
    this.language = getStorageItem(storageKeys.language) || 'de';
  }

  onMenuOpenedChange(event) {
    this.isMenuOpened = event;
  }

  onRouterNavigate(item: KidMenu) {
    if (item.logoutAction) {
      this.doLogout();
    } else {
      this.handleNavigation(item);
    }
    this.closeMenu();
  }

  handleNavigation(item: KidMenu) {
    if (item.routerLink !== this.router.url) {
      setTimeout(() => {
        this.router.navigate([item.routerLink]);
      }, 321);
    }
  }

  doLogout() {
    this.authService.logOut().subscribe(() => {});
  }

  onChangeLanguage(language) {
    this.language = language;
    this.settingsService.setLanguage(language);
    this.closeMenu();
  }

  closeMenu() {
    this.drawer.close();
  }
}
