const timeSpan = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTER: 'quarter'
};

const pageUrl = {
  HOME: 'teacher/quickaccess/home',
  WEEKLY: 'new-analyze/students-subjects-report',
  SUBJECT: 'new-analyze/favorites',
  GROUPS: 'new-analyze/groups',
  KID_WORKS: 'new-analyze/kid-works'
};

const pagesHistory = [
  {
    id: 1,
    key: 'overview',
    url: 'teacher/quickaccess/new-analyze/groups',
    label: 'general.overview',
    backRoutes: []
  },
  {
    id: 2,
    key: 'weekly',
    url: '/teacher/quickaccess/new-analyze/students-subjects-report',
    label: 'general.week_overview',
    extra: localStorage.getItem('category'),
    backRoutes: [1]
  },
  {
    id: 3,
    key: 'subjects',
    label: 'general.overview',
    backRoutes: [2, 1]
  },
  {
    id: 5,
    key: 'kidsRaport',
    label: '',
    backRoutes: [3, 2, 1]
  }
];

const apiUrl = {
  TEACHER_TAGS: '/analyze/tags/:tagId',
  GRADE_CLASSES: '/analyze/grade-classes/:gradeClassId',
  LEARNING_GROUPS: '/analyze/grade-classes/:gradeClassId/learning-groups/:learningGroup',
  TOPICS: '/topics/bookmarks/:topicId'
};

const groupTypes = {
  TEACHER_TAGS: 'TEACHER_TAGS',
  GRADE_CLASSES: 'GRADE_CLASSES',
  LEARNING_GROUPS: 'LEARNING_GROUPS',
  TOPICS: 'TOPICS',
  SUBJECT: 'SUBJECT'
};

const apiMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

const propertyName = {
  ID: 'id',
  GRADE_CLASS_ID: 'grade_class_id'
};
export { pageUrl, timeSpan, pagesHistory, apiUrl, groupTypes, apiMethods, propertyName };
