import { styleClass } from '@app-core/services/helpers/config-data';
import { timeDifferenceToString } from '@app-core/services/helpers/helpers';
import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface RecordedAudioOutput {
  file: File;
  audioSafeUrl: SafeUrl;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {
  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  constructor(private snackBarService: SnackBarService, private sanitizer: DomSanitizer) {}

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch((error: DOMException) => {
        this.snackBarService.addCustom({
          message: `error.${error.message}`,
          styleClass: styleClass.error
        } as Toast);
        this._recordingFailed.next();
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm'
    });

    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = timeDifferenceToString(diffTime.minutes()) + ':' + timeDifferenceToString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 1000);
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        blob => {
          if (this.startTime) {
            const file = new File([blob], encodeURIComponent(`audio_${new Date().getTime()}.wav`));
            const audioSafeUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            this.stopMedia();
            this._recorded.next({
              file: file,
              url: URL.createObjectURL(blob),
              audioSafeUrl: audioSafeUrl
            });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
}
