import { isDefined } from '@app-core/services/helpers/helpers';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return isDefined(fullName)
      ? fullName
          .split(' ')
          .map(n => n[0])
          .join('')
      : '';
  }
}
