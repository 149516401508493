import { NavigationService } from 'src/app/shared/services/navigation/navigation.service';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './features/auth/auth.module';
import { LayoutModule } from '@app-core/layouts/layouts.module';
import { SharedService } from './shared/services/shared.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
// import { BrowserModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import 'moment/locale/en';
import 'moment/locale/de';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular
    BrowserAnimationsModule,
    // BrowserModule,

    // core & shared
    CoreModule,
    SharedModule,

    // app
    AppRoutingModule,
    LayoutModule,

    // default module
    AuthModule,

    OAuthModule.forRoot(),

    // notification
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })

    // ServiceWorkerModule.register('service-worker.js', {
    //   enabled: environment.production
    // })
  ],
  providers: [
    SharedService, // this is not the best structure , TBS
    NavigationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
