import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iconType, storageKeys } from '@app-core/services/helpers/config-data';
import { getSchoolIcon, getStorageObjectItem } from '@app-core/services/helpers/helpers';
import { KidMenu } from '@shared/models/navigation/navigation.model';

@Component({
  selector: 'app-kid-navbar',
  templateUrl: './kid-navbar.component.html',
  styleUrls: ['./kid-navbar.component.scss']
})
export class KidNavbarComponent implements OnInit {
  navLinks = [
    {
      routerLink: '/new-kid/home',
      icon: getSchoolIcon(iconType.bee),
      title: 'menu.my_overview'
    },
    {
      routerLink: '/new-kid/planning',
      icon: getSchoolIcon(iconType.owl),
      title: 'menu.my_planning'
    },
    {
      routerLink: '/new-kid/week',
      icon: 'assets/icons/Kids/filled.svg',
      title: 'menu.my_week'
    },
    {
      routerLink: '/new-kid/coaching',
      icon: 'assets/icons/Kids/my_coaching.svg',
      title: 'menu.my_coaching'
    },
    {
      routerLink: '/new-kid/achievements',
      icon: 'assets/icons/Kids/my_achievements.svg',
      title: 'menu.my_achievements'
    }
  ];
  hasBigIcons = false;

  readonly school = getStorageObjectItem(storageKeys.school);

  constructor(public router: Router) {}

  ngOnInit() {
    this.handleNavBar();
  }

  handleNavBar() {
    if (this.school.navbar_size === 'big') {
      this.hasBigIcons = true;
    }
  }

  onRouterNavigate(item: KidMenu) {
    this.router.navigate([item.routerLink]);
  }
}
