import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SharedModule } from '../../shared.module';
import { MatDialogModule, MatBadgeModule } from '@angular/material';
import { SessionExpiredDialogComponent } from './session-expired-dialog/session-expired-dialog.component';
import { AddOneDialogComponent } from './add-one-dialog/add-one-dialog.component';
import { ChooserGridComponent } from './chooser-grid/chooser-grid.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { ExpertDialogComponent } from './expert-dialog/expert-dialog.component';
import { SelfReflectionComponent } from './self-reflection/self-reflection.component';
import { InviteDialogComponent } from './invite-dialog/invite-dialog.component';
// import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeModule } from 'angular2-qrcode';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    SessionExpiredDialogComponent,
    AddOneDialogComponent,
    ChooserGridComponent,
    InfoDialogComponent,
    ExpertDialogComponent,
    SelfReflectionComponent,
    InviteDialogComponent
  ],
  imports: [CommonModule, CarouselModule, SharedModule, MatDialogModule, MatBadgeModule, QRCodeModule],
  entryComponents: [
    ConfirmDialogComponent,
    ExpertDialogComponent,
    SelfReflectionComponent,
    SessionExpiredDialogComponent,
    AddOneDialogComponent,
    ChooserGridComponent,
    InfoDialogComponent,
    InviteDialogComponent
  ]
})
export class DialogModule {}
