import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoadingService } from '@app-core/services/loading/loading.service';
import { IDateRange } from '@shared/Interfaces/DateRange';
import { Subscription } from 'rxjs/Subscription';
import { KidLearnChecksDialogComponent } from 'src/app/features/new-kid/kid-components/dialogs/kid-learn-checks-dialog/kid-learn-checks-dialog.component';
import { KidTestsDialogComponent } from 'src/app/features/new-kid/kid-components/dialogs/kid-tests-dialog/kid-tests-dialog.component';
import { KidYearlyAchievements } from 'src/app/features/new-kid/models/overview.model';

@Component({
  selector: 'app-kid-yearly-achievments',
  templateUrl: './kid-yearly-achievments.component.html',
  styleUrls: ['./kid-yearly-achievments.component.scss']
})
export class KidYearlyAchievmentsComponent {
  @Input() kidYearlyAchievements: KidYearlyAchievements[];
  @Input() hasExams: false;
  @Input() hasTests: false;
  @Input() kidId: number;
  @Input() belongsToParent: false;

  dateRange: IDateRange;
  onDateChangeSubscription: Subscription;
  percentageCalculationWorks = false;

  constructor(private loadingService: LoadingService, private dialog: MatDialog) {}

  get isLoading() {
    return this.loadingService.loading;
  }

  onTestPopUpOpen(achievement: KidYearlyAchievements) {
    const topicIds = [];
    achievement.topics.map(topic => {
      topicIds.push(topic.topicId);
    });

    this.dialog
      .open(KidTestsDialogComponent, {
        width: '500px',
        data: { topicId: topicIds, dataRange: this.dateRange, isYearly: true, kidId: this.kidId, belongsToParent: this.belongsToParent }
      })
      .afterClosed()
      .subscribe();
  }

  onLearnChecks(achievement: KidYearlyAchievements) {
    const topicIds = [];
    achievement.topics.map(topic => {
      topicIds.push(topic.topicId);
    });

    this.dialog
      .open(KidLearnChecksDialogComponent, {
        width: '500px',
        data: { topicId: topicIds, dataRange: this.dateRange, isYearly: true, kidId: this.kidId, belongsToParent: this.belongsToParent }
      })
      .afterClosed()
      .subscribe();
  }
}
