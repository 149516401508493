export const environment = {
  production: true,
  envName: 'DEV',
  restBaseUrl: 'https://scobees.division5.co:4400',
  xDemoApiKey: 'EV1Wy2WZApNRAuZ',
  rows: 15,
  imagePath: '/var/www/html/uploads/',
  imageReadPath: 'http://dev.unnitech.net/uploads/',
  supportUrl: 'http://doc.scobees-support.com/',
  assetsUrl: 'https://scobees-assets.division5.co/school/assets/',
  // prod
  // test
  vapidPublicKey: 'BKkhlfcbM50l5YQKFN0PmBW7tBBmiyW-2Xru4l_8R43rJlaCPuasLB_2zhKBHikZHlJd6tcBamlAtR53A2AHiJk',

  /***
   * google configuration
   */
  googleClientId: '823297166420-5ak5lr0sf69u0arns75ftdrai9e0t4ks.apps.googleusercontent.com',
  /***
   * facebook configuration
   */

  facebookClientId: '581706808991924',
  status: false,
  cookie: false,
  xfbml: false,
  openIdAuthMode: true,
  iservUrl: 'https://schule-huenfelden.de/iserv/',
  version: 'v2.7',
  i18nPrefix: './',

  FEATURE_PARENT_ACCOUNT: true
};
