import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { getFileType, isDefined } from '@app-core/services/helpers/helpers';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '@app-core/services/app.service';

interface Files {
  customSrc: any;
  url: string;
  type: string;
  loaded: boolean;
  isDownloading: boolean;
}

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  readonly imageTypes = ['png', 'jpeg', 'jpg'];
  readonly audioTypes = ['mp3', 'wav'];
  readonly videoTypes = ['m4a', 'mov'];
  readonly documentTypes = ['doc', 'docx', 'pages'];
  readonly pdfType = 'pdf';
  readonly mp4Type = 'mp4';
  files: Files[] = [];

  activeFile = 0;
  totalFiles = 0;
  plyrOptions = { ratio: '21:9' } as Plyr.Options;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<FileViewerComponent>, private appService: AppService) {
    this.setInfo();
  }

  ngOnInit() {}

  setInfo() {
    if (isDefined(this.data.files)) {
      if (typeof this.data.files === 'string') {
        this.data.files = [{ url: this.data.files }];
      }

      for (const file of this.data.files) {
        const type = getFileType(file.url);

        this.files.push({
          customSrc: this.handeSourceFileType(file.url, type),
          url: file.url,
          type: type,
          loaded: false,
          isDownloading: false
        });
      }
      if (this.data.activeFile) {
        const fileIndex = this.files.findIndex(file => file.url === this.data.activeFile.url);
        this.activeFile = fileIndex !== -1 ? fileIndex : 0;
      }
      this.handleLazyLoad();
      this.totalFiles = this.data.files.length;
    }
  }

  handeSourceFileType(url: string, type: string) {
    if (this.audioTypes.includes(type)) {
      return {
        src: url,
        type: `audio/${type}`
      };
    }
    if (type === this.mp4Type) {
      return {
        src: url,
        type: `video/${type}`
      };
    }
    if (type === this.pdfType) {
      return encodeURIComponent(url);
    }
  }
  handleLazyLoad() {
    if (isDefined(this.activeFile)) {
      this.files[this.activeFile].loaded = true;
    }
  }

  onStepChange(event: StepperSelectionEvent) {
    this.activeFile = event.selectedIndex;
    this.handleLazyLoad();
  }

  onFileDownload(file: Files) {
    file.isDownloading = true;
    this.appService.downloadFile(file.url).then(() => {
      file.isDownloading = false;
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
