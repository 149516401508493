import { SharedService } from 'src/app/shared/services/shared.service';
import { CanActivate, Router } from '@angular/router';
import { isDefined } from '@app-core/services/helpers/helpers';
import { Injectable } from '@angular/core';

@Injectable()
export class GradeNavigation implements CanActivate {
  constructor(public router: Router, private sharedService: SharedService) {}

  canActivate() {
    if (isDefined(this.sharedService.analyzeGrade)) {
      this.router.navigate(['/teacher/quickaccess/new-analyze/categories']).then();
    }
    return true;
  }
}

@Injectable()
export class WorkNavigation implements CanActivate {
  constructor(private router: Router, private sharedService: SharedService) {}

  canActivate() {
    if (isDefined(this.sharedService.analyzeWork)) {
      this.router.navigate(['/teacher/quickaccess/new-analyze/groups']).then();
    }
    return true;
  }
}
