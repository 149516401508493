import { Component, OnInit, Input } from '@angular/core';
import { Router, ChildActivationEnd, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { MenuService } from '@app-core/services/menu/menu.service';
import { User } from 'src/app/shared/models/user/user.model';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AuthService } from '@app-core/services/auth.service';
import { defaultImage } from '@app-core/services/helpers/config-data';
const QUICKACCESS_KEY = 'menu.quickaccess';

@Component({
  selector: 'app-qa-toolbar',
  templateUrl: './qa-toolbar.component.html',
  styleUrls: ['./qa-toolbar.component.scss']
})
export class QaToolbarComponent implements OnInit {
  readonly QUICKACCESS_KEY = QUICKACCESS_KEY;
  title: string;
  backPath: string;
  user = {} as User;
  menuItems = [];
  activeMenuPath: string;
  activeMenuItem: any;

  defaultImage = defaultImage;

  constructor(
    private location: Location,
    private menuService: MenuService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.setTitle(this.router.routerState.snapshot.root);

    this.menuItems = this.menuService.getMenu();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setTitle(this.router.routerState.snapshot.root);
      }
    });
  }

  setTitle(snapshot: ActivatedRouteSnapshot) {
    let lastChild = snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    this.title = lastChild.data.title;
    this.backPath = lastChild.data.backPath;
  }

  back() {
    this.router.navigate([this.backPath]);
  }

  logout() {
    this.authService.logOut().subscribe(() => {});
  }
}
