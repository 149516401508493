import { AnimationsService } from './animations/animation.service';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgGapiClientConfig } from 'ng-gapi';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { GradeNavigation, WorkNavigation } from './services/helpers/analyzeOrganizeNavigation';
import { AppService } from './services/app.service';
import { LoggingService } from './services/log.service';
import { MenuService } from './services/menu/menu.service';
import { AuthGuardService } from './services/authguard/auth-guard.service';
import { SnackBarService } from './services/snackbar/snack-bar.service';
import { NotificationService } from './services/notifications/notification.service';
import { AppErrorHandler } from './services/error-handler/app-error-handler.service';
import { HttpErrorInterceptor } from './services/http-interceptors/http-error.interceptor';
import { SettingsService } from './settings/settings.service';
import { IsLoggedIn } from './services/authguard/is-logged';
import { AuthorizationService } from './services/authorization/authorization.service';
import { TokenInterceptor } from './services/token-interceptor/token-interceptor.service';
import { LoadingInterceptor } from './services/loading-interceptor/loading.interceptor';
import { PasswordGeneratorService } from './services/password-generator/password-generator.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PwaService } from './services/pwa/pwa.service';
import { HttpLoader } from './services/cache-manager/cache';
import { KidWorksInterceptor } from './services/kid-works-interceptor/kid-works.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, `.json?cacheBuster=${new Date().getTime()}`);
}
export function tokenGetter() {
  return localStorage.getItem('token');
}

const gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.googleClientId,
  discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
  scope: ['https://www.googleapis.com/auth/analytics.readonly', 'https://www.googleapis.com/auth/analytics'].join(' ')
};

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // 3rd party
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['example.com'],
        blacklistedRoutes: ['example.com/examplebadroute/']
      }
    }),
    DeviceDetectorModule
  ],
  providers: [
    AuthService,
    AppService,
    GradeNavigation,
    WorkNavigation,
    LoggingService,
    MenuService,
    AuthGuardService,
    SnackBarService,
    NotificationService,
    SettingsService,
    IsLoggedIn,
    AuthorizationService,
    AnimationsService,
    PasswordGeneratorService,
    PwaService,
    HttpLoader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KidWorksInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
