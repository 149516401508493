import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-register-info-dialog',
  templateUrl: './register-info-dialog.component.html',
  styleUrls: ['./register-info-dialog.component.scss']
})
export class RegisterInfoDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<RegisterInfoDialogComponent>) {}

  ngOnInit() {}
}
