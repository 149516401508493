import { getSafe, isDemo } from '@app-core/services/helpers/helpers';
import { User } from '../../shared/models/user/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, tap, mapTo } from 'rxjs/operators';
import { LoggingService } from './log.service';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material';
import { SessionExpiredDialogComponent } from '../../shared/features/dialog/session-expired-dialog/session-expired-dialog.component';
import { CONFIRM } from 'src/app/features/admin/models/admin.config';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IParentPayload } from '@shared/Interfaces/ParentInterface';

export class Tokens {
  jwt: string;
  refreshToken: string;
}

@Injectable()
export class AuthService {
  private readonly JWT_TOKEN = 'token';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private loggedUser: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private logger: LoggingService,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {}

  login(url: string, loginData: Object): any {
    return this.http.post<any[]>(environment.restBaseUrl + url, loginData, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        if (getSafe(() => res.data.access_token)) {
          this.logger.log('Service:', 'SUCCESS', 200);
          this.saveLoginData(res);
          this.doLoginUser(res.data.first_name, {
            jwt: res.data.access_token,
            refreshToken: res.data.refresh_token
          } as Tokens);
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        this.logger.log('Service Fail. Error:', error, 200);
        return throwError(error);
      })
    );
  }

  forgotPassword(email: string): any {
    localStorage.clear();
    const mailObj: any = {
      email: email
    };

    return this.http.post<any[]>(environment.restBaseUrl + '/profile/forgot-password', mailObj, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  changePassword(changePasswordData: Object): any {
    return this.http.put<any[]>(environment.restBaseUrl + '/user/change-password', changePasswordData, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;

        if (res.success === true) {
          this.logger.log('Change PWD', 'SUCCESS', 200);
          return res;
        } else {
          this.logger.log('Change PWD', 'FAILURE', 200);
          return res;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  register(registerData: Object, url: string = '/user/register'): any {
    return this.http.post<any[]>(`${environment.restBaseUrl}${url}`, registerData, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;

        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  registerParent(registerParentData: IParentPayload, hasKid: Boolean) {
    let url = '/parents';
    if (hasKid) {
      url = '/parents/connect';
    }

    return this.register(registerParentData, url);
  }

  registerDemoAccount(registerData: Object): any {
    const headers: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-demo-api-key': environment.xDemoApiKey
      })
    };

    return this.http.post<any[]>(`${environment.restBaseUrl}/users/demo`, registerData, headers).pipe(
      map((response: HttpResponse<any>) => {
        if (response === null) {
          return response;
        } else {
          this.logger.log('Register', 'FAILURE', 200);
          return response;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  activate(data: Object): any {
    localStorage.clear();

    return this.http.put<any[]>(environment.restBaseUrl + '/user/activate', data, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;

        if (res.success === true) {
          this.logger.log('Activate', 'SUCCESS', 200);
          return res;
        } else {
          this.logger.log('Activate', 'FAILURE', 200);
          return res;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  connectAccountToIServ(connectData) {
    return this.http.post(environment.restBaseUrl + '/users/iserv-connect', connectData, this.getRequestOptionArgs());
  }

  saveLoginData(response) {
    const data = response.data;
    const user = {
      name: data.user.first_name,
      surname: data.user.last_name,
      email: data.user.email,
      idSchool: data.user.school_id,
      first_login: data.user.first_login,
      profilePhoto: environment.imageReadPath + data.user.profile_picture,
      is_demo: data.user.is_demo,
      iserv_email: data.user.iserv_email
    } as User;

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('role', data.user.role);
    if (data.school_data) {
      localStorage.setItem('school', JSON.stringify(data.school_data));
    }
  }

  getRequestOptionArgs(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  showExpirationDialog(): void {
    this.dialog.closeAll();
    this.clearLocalStorage();
    this.dialog
      .open(SessionExpiredDialogComponent, {
        disableClose: true
      })
      .afterClosed()
      .subscribe((response: any) => {
        if (response && response.action === CONFIRM) {
          this.terminateSession();
        }
      });
  }

  terminateSession() {
    const isDemoUser = isDemo();

    this.clearLocalStorage();

    let url = '/login';
    if (isDemoUser) {
      url = '/login-demo';
    }

    this.router.navigate([url]);
  }

  clearLocalStorage() {
    const language = localStorage.getItem('language');

    localStorage.clear();
    this.sharedService.gradeSelected = null;
    this.sharedService.analyzeGrade = null;
    this.sharedService.analyzeWork = null;

    if (language !== null) {
      localStorage.setItem('language', language);
    }
  }

  logOut() {
    return this.http
      .post<any>(`${environment.restBaseUrl}/logout`, {
        refreshToken: this.getRefreshToken()
      })
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError(() => {
          this.doLogoutUser();
          return of(false);
        })
      );
  }

  removeNotificationSubscription() {
    const subscriptionId = localStorage.getItem('subscriptionId');
    if (subscriptionId) {
      this.http.delete(`${environment.restBaseUrl}/subscriptions/${subscriptionId}`).subscribe();
    }
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(`${environment.restBaseUrl}/refresh-token`, {
      refresh_token: this.getRefreshToken()
    });
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
    this.sharedService.achievementsDurationSelected.next(null);
    this.terminateSession(); // this needs to be removed
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  storeTokens(tokens: Tokens) {
    const jwtToken = tokens.jwt;
    const payload = JSON.parse(window.atob(jwtToken.split('.')[1]));
    const userRole = payload.userRole;
    localStorage.setItem('role', userRole);
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
