import { getSchoolIcon } from '@app-core/services/helpers/helpers';
import { iconType } from '@app-core/services/helpers/config-data';
import { styleClass, notificationType, actions } from '@app-core/services/helpers/config-data';
import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { MatDialog, MatBottomSheet, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '@app-core/services/app.service';
import { AuthService } from '@app-core/services/auth.service';
import { Toast, SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { User } from 'src/app/shared/models/user/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SimpleDialogComponent } from 'src/app/shared/features/simple-dialog/simple-dialog.component';
import { RateLessonDialogComponent } from 'src/app/features/kid/dialogs/rate-lesson-dialog/rate-lesson-dialog.component';

@Component({
  selector: 'app-select-teacher-kid',
  templateUrl: '../kid-topics/select-teacher/select-teacher.component.html'
})
export class SelectTeacherKidComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private appService: AppService,
    private sharedService: SharedService,
    private _bottomSheetRef: MatDialogRef<SelectTeacherKidComponent>
  ) {}

  attend(lesson: any) {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      width: '250px',
      data: {
        title: 'general.attending',
        message: 'general.attending-dialog',
        questionMark: '?'
      },
      panelClass: 'simpledialog'
    });
    dialogRef.afterClosed().subscribe(decision => {
      if (decision === 'YES') {
        this.appService.newElement(`/kid/presence`, { lesson_id: lesson.lesson_id }).subscribe(response => {
          dialogRef.close(lesson);
          this.sharedService.closeDialog.emit(lesson);
        });
      }
    });
  }
}

@Component({
  selector: 'app-kid-nav',
  templateUrl: './kid-nav.component.html',
  styleUrls: ['./kid-nav.component.scss']
})
export class KidNavComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subject: any;
  @Input() feedback: any;
  @Input() isLernburo: boolean;
  workId: any;
  workName: any;
  workTitle: any = '';
  disableAttend = false;
  primaryDialog: any;
  closePrimaryDialog: any;
  subjectActiveLessons: any = [];
  today = new Date();
  user = {} as User;
  topicId: string;
  phaseId: string;
  hasBackPath: string;
  notifications = [];

  notificationType = notificationType;

  logo = getSchoolIcon(iconType.logo);

  constructor(
    private bottomSheet: MatBottomSheet,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public sharedService: SharedService,
    private location: Location,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.topicId = this.route.snapshot.paramMap.get('topicId');
    this.phaseId = this.route.snapshot.paramMap.get('phaseId');
    if (this.route.snapshot.paramMap.get('workName')) {
      this.workTitle = this.route.snapshot.paramMap.get('workName');
    }
    this.workId = this.route.snapshot.paramMap.get('id');
    this.workName = this.route.snapshot.paramMap.get('workName');
    this.setTitle(this.router.routerState.snapshot.root);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setTitle(this.router.routerState.snapshot.root);
      }
    });
    this.closePrimaryDialog = this.sharedService.closeDialog.subscribe((response: any) => {
      this.primaryDialog.close();
      this.subject.attendance = response;
    });
  }

  onBackNavigation() {
    // To be checked
    // if (this.hasBackPath !== 'STACK_BACK') {
    //   this.router.navigate([this.hasBackPath]);
    // } else {
    //   this.location.back();
    // }
    this.location.back();
  }

  rateLesson(notification) {
    this.dialog
      .open(RateLessonDialogComponent, {
        data: { message: notification }
      })
      .afterClosed()
      .subscribe(rate => {
        if (rate) {
          this.appService
            .newElement(`/rate/lessons/${this.subject.active_lessons[0].lesson_id}`, {
              rate: rate,
              notification_id: notification.id
            })
            .subscribe(() => {
              const toast = {
                message: this.translate.instant(`rate.thanks`),
                styleClass: styleClass.success
              } as Toast;
              this.snackBarService.addCustom(toast);
              notification.metadata.lessonRate = rate;
              this.markAsRead(notification);
            });
        }
      });
  }

  attendToTeacher() {
    if (this.subject && this.subject.active_lessons && this.subject.active_lessons.length > 1) {
      this.dialog
        .open(SelectTeacherKidComponent, {
          data: {
            lessons: this.subject.active_lessons
          }
        })
        .afterClosed()
        .subscribe((response: any) => {
          if (response) {
            this.subject.attendance = response;
            this.handleAttendDisable();
          }
        });
    } else {
      if (this.subject && this.subject.active_lessons && this.subject.active_lessons[0] && this.subject.active_lessons[0].lesson_id) {
        this.dialog
          .open(SimpleDialogComponent, {
            width: '250px',
            data: {
              title: 'general.attending',
              message: 'general.attending-dialog',
              teacher: this.subject.active_lessons[0],
              questionMark: '?'
            },
            panelClass: 'simpledialog'
          })
          .afterClosed()
          .subscribe(decision => {
            if (decision === 'YES') {
              this.appService
                .newElement(`/kid/presence`, {
                  lesson_id: this.subject.active_lessons[0].lesson_id
                })
                .subscribe(response => {
                  this.subject.attendance = response;
                });
            }
          });
      }
    }
  }

  setTitle(snapshot: ActivatedRouteSnapshot) {
    let lastChild = snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    this.hasBackPath = lastChild.data.backPath;
  }

  logout() {
    this.authService.logOut().subscribe();
  }

  getNotifications() {
    this.appService.getAll('/notifications?isRead=0').subscribe(response => {
      if (response) {
        this.notifications = response.data.page;
        this.sharedService.manageNotification(actions.update, response.data.pagination.total_count);
      }
    });
  }

  readNotifications(notification) {
    if (notification.type === notificationType.lessonFeedback && notification.metadata) {
      this.rateLesson(notification);
    } else if (
      notification.type === notificationType.teacherTaskFeedback ||
      notification.type === notificationType.teacherQuickFeedback ||
      notification.type === notificationType.teacherVariationFeedback
    ) {
      this.markAsRead(notification);
      this.navigateToFeedback(notification.metadata);
    }
  }

  navigateToFeedback(feedbackData) {
    localStorage.setItem('tab', '1');
    localStorage.setItem('task', feedbackData.task_id);
    if (feedbackData.variation_id) {
      this.sharedService.notificationVariationId = feedbackData.variation_id;
    }
    this.router
      .navigate([
        `/kid/home/${feedbackData.work_name}/${feedbackData.work_id}/topic/${feedbackData.topic_id}/phase/${feedbackData.phase_id}/task/${feedbackData.task_id}/feedback`
      ])
      .then();
  }

  markAsRead(notification) {
    if (!notification.is_read) {
      notification.is_read = true;
      this.appService.editElement(`/notifications/${notification.id}/read`, {}).subscribe(() => {
        this.getNotifications();
      });
    }
  }

  onRequestLogout() {
    this.dialog
      .open(SimpleDialogComponent, {
        width: '250px',
        data: {
          icon: 'power_settings_new',
          title: 'general.logout',
          message: 'general.logout_dialog'
        },
        panelClass: 'simpledialog'
      })
      .afterClosed()
      .subscribe(decision => {
        if (decision === 'YES') {
          this.logout();
        }
      });
  }

  handleAttendDisable() {
    this.disableAttend = false;
    if (this.subject.attendance) {
      this.disableAttend = true;
    }
  }

  ngOnDestroy() {}
}
