import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://scobees.test-iserv.de',

  skipIssuerCheck: true,
  skipSubjectCheck: true,
  strictDiscoveryDocumentValidation: false,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/login',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: '6b1udnqahls0o0sswck4444gg8ccgog4kgs4cow84g04owocc0',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email roles',
  responseType: 'code'
};
