import { Component, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AppService } from '@app-core/services/app.service';
import { SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { EventEmitter } from '@angular/core';
import { KidGoal } from '../../../models/overview.model';
import { getInitials } from '@app-core/services/helpers/helpers';

@Component({
  selector: 'app-kid-goal',
  templateUrl: './kid-goal.component.html',
  styleUrls: ['./kid-goal.component.scss']
})
export class KidGoalComponent implements OnInit {
  @Output() teacherReaction = new EventEmitter();
  @Input() onlyView = false;
  @Input() belongsToKid = true;
  @Input() isYearly = false;
  @Input() goal = {} as any;
  @Input() actionTemplate: TemplateRef<any>;

  teacherInitials: string;

  constructor(private appService: AppService, private snackBarService: SnackBarService) {}

  ngOnInit() {
    if (this.goal.name) {
      this.teacherInitials = getInitials(this.goal.name);
    }
  }

  onGoalStatusChange() {
    this.goal.isCompleted = this.goal.isCompleted;
    this.appService.patchElement(`/kid-goal/${this.goal.id}`, { isCompleted: this.goal.isCompleted } as KidGoal).subscribe(() => {
      this.snackBarService.showSuccessMessage('general.updated');
    });
  }
  onReactionChange(quickResponse: string) {
    this.goal.reaction = quickResponse;
    this.appService.patchElement(`/kid-goal/${this.goal.id}`, { reaction: this.goal.reaction } as KidGoal).subscribe(() => {
      if (this.belongsToKid) {
        this.snackBarService.showSuccessMessage('general.updated');
      }
    });

    this.onTeacherReaction(quickResponse);
  }
  onTeacherReaction(reaction) {
    this.teacherReaction.emit(reaction);
  }
}
