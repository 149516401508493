import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CONFIRM, CANCEL } from 'src/app/features/admin/models/admin.config';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss']
})
export class SessionExpiredDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SessionExpiredDialogComponent>) {}

  ngOnInit() {}

  onConfirmClick(): void {
    this.dialogRef.close({ action: CONFIRM });
  }
}
