import { environment } from '@env/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { NavigationService } from 'src/app/shared/services/navigation/navigation.service';
import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app-core/settings/settings.service';
import { routeAnimations } from '@app-core/animations/route.animations';
import { Subscription } from 'rxjs';
import { LoadingService } from '@app-core/services/loading/loading.service';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { authConfig } from '../features/auth/auth.config';
import { hasBrowserSupport, isBrowserAllowed } from '@app-core/services/helpers/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  @ViewChild('contentArea', { static: true }) private contentArea: ElementRef<HTMLElement>;

  /**
   * * Important Information is highlight
   * ! Depricated method, do not use it
   * ? Should this method be exposed in the public API?
   * Todo: Refactor this method in order to be confirmed in code review
   * @param test The parameter of the method
   */

  title = 'scobees';
  loading = false;
  loadingSubscription: Subscription;
  pages = [
    {
      key: 'kids',
      url: '/teacher/quickaccess/kids',
      hasBeenOnThisPage: false
    },
    {
      key: 'learningContent',
      url: '/teacher/quickaccess/learning-content',
      hasBeenOnThisPage: false
    },
    {
      key: 'home',
      url: '/teacher/quickaccess/home',
      hasBeenOnThisPage: false
    }
  ];

  isSupportedBrowser = false;

  allowedUrl = ['/demo', '/login-demo'];

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private oauthService: OAuthService
  ) {
    this.initLanguage();
  }

  ngOnInit() {
    this.isSupportedBrowser = isBrowserAllowed();
    this.configureOpenIdAuthenticate();

    this.router.events.pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0)).subscribe((event: ActivationEnd) => {
      this.navigationService.managePagesHistory(event.snapshot.data);
    });

    setTimeout(() => {
      if (hasBrowserSupport(this.router.url)) {
        this.isSupportedBrowser = true;
        this.initApp();
      }
    }, 500);
  }

  private initApp() {
    const storedPages = JSON.parse(localStorage.getItem('pages'));
    if (!storedPages) {
      localStorage.setItem('pages', JSON.stringify(this.pages));
    }
    this.settingsService.languageChange.subscribe(() => {
      this.initLanguage();
    });
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value: boolean) => {
      setTimeout(() => {
        this.loading = value;
      });
    });
  }

  private configureOpenIdAuthenticate() {
    if (environment.openIdAuthMode) {
      this.oauthService.configure(authConfig);
      this.oauthService.initCodeFlow();
    }
  }

  initLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    let language = 'de';
    if (localStorage.getItem('language')) {
      language = localStorage.getItem('language');
    }
    this.translate.setDefaultLang(language);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(language);
  }
}
