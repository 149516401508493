import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GTableComponent } from './g-table/g-table.component';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { SearchModule } from '../search/search.module';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [GTableComponent],
  imports: [
    CommonModule,
    RouterModule,

    // Material Modules
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    TranslateModule,
    SearchModule,
    FlexModule
  ],
  exports: [GTableComponent]
})
export class GTableModule {}
