import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kid-achievement-tag',
  templateUrl: './kid-achievement-tag.component.html',
  styleUrls: ['./kid-achievement-tag.component.scss']
})
export class KidAchievementTagComponent implements OnInit {
  @Input() color = 'green';
  @Input() label: string;
  @Input() count: number;

  constructor() {}

  ngOnInit() {}
}
