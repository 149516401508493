import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@app-core/services/authorization/authorization.service';

@Component({
  selector: 'app-oidc-login',
  templateUrl: './oidc-login.component.html',
  styleUrls: ['./oidc-login.component.scss']
})
export class OidcLoginComponent implements OnInit {
  isLoggingIn = false;

  constructor(private authorizationService: AuthorizationService, public activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    const { login_hint } = this.activatedRoute.snapshot.queryParams;

    this.authorizationService.initOpenIdAuthenticate(null, login_hint);
  }
}
