import { ColleaguesModule } from './../../features/admin/manage-schools/new-entity/colleagues/colleagues.module';
import { ContactToSwitchModule } from './../../features/admin/dialogs/contact-to-switch/contact-to-switch.module';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { QuickAccessLayoutComponent } from './quick-access-layout/quick-access-layout.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DialogModule } from 'src/app/shared/features/dialog/dialog.module';
import { QaToolbarComponent } from './qa-toolbar/qa-toolbar.component';
import { TeacherLayoutComponent } from './teacher-layout/teacher-layout.component';
import { QaFilterComponent } from './qa-filter/qa-filter.component';
import { KidLayoutComponent, BottomSheetOverviewExampleSheetComponent } from './kid-layout/kid-layout.component';
import { MatBottomSheetModule, MatBadgeModule } from '@angular/material';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { MomentModule } from 'ngx-moment';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { NewKidLayoutComponent } from './new-kid-layout/new-kid-layout.component';
import { KidHeaderComponent } from './new-kid-layout/kid-header/kid-header.component';
import { KidNavbarComponent } from './new-kid-layout/kid-navbar/kid-navbar.component';
import { KidGoalsModule } from 'src/app/features/new-kid/kid-components/kid-goals/kid-goals.module';
import { IServService } from '../../shared/services/iserv/iserv.service';

@NgModule({
  declarations: [
    AppLayoutComponent,
    FullLayoutComponent,
    QuickAccessLayoutComponent,
    QaToolbarComponent,
    TeacherLayoutComponent,
    QaFilterComponent,
    KidLayoutComponent,
    NewKidLayoutComponent,
    BottomSheetOverviewExampleSheetComponent,
    LoadingScreenComponent,
    KidHeaderComponent,
    KidNavbarComponent
  ],
  imports: [
    RouterModule,
    SharedModule,
    PerfectScrollbarModule,
    DialogModule,
    MatBottomSheetModule,
    DeviceDetectorModule,
    MatBadgeModule,
    ContactToSwitchModule,
    ColleaguesModule,
    KidGoalsModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    })
  ],
  entryComponents: [BottomSheetOverviewExampleSheetComponent],
  exports: [
    AppLayoutComponent,
    FullLayoutComponent,
    QuickAccessLayoutComponent,
    QaToolbarComponent,
    TeacherLayoutComponent,
    KidLayoutComponent,
    NewKidLayoutComponent,
    LoadingScreenComponent,
    KidHeaderComponent,
    KidNavbarComponent
  ],
  providers: [DeviceDetectorService, IServService]
})
export class LayoutModule {}
