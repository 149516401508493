import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UploadService } from './services/upload.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';
const MAX_UPLOAD_SIZE = 52428800; // size in bytes
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();
  progress: any;
  canBeClosed = true;
  primaryButtonText = 'Upload';
  secondaryButtonText = 'Cancel';
  showCancelButton = true;
  uploading = false;
  isSuccessfullyUploaded = false;
  defaultAccept = '.png,.pdf,.doc,.docx,.pages,jpeg,.jpg,.mp3,.mp4,.m4a,.mov';
  accept: string;
  size;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FileUploaderComponent>,
    public uploadService: UploadService,
    private translate: TranslateService,
    private snackBarService: SnackBarService
  ) {}
  ngOnInit() {
    this.setInfo();
  }

  setInfo() {
    this.accept = this.data.accept || this.defaultAccept;
  }

  onAddFiles() {
    this.file.nativeElement.click();
  }

  onFilesAddedClick() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    if (this.file.nativeElement.files[0].size > MAX_UPLOAD_SIZE) {
      this.snackBarService.addCustom({
        styleClass: styleClass.error,
        description: 'Upload file size can be up to 50 MB',
        message: 'Size error ! Upload file size can be up to 50 MB.'
      } as Toast);
      this.file.nativeElement.value = '';
    }

    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
  }

  onCloseDialogClick() {
    // if everything was uploaded already, just close the dialog
    if (this.isSuccessfullyUploaded) {
      return this.dialogRef.close(this.files);
    }

    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.progress = this.uploadService.upload(this.files, this.data.url, this.data.httpMethod);

    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    // Adjust the state variables

    // The OK-button should have the text "Finish" now
    this.primaryButtonText = 'Finish';

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;
      this.dialogRef.disableClose = false;

      // ... the upload was successful...
      this.isSuccessfullyUploaded = true;

      // ... and the component is no longer uploading
      this.uploading = false;
      const toast = {
        message: this.translate.instant(`general.uploaded`),
        styleClass: styleClass.success
      } as Toast;
      this.snackBarService.addCustom(toast);
      return this.dialogRef.close(this.isSuccessfullyUploaded);
    });
  }
}
