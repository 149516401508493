import { Component, OnInit, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-favorites-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss']
})
export class FavoriteProgressChartComponent implements OnInit {
  @Input() progress: number;
  @Input() color: string;
  mode: ProgressSpinnerMode = 'determinate';
  progressColor: string;

  constructor() {}

  ngOnInit(): void {
    this.progress = this.progress * 100;
  }

  get spinnerStyle() {
    return { color: this.color };
  }
}
