import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app-core/services/app.service';
import { actions, iconType, notificationType, panelClass, storageKeys } from '@app-core/services/helpers/config-data';
import { getSchoolIcon, getStorageObjectItem, isKid } from '@app-core/services/helpers/helpers';
import { User } from '@shared/models/user/user.model';
import { NavigationService, RouterData } from '@shared/services/navigation/navigation.service';
import { Location } from '@angular/common';
import { SharedService } from '@shared/services/shared.service';
import { Subscription } from 'rxjs-compat/Subscription';
import { MatDialog } from '@angular/material';
import { KidGoalsDialogComponent } from 'src/app/features/new-kid/kid-components/dialogs/kid-goals-dialog/kid-goals-dialog.component';
import { getDayOfWeek, isInThisWeek } from '@app-core/services/helpers/helpers';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogComponent } from 'src/app/shared/features/dialog/info-dialog/info-dialog.component';

@Component({
  selector: 'app-kid-header',
  templateUrl: './kid-header.component.html',
  styleUrls: ['./kid-header.component.scss']
})
export class KidHeaderComponent implements OnInit, OnDestroy {
  @Input() isMenuOpened = false;
  @Output() onClickMenu = new EventEmitter();

  routerDataSubscription: Subscription;

  readonly user: User = getStorageObjectItem(storageKeys.user);
  readonly logo = getSchoolIcon(iconType.logo);
  readonly notificationType = notificationType;
  readonly isKid = isKid();

  notifications = [];
  notificationTypesFeedback = [notificationType.teacherTaskFeedback, notificationType.teacherQuickFeedback, notificationType.teacherVariationFeedback];
  notificationTypeComment = [notificationType.teacherCommentGoal];
  notificationSharedType = [notificationType.teacherSharedNotification];
  notificationExamType = [notificationType.teacherExamNotification];
  notificationTestType = [notificationType.teacherTestNotification];

  routerData = {} as RouterData;
  showSetPasswordBtn: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private navigationService: NavigationService,
    private location: Location,
    public sharedService: SharedService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.routerDataSubscription = this.navigationService.routerData.subscribe((data: RouterData) => {
      this.routerData = data;
    });
    this.hasKidSetOwnPassword();
  }

  ngOnDestroy() {
    this.routerDataSubscription.unsubscribe();
  }

  onBackNavigation() {
    this.location.back();
  }

  getNotifications() {
    this.appService.getAll('/notifications?isRead=0').subscribe(response => {
      if (response) {
        this.notifications = response.data.page;
        this.sharedService.manageNotification(actions.update, response.data.pagination.total_count);
      }
    });
  }

  readNotifications(notification) {
    this.markAsRead(notification);

    if (this.notificationTypesFeedback.includes(notification.type)) {
      this.navigateToFeedback(notification.metadata);
    } else if (this.notificationTypeComment.includes(notification.type)) {
      this.dialog
        .open(KidGoalsDialogComponent, {
          width: '75vw',
          autoFocus: false,
          panelClass: panelClass.roundDialog,
          data: { belongsToTeacher: false }
        })
        .afterClosed()
        .subscribe(() => {});
    } else if (this.notificationSharedType.includes(notification.type)) {
      this.setDateRangeAccordingToMessageCreateDate(notification);
      this.router.navigate(['new-kid/week']);
    } else if (this.notificationTestType.includes(notification.type)) {
      this.router.navigate(['new-kid/achievements'], { queryParams: { test: 'test', topicId: notification.metadata.topicId } });
    } else if (this.notificationExamType.includes(notification.type)) {
      this.router.navigate(['new-kid/achievements'], { queryParams: { test: 'exam', topicId: notification.metadata.topicId } });
    }
  }

  setDateRangeAccordingToMessageCreateDate(notification) {
    const messageDate = new Date(notification.ts_created);
    const dateRange = getDayOfWeek(messageDate);
    this.sharedService.selectDateRange(dateRange);
    if (!isInThisWeek(messageDate)) {
      this.onInfoOpen();
    }
  }

  onInfoOpen() {
    this.dialog.open(InfoDialogComponent, {
      data: { html: this.translate.instant(`notification.notCurrentWeek`) }
    });
  }

  markAsRead(notification) {
    if (!notification.is_read) {
      notification.is_read = true;
      this.appService.editElement(`/notifications/${notification.id}/read`, {}).subscribe(() => {
        this.getNotifications();
      });
    }
  }

  hasKidSetOwnPassword() {
    this.appService.getElement(`/kids/cred-visible/${0}`).subscribe(response => {
      if (response) {
        this.showSetPasswordBtn = response.data.message_pleasePersonalize;
      }
    });
  }

  navigateToFeedback(feedbackData) {
    localStorage.setItem('tab', '1');
    localStorage.setItem('task', feedbackData.task_id);
    if (feedbackData.variation_id) {
      this.sharedService.notificationVariationId = feedbackData.variation_id;
    }
    this.router.navigate([
      `/kid/home/${feedbackData.work_name}/${feedbackData.work_id}/topic/${feedbackData.topic_id}/phase/${feedbackData.phase_id}/task/${feedbackData.task_id}/feedback`
    ]);
  }
}
