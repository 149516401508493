import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconTitleComponent } from './icon-title/icon-title.component';
import { MatIconModule } from '@angular/material';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [IconTitleComponent],
  imports: [CommonModule, MatIconModule, FlexModule],
  exports: [IconTitleComponent]
})
export class IconTitleModule {}
