import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, NavigationEnd, CanDeactivate } from '@angular/router';
import { AuthorizationService } from '../authorization/authorization.service';
import { Observable } from 'rxjs';
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      if (confirm('WARNING: You may have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')) {
        return true;
      } else {
        window.history.pushState(null, '', window.location.href);
        return false;
      }
    }
  }
}
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private authorizationService: AuthorizationService, public router: Router) {
    this.routeEvent(this.router);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);

    if (!isAuthorized) {
      this.router.navigate(['/']);
    }
    return isAuthorized;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authorizationService.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/' && this.authorizationService.isAuthenticated()) {
          this.authorizationService.guideUserToHome();
        }
      }
    });
  }
}
