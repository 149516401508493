import { OidcLoginComponent } from './features/auth/oidc-login/oidc-login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './features/auth/login/login.component';
import { RegisterComponent } from './features/auth/register/register.component';
import { RegisterDemoComponent } from './features/auth/register-demo/register-demo.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/forgot-password.component';
import { PageErrorComponent } from './shared/features/page-error/page-error.component';
import { Roles } from './shared/models/roles';
import { IsLoggedIn } from '@app-core/services/authguard/is-logged';
import { AuthGuardService as AuthGuard } from '@app-core/services/authguard/auth-guard.service';
import { AccessDeniedComponent } from './features/auth/access-denied/access-denied.component';
import { FullLayoutComponent } from '@app-core/layouts/full-layout/full-layout.component';
import { ChangePasswordComponent } from './features/auth/change-password/change-password.component';
import { RegisterParentComponent } from './features/auth/register-parent/register-parent.component';
import { BrowserSupportGuard } from '@app-core/guards/browser-support.guard';
import { NotMyChildComponent } from './features/auth/not-my-child/not-my-child.component';

const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard, BrowserSupportGuard],
    canActivateChild: [AuthGuard, BrowserSupportGuard],

    children: [
      {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./features/admin/admin.module').then(mod => mod.AdminModule),
        data: {
          allowedRoles: [Roles.admin]
        }
      },
      {
        path: 'new-kid',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./features/new-kid/new-kid.module').then(mod => mod.NewKidModule),
        data: {
          allowedRoles: [Roles.kid]
        }
      },
      {
        path: 'kid',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./features/kid/kid.module').then(mod => mod.KidModule),
        data: {
          allowedRoles: [Roles.kid]
        }
      },

      {
        path: 'parent',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./features/parent/parent.module').then(mod => mod.ParentModule),
        data: {
          allowedRoles: [Roles.parent]
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/profile/profile.module').then(mod => mod.ProfileModule),
        data: {
          title: 'menu.profile'
        }
      }
    ]
  },
  {
    path: 'teacher',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/teacher/teacher.module').then(mod => mod.TeacherModule),
    data: {
      allowedRoles: [Roles.teacher, Roles.leadTeacher],
      title: 'menu.teacher'
    }
  },
  {
    path: 'teacher',
    loadChildren: () => import('./features/analyze/analyze.module').then(mod => mod.AnalyzeModule),
    data: {
      allowedRoles: [Roles.teacher, Roles.leadTeacher]
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BrowserSupportGuard],
    data: { title: 'menu.login' },
    resolve: [IsLoggedIn]
  },
  {
    path: 'login-demo',
    component: LoginComponent,
    canActivate: [BrowserSupportGuard],
    data: { title: 'menu.login' },
    resolve: [IsLoggedIn]
  },
  {
    path: 'register-parent/:code',
    component: RegisterParentComponent,
    data: { title: 'menu.register_parent' }
  },
  {
    path: 'not-my-child/:code',
    component: NotMyChildComponent
  },

  {
    path: 'login-iserv',
    component: OidcLoginComponent,
    canActivate: [BrowserSupportGuard],
    data: { title: 'menu.login' },
    resolve: [IsLoggedIn]
  },
  {
    path: 'register',
    canActivate: [BrowserSupportGuard],
    component: RegisterComponent,
    data: { title: 'menu.register' },
    resolve: [IsLoggedIn]
  },
  {
    path: 'demo',
    component: RegisterDemoComponent,
    resolve: [IsLoggedIn]
  },
  {
    path: 'forgot',
    canActivate: [BrowserSupportGuard],
    component: ForgotPasswordComponent,
    data: { title: 'menu.forgot' },
    resolve: [IsLoggedIn]
  },
  {
    path: 'change-password/:token',
    canActivate: [BrowserSupportGuard],
    component: ChangePasswordComponent,
    data: { title: 'menu.forgot' }
  },
  {
    path: 'accessdenied',
    component: AccessDeniedComponent,
    data: { title: 'menu.accessdenied' }
  },
  {
    path: '**',
    component: PageErrorComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled'
      // preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
