import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AbstractControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { isNullOrUndefined } from 'util';
import { storageKeys, UNDEFINED } from './config-data';
import { Roles } from 'src/app/shared/models/roles';
import { KidSubject } from 'src/app/features/new-kid/models/overview.model';
const { detect } = require('detect-browser');
const browser = detect();

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const schoolImages = {
  bee_image: 'assets/icons/Kids/blackBee.svg',
  owl_image: 'assets/icons/Kids/owl.svg',
  logo_image: 'assets/icons/Kids/scobees_logo_black.svg',
  kompass_image: 'assets/icons/kompass.png',
  heart_image: 'assets/icons/heart.png',
  birne_image: 'assets/icons/Birne.png'
};

/**
 * This function is used to get ids from an array
 * @param array
 */
export function getIds(array): Array<any> {
  const ids = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (!element.id) {
      console.error('Iterated array most have an id:', array);
      return;
    }
    ids.push(element.id);
  }
  return ids;
}

export function getKidNames(array): Array<any> {
  const kids = [];
  for (const kid of array) {
    if (!kid.id) {
      console.error('Iterated array most have an id:', array);
      return [];
    }
    kids.push({ id: kid.id, name: kid.name });
  }
  return kids;
}

/**
 * This function is used to get ids from an array
 * @param array
 */
export function getOneAttributeAsArray(attribute, array): Array<number> {
  const items = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (!element[attribute]) {
      console.error('Iterated array most have an attribute with name: ' + attribute, array);
      return;
    }
    items.push(element[attribute]);
  }
  return items;
}

/**
 * This function is used to create and export excel files
 */
export function exportCSV(fileName, jsonData: Array<any>): void {
  const datas = XLSX.utils.json_to_sheet(jsonData);
  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new(); // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, datas); // sheetAName is name of Worksheet
  // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

  // export Excel file
  XLSX.writeFile(wb, fileName + '.csv'); // name of the file is 'book.xlsx'
}

// export function exportExcel(json: any[], excelFileName: string): void {

//   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
//   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//   // Save file
//   const data: Blob = new Blob([excelBuffer], {
//     type: EXCEL_TYPE
//   });
//   saveAs(data, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
// }

export function exportExcel(excelFileName: string, json: any[] = [], header: any): void {
  if (header) {
    json.unshift(header);
  }
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
    skipHeader: true
  });
  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ['data']
  };
  const excelBuffer: any = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  // Save file
  const data: Blob = new Blob([excelBuffer], {
    type: EXCEL_TYPE
  });
  saveAs(data, excelFileName + EXCEL_EXTENSION);
}

export function markFormAsTouched(form: FormGroup): void {
  for (const i in form.controls) {
    if (!form.controls.hasOwnProperty(i)) {
      continue;
    }
    form.controls[i].markAsTouched();
  }
}
export function markFormAsUnTouched(form: FormGroup): void {
  for (const i in form.controls) {
    if (!form.controls.hasOwnProperty(i)) {
      continue;
    }
    form.controls[i].markAsUntouched();
  }
}

export function convertToSqlDate(dateObj: Date): string {
  if (!(dateObj instanceof Date)) {
    dateObj = new Date(dateObj);
  }

  const year = dateObj.getFullYear();
  const dateMonth = dateObj.getMonth() + 1;
  const month = dateMonth < 10 ? `0${dateMonth}` : dateMonth;
  const date = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
  return `${year}-${month}-${date}`;
}

export function convertArrayToSqlDate(array: any[], keys): any[] {
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < keys.length; j++) {
      array[i][keys[j]] = convertToSqlDate(array[i][keys[j]]);
    }
  }
  return array;
}

/**
 * This function is used to get ids from an array
 * @param array
 */
export function getMaxDate(array): Date {
  let maxDate = new Date(array[0]);
  for (let i = 1; i < array.length; i++) {
    if (!array[i]) continue;
    const date = new Date(array[i]);
    if (date.getTime() > maxDate.getTime()) {
      maxDate = date;
    }
  }
  return maxDate;
}
/**
 * This function is used to get ids from an array
 * @param array
 */
export function getMinDate(array): Date {
  let maxDate = new Date(array[0]);
  for (let i = 1; i < array.length; i++) {
    if (!array[i]) continue;
    const date = new Date(array[i]);
    if (date.getTime() < maxDate.getTime()) {
      maxDate = date;
    }
  }
  return maxDate;
}

export function minutesToTime(minutes): string {
  let time = '';
  const timeObj = moment.duration({ minutes: minutes });
  time = timeObj.minutes() + ' m';
  if (timeObj.hours() > 0) {
    time = timeObj.hours() + ' h ' + minutes;
  }
  return time;
}

export function isDefined(variable) {
  return typeof variable !== UNDEFINED && variable !== null;
}

export function getStorageItem(item) {
  return item in localStorage ? localStorage.getItem(item) : null;
}
export function setStorageItem(item, value) {
  value ? localStorage.setItem(item, value) : localStorage.removeItem(item);
}

export function getStorageObjectItem(item) {
  return item in localStorage ? JSON.parse(localStorage.getItem(item)) : {};
}

export function setStorageObjectItem(item, value) {
  value ? localStorage.setItem(item, JSON.stringify(value)) : localStorage.removeItem(item);
}

export function isDemo() {
  const user = getStorageObjectItem(storageKeys.user);
  return user.is_demo === 1;
}
export function isLead() {
  const role = localStorage.getItem('role');
  return role === Roles.leadTeacher;
}
export function isTeacher() {
  const role = localStorage.getItem('role');
  return role === Roles.teacher;
}
export function isKid() {
  const role = localStorage.getItem('role');
  return role === Roles.kid;
}
export function getSchoolIcon(type: string) {
  const school = getStorageObjectItem(storageKeys.school);
  let icon = schoolImages[type];

  if (getSafe(() => school[type])) {
    icon = school[type];
  }
  return icon;
}

export function getSafe(callback) {
  try {
    return callback();
  } catch (event) {
    return null;
  }
}
export function getKeys(array, key): Array<any> {
  const keys = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (!element[key]) {
      console.error('Iterated array most have an key:', array);
      return;
    }
    keys.push(element[key]);
  }
  return keys;
}
export function formatDate(date, format = 'YYYY-MM-DD') {
  let isoDate;
  try {
    isoDate = new Date(date).toISOString();
  } catch (err) {
    return null;
  }
  const dateObj = moment(isoDate).format(format);
  return date ? dateObj.toString() : null;
}

export function getDayOfWeek(date: Date) {
  return {
    startDate: getStartDayOfWeek(date),
    endDate: getEndDayOfWeek(date)
  };
}

export function getStartDayOfWeek(date: Date) {
  return moment(date)
    .startOf('isoWeek')
    .format('YYYY-MM-DD');
}

export function getEndDayOfWeek(date: Date) {
  return moment(date)
    .endOf('isoWeek')
    .format('YYYY-MM-DD');
}

export function getDayOfYear(date: Date) {
  return {
    startDate: getStartDayOfYear(date),
    endDate: getEndDayOfYear(date)
  };
}
export function getStartDayOfYear(date: Date) {
  return moment(date)
    .startOf('year')
    .format('YYYY-MM-DD');
}

export function getEndDayOfYear(date: Date) {
  return moment(date)
    .endOf('year')
    .format('YYYY-MM-DD');
}

export function isInThisWeek(date: Date): boolean {
  const now = new Date();
  const thisWeek = getDayOfWeek(now);
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return formattedDate >= thisWeek.startDate && formattedDate <= thisWeek.endDate;
}

export function getDaysList() {
  const language = localStorage.getItem('language') || 'de';
  moment.locale(language);
  return Array.apply(null, Array(7)).map(function(_, i) {
    return moment(i, 'e')
      .startOf('week')
      .isoWeekday(i + 1)
      .format('dddd');
  });
}

export function getDayName(date: string) {
  const language = localStorage.getItem('language') || 'de';
  moment.locale(language);
  return moment(date).format('dddd');
}

export function fillStringData(string, object) {
  for (const iterator in object) {
    string = string.replace(iterator, object[iterator]);
    string = string.replace(':', '');
  }

  return string;
}

export function checkRequiredParams(params: any, required: string[]) {
  for (const requiredParam of required) {
    if (isNullOrUndefined(params[requiredParam])) {
      return false;
    }
  }
  return true;
}

export function getUniqueObjects(array) {
  return array.reduce((element, x) => element.concat(element.find(y => y.id === x.id) ? [] : [x]), []);
}

export function findByProperty(array, property, id) {
  return array.filter(element => element[property] === id);
}

export function replaceObjectKey(object: any, oldkey: string, newKey: string) {
  if (object[oldkey] !== undefined) {
    object[newKey] = object[oldkey];
    delete object[oldkey];
  }
}

export function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
}
export function deepClone(object) {
  const clonedString = JSON.stringify(object, getCircularReplacer());
  const clonedObject = JSON.parse(clonedString);
  return clonedObject;
}

export function deleteObjectKeys(data: any = {}, deleteKeys: string[] = []) {
  for (const key in data) {
    if (!deleteKeys.includes(key) && (data[key] instanceof Object || data[key] instanceof Array)) {
      deleteObjectKeys(data[key], deleteKeys);
      continue;
    }
    if (deleteKeys.includes(key)) {
      delete data[key];
    }
  }
}
export function timeDifferenceToString(value) {
  if (!value) {
    value = '00';
  } else if (value < 10) {
    value = '0' + value;
  }
  return value;
}
export function setFormFieldOptional(formField: AbstractControl) {
  formField.clearValidators();
  formField.setErrors(null);
}

export function isBrowserAllowed() {
  let isAllowed = false;

  switch (browser && browser.name) {
    case 'chrome':
    case 'safari':
    case 'ios':
    case 'crios':
    case 'firefox':
    case 'edge-chromium':
      isAllowed = true;
      break;
    default:
      console.log('not supported');
  }

  return isAllowed;
}

export function hasBrowserSupport(url: string) {
  const isAllowed = isBrowserAllowed();
  return isAllowed || (!isAllowed && ['/demo', '/login-demo'].includes(url)) || (!isAllowed && isDemo() && url !== '/login');
}

export function findLongestArray(masterArray, arrayKey) {
  let max = 0;
  for (const value of masterArray) {
    if (value[arrayKey].length > max) {
      max = value[arrayKey].length;
    }
  }
  return max;
}

export function generateColumns(columnsNumber) {
  const column = [];
  for (let i = 0; i < columnsNumber; i++) {
    column[i] = {
      name: i + 1,
      value: (600 / columnsNumber) * (i + 1)
    };
  }
  return column;
}

export function getFileType(filePath) {
  return filePath ? filePath.substr(filePath.lastIndexOf('.') + 1).toLocaleLowerCase() : null;
}

export function ckeContentRemoveHtmlTags(content: string) {
  return content ? content.replace(/( |<([^>]+)>)/gi, ' ').replace(/&nbsp;/gi, '') : null;
}

export function capitalizeFirstLetterOfWord(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
export function getInitials(fullName) {
  const newName = fullName.split(' ');
  const initials = newName[0][0] + '.' + newName[1];
  return initials;
}

export function getNotEmptyValues(content: Object): string[] {
  if (!Boolean(content)) return [];
  return Object.values(content).filter(Boolean);
}

export function sortSubjectsByTopic(subject: KidSubject): void {
  if (subject && subject.topics) {
    subject.topics.sort((topicOne, topicTwo) => topicOne.sortOrder - topicTwo.sortOrder);
  }
}
