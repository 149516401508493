import { styleClass } from '@app-core/services/helpers/config-data';
import { Component, OnInit, ViewChild, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app-core/animations/route.animations';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app-core/services/app.service';
import { Router } from '@angular/router';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { DEFAULT_PAGEINDEX } from 'src/app/features/admin/models/admin.config';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';

export interface GTableColumn {
  key: string;
  translatedKey: string;
  type: string;
  sortable?: boolean;
}
export interface GTableTemplate {
  id: string;
  template: TemplateRef<any>;
}
@Component({
  selector: 'app-g-table',
  templateUrl: './g-table.component.html',
  styleUrls: ['./g-table.component.scss']
})
export class GTableComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;

  @Input() columns: Array<GTableColumn>;
  @Input() dataSource: MatTableDataSource<any>;
  @Input() private getAllUrl: string; // '/schools'
  @Input() editUrl: string;
  @Input() deletedSnackbarMessage: string; // admin.manage-schools.deleted
  @Input() archiveSnackbarMessage: string; // admin.manage-schools.deleted
  @Input() searchAlign: string;
  @Input() actions = [];
  @Input() actionTemplate: TemplateRef<any>;
  @Input() customActionTable: TemplateRef<any>;
  @Input() tableActionTemplate: TemplateRef<any>;
  @Input() toggleTemplate: TemplateRef<any>;
  @Input() customTemplate: TemplateRef<any>;
  @Input() hasSelection: boolean;
  @Input() editHasModal = true;
  @Input() noHeaders = false;
  @Input() hasExternalCall = false;
  @Input() noActions = false;
  @Input() templates: Array<GTableTemplate>;
  @Input() getAllParams: any = {}; // passed as input because filters may be in parent
  @Input() noResIcon = 'search';

  @Output() onEdit = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns = [];

  selection = new SelectionModel<any>(true, []);
  isCallingApi = true;

  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    if (this.hasSelection) {
      this.displayedColumns = ['select'];
    }
    this.columns.forEach((column: GTableColumn) => {
      this.displayedColumns.push(column.key);
    });
    this.paginator.pageIndex = DEFAULT_PAGEINDEX;
    this.paginator.pageSize = 20;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!this.hasExternalCall) this.handleGetAll();
  }

  onStopPropagation(event) {
    event.stopPropagation();
  }

  /**
   * This function is used to get all schools from server and bind it to
   * datasource and fit to paginator
   */
  private getAll() {
    this.isCallingApi = true;
    this.appService.getElement(this.getAllUrl, this.getAllParams).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.data.page);
      this.paginator.pageIndex = res.data.pagination.current_page - 1;
      this.paginator.pageSize = res.data.pagination.limit;
      this.paginator.length = res.data.pagination.total_count;
      this.isCallingApi = false;
      this.selection.clear();
    });
  }

  /**
   * This is an event that handles the sort on headers of mat tables
   * @param event
   */
  sortTable(event) {
    if (event.direction === '') {
      this.sort.direction = null;
      this.sort.active = null;
    }
    this.handleGetAll();
  }

  /**
   * This is a central function that handles query params
   * about pagination and sorting for the api call
   */
  handleGetAll(queryParams?: any) {
    if (queryParams) {
      this.getAllParams = queryParams;
    }
    this.getAllParams.limit = this.paginator.pageSize;
    this.getAllParams.page = this.paginator.pageIndex + 1;
    if (this.sort.active) {
      this.getAllParams.orderBy = this.sort.active;
    }
    if (this.sort.direction) {
      this.getAllParams.orderDir = this.sort.direction.toUpperCase();
    }
    this.getAll();
  }

  /**
   * This is a function that opens an entryComponent for
   * confirmin the deletion
   * @param item
   */
  deleteItem(item: any) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: this.translate.instant(`general.delete.${this.getAllUrl}`),
          message: this.translate.instant(`general.deletemessage`),
          buttonText: {
            ok: this.translate.instant(`general.archive`),
            sure: this.translate.instant(`general.delete`),
            cancel: this.translate.instant(`general.cancel`)
          },
          okBtnColor: 'primary',
          okSureBtnColor: 'warn'
        }
      })
      .afterClosed()
      .subscribe((action: any) => {
        let purge = '';
        let message = this.archiveSnackbarMessage;
        if (['delete', 'confirm'].indexOf(action) !== -1) {
          if (action === 'delete') {
            message = this.deletedSnackbarMessage;
            purge += 'purge/';
          }
          this.appService
            .deleteElement(this.getAllUrl + '/' + purge + item.id, {
              id: item.id
            })
            .subscribe(() => {
              const toast = {
                message: message,
                styleClass: styleClass.success
              } as Toast;
              this.getAll();
              this.snackBarService.addCustom(toast);
            });
        }
      });
  }

  editItem(item) {
    if (this.editHasModal) {
      this.onEdit.emit({ domain: this.getAllUrl, item: item });
    } else {
      this.redirectToEdit(item);
    }
  }
  /**
   * This is a function that extracts the id from the item and redirect the user
   * on edit page
   * @param item
   */
  redirectToEdit(item) {
    this.router.navigate([this.editUrl + '/' + item.id]);
  }

  /**
   * This is a function that extract the string from event of search
   * and makes a request to getAll
   * @param event
   */
  onSearch(event) {
    if (event.search) {
      this.getAllParams.search = event.search;
    } else {
      delete this.getAllParams.search;
    }
    this.getAll();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    this.onSelect.emit(this.selection.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  /**
   * This is a function that handles single selects
   * @param event
   * @param row
   */
  onSelectSingle(event, row) {
    if (event) {
      this.selection.toggle(row);
    }
    this.onSelect.emit(this.selection.selected);
  }

  resetCheckboxes() {
    this.selection.clear();
  }
}
