import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from '../notifications/notification.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
const BAD_REQUEST = 400;
/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
interface ServerError {
  error_code: number;
  errors: Array<any>;
  message: string;
}
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private translate: TranslateService, private notificationsService: NotificationService) {
    super();
  }

  /**
   * This is a function that overides the method of ErrorHandler of system
   * @param error
   */
  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    } else {
      // this.handleGeneralError(error);
    }
    super.handleError(error);
  }

  /**
   * This is a function that handles error of https
   * @param error
   */
  handleHttpError(error: HttpErrorResponse) {
    switch (error.status) {
      case BAD_REQUEST:
        this.notificationsService.error(this.translate.instant(`error.${error.error.error_code}`));
        break;
      default:
        // this.handleGeneralError(error);
        break;
    }
  }

  /**
   * This is a function that handles errors of system and unhandled errors of https
   * @param error
   */
  handleGeneralError(error: Error | HttpErrorResponse) {
    let displayMessage = 'An error occurred.';

    if (!environment.production) {
      displayMessage += ' See console for details.';
    }

    this.notificationsService.error(displayMessage);
  }
}
