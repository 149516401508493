import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
// import cacheBusting from '../../../../../i18n-cache-busting.json';
const cacheBusting = {};
@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    const path = `/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
    return this.http.get(path);
  }
}

export const translocoLoader = {
  provide: TRANSLOCO_LOADER,
  useClass: HttpLoader
};
