import { TranslateService } from '@ngx-translate/core';
import { Toast, SnackBarService } from './../../../../../core/services/snackbar/snack-bar.service';
import { AppService } from './../../../../../core/services/app.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { styleClass } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-contact-to-switch-dialog',
  templateUrl: './contact-to-switch-dialog.component.html',
  styleUrls: ['./contact-to-switch-dialog.component.scss']
})
export class ContactToSwitchDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContactToSwitchDialogComponent>,
    private appService: AppService,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  onEmailSend() {
    this.appService.newElement('/users/demo/redeem-account', {}).subscribe(() => {
      this.dialogRef.close();
      this.snackBarService.addCustom({
        message: this.translate.instant(`general.email_sended`),
        styleClass: styleClass.success
      } as Toast);
    });
  }
}
