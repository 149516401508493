import { GTableModule } from 'src/app/shared/features/g-table/g-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddColleagueDialogComponent } from 'src/app/features/admin/dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColleaguesComponent } from './colleagues/colleagues.component';

@NgModule({
  declarations: [ColleaguesComponent, AddColleagueDialogComponent],
  entryComponents: [AddColleagueDialogComponent],
  exports: [ColleaguesComponent, AddColleagueDialogComponent],
  imports: [CommonModule, SharedModule, GTableModule]
})
export class ColleaguesModule {}
