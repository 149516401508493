import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appOverflow]'
})
export class OverflowDirective {
  @Output() appOverflow = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {
    this.checkOverflow();
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.checkOverflow();
  }

  checkOverflow() {
    if (
      this.elementRef.nativeElement.offsetHeight < this.elementRef.nativeElement.scrollHeight ||
      this.elementRef.nativeElement.offsetWidth < this.elementRef.nativeElement.scrollWidth
    ) {
      this.appOverflow.emit(true);
    } else {
      this.appOverflow.emit(false);
    }
  }
}
