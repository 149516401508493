import { Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { Subjects, Grades, NewTeacherSettings, TeacherSettingsEntity, WorkSubjects } from 'src/app/shared/models/teacher-settings/teacher-settings.model';
import { getKeys } from '@app-core/services/helpers/helpers';
import { AppService } from '@app-core/services/app.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-new-update-teacher-settings',
  templateUrl: './new-update-teacher-settings.component.html',
  styleUrls: ['./new-update-teacher-settings.component.scss']
})
export class NewUpdateTeacherSettingsComponent implements OnInit {
  activeStep = 0;
  works: WorkSubjects[] = [];
  subjectIds: number[] = [];

  subjectsGrades: Subjects[] = [];
  isStepSubjectsValid = false;
  isStepGradesValid = false;

  newTeacherSettings: NewTeacherSettings[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewUpdateTeacherSettingsComponent>,
    private appService: AppService,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.setInfo();
  }
  setInfo() {
    this.works = this.data.works;
    this.handleStepSubjectsValidation();
  }

  getSubjectsGrades() {
    this.setSubjectsIds();
    this.appService
      .getAll('/school/subjects/grades', {
        subjectIds: this.subjectIds.toString()
      })
      .subscribe(response => {
        if (response.data) {
          this.subjectsGrades = response.data;
          this.handleStepGradesValidation();
        }
        this.activeStep = 1;
      });
  }

  onStepChange(event: StepperSelectionEvent) {
    this.activeStep = event.selectedIndex;
  }
  onStepBack() {
    this.activeStep -= 1;
    this.isStepGradesValid = false;
  }

  onSubjectToggle(subject: Subjects) {
    subject.selected = !subject.selected;
    this.handleStepSubjectsValidation();
  }
  onSubjectChanged() {
    this.handleStepSubjectsValidation();
  }

  handleStepSubjectsValidation() {
    for (const works of this.works) {
      const subjectsSelected = works.subjects.filter(s => s.selected === true);
      if (subjectsSelected.length > 0) {
        this.isStepSubjectsValid = true;
        return;
      }
    }
    this.isStepSubjectsValid = false;
  }

  setSubjectsIds() {
    this.subjectIds = [];
    for (const work of this.works) {
      this.subjectIds.push(...getKeys(this.subjectSelected(work.subjects), 'subject_id'));
    }
  }

  subjectSelected(subjects: Subjects[] = []) {
    return subjects.filter(s => s.selected === true);
  }

  onGradeToggle(grade: Grades) {
    grade.selected = !grade.selected;
    this.handleStepGradesValidation();
  }
  onGradeChange() {
    this.handleStepGradesValidation();
  }

  handleStepGradesValidation() {
    for (const subject of this.subjectsGrades) {
      const selectedGrades = subject.grades.filter(g => g.selected === true);
      if (selectedGrades.length === 0) {
        this.isStepGradesValid = false;
        return;
      }
    }
    this.isStepGradesValid = true;
  }

  onNewTeacherSettings() {
    this.setNewTeacherSettings();

    this.appService.newElement('/v2/teachings', { teaching_data: this.newTeacherSettings }).subscribe(() => {
      this.dialogRef.close(true);
      this.snackBarService.addCustom({
        message: this.translate.instant(`admin.manage-schools.assinged.subjects_grade`),
        styleClass: styleClass.success
      } as Toast);
    });
  }

  setNewTeacherSettings() {
    this.newTeacherSettings = [];
    for (const subject of this.subjectsGrades) {
      this.newTeacherSettings.push({
        subject_id: subject.subject_id,
        work_id: subject.work_id,
        subject_name: subject.subject_name,
        work_name: subject.work_name,
        grades: getKeys(
          subject.grades.filter(g => g.selected === true),
          'grade'
        )
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
