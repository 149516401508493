import { Component, OnInit } from '@angular/core';
import { routeAnimations } from '@app-core/animations/route.animations';

@Component({
  selector: 'app-teacher-layout',
  templateUrl: './teacher-layout.component.html',
  styleUrls: ['./teacher-layout.component.scss'],
  animations: [routeAnimations]
})
export class TeacherLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
