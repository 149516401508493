import { MatMenuTrigger } from '@angular/material';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss']
})
export class EmojiPickerComponent implements OnInit {
  @Output() onChange = new EventEmitter<string>();

  @ViewChild('emojiMenuTrigger', { static: false })
  emojiMenuTrigger: MatMenuTrigger;

  constructor() {}

  ngOnInit() {}

  onEmojiSelect(event) {
    this.onChange.emit(event.emoji.native);
    this.emojiMenuTrigger.closeMenu();
  }
}
