import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app-core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LearningGroups } from 'src/app/features/admin/models/school.model';

export interface SortOption {
  id: number;
  orderBy: string;
  orderDir: 'ASC' | 'DESC';
  label: string;
}

@Component({
  selector: 'app-teacher-dropdown',
  templateUrl: './teacher-dropdown.component.html',
  styleUrls: ['./teacher-dropdown.component.scss']
})
export class TeacherDropdownComponent implements OnInit {
  @Input() label = 'general.choose';
  @Input() optionsShown: string[];
  @Input() belongsToFavorites = false;

  @Output() onChange = new EventEmitter();
  isDropdownOpen = false;

  selectedOption: SortOption;

  options: SortOption[] = [
    {
      id: 1,
      orderBy: 'newTasks',
      orderDir: 'DESC',
      label: 'sort.new_tasks'
    },
    {
      id: 2,
      orderBy: 'name',
      orderDir: 'ASC',
      label: 'sort.name_a_z'
    },
    {
      id: 3,
      orderBy: 'name',
      orderDir: 'DESC',
      label: 'sort.name_z_a'
    },
    {
      id: 4,
      orderBy: 'learningGroup',
      orderDir: 'ASC',
      label: 'sort.learning_group_m_fo'
    },
    {
      id: 5,
      orderBy: 'learningGroup',
      orderDir: 'DESC',
      label: 'sort.learning_group_fo_m'
    },
    {
      id: 6,
      orderBy: 'progress',
      orderDir: 'ASC',
      label: 'sort.progress_0_100'
    },
    {
      id: 7,
      orderBy: 'progress',
      orderDir: 'DESC',
      label: 'sort.progress_100_0'
    },
    {
      id: 8,
      orderBy: 'stars',
      orderDir: 'ASC',
      label: 'sort.stars_0_10'
    },
    {
      id: 9,
      orderBy: 'stars',
      orderDir: 'DESC',
      label: 'sort.stars_10_0'
    }
  ];

  constructor(private appService: AppService, private translate: TranslateService) {}

  ngOnInit() {
    this.getLearningGroups();
    this.handelOptionsShown();
    this.setDefalutSort();
  }
  getLearningGroups() {
    if (this.belongsToFavorites) {
      this.appService.getAll('/options/learning-groups').subscribe((response: any) => {
        this.handleLearningGroupsLabels(response.data);
      });
    }
  }

  onElementSelect(option: SortOption) {
    this.isDropdownOpen = false;
    this.selectedOption = option;
    this.onChange.emit({
      orderBy: this.selectedOption.orderBy,
      orderDir: this.selectedOption.orderDir
    });
  }

  handelOptionsShown() {
    if (this.optionsShown) {
      this.options = this.options.filter(option => this.optionsShown.indexOf(option.orderBy) !== -1);
    }
  }
  handleLearningGroupsLabels(learnigGroups: LearningGroups[] = []) {
    const firstGroup = learnigGroups[1].name.substring(0, 2);
    const lastGroup = learnigGroups[learnigGroups.length - 1].name.substring(0, 2);

    this.options[3].label = this.translate.instant('sort.learning_group', {
      start: firstGroup,
      end: lastGroup
    });
    this.options[4].label = this.translate.instant('sort.learning_group', {
      start: lastGroup,
      end: firstGroup
    });
  }

  setDefalutSort() {
    this.selectedOption = this.options[0];
    this.onElementSelect(this.selectedOption);
  }
}
