import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss']
})
export class EmptyCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
