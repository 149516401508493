import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { AppService } from '@app-core/services/app.service';

@Component({
  selector: 'app-add-reflection-kid-dialog',
  templateUrl: './add-reflection-kid-dialog.component.html',
  styleUrls: ['./add-reflection-kid-dialog.component.scss']
})
export class AddReflectionKidDialogComponent implements OnInit {
  description = new FormControl(null, Validators.required);

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<AddReflectionKidDialogComponent>,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.getReflection();
  }

  onConfirm() {
    if (this.description.valid) {
      this.bottomSheetRef.dismiss({
        description: this.description.value
      });
    } else {
      this.description.markAsTouched();
    }
  }
  onCancel() {
    this.bottomSheetRef.dismiss();
  }

  getReflection() {
    this.appService.getAll(`/proud-of`).subscribe((response: any) => {
      if (response && response.data) {
        this.description.setValue(response.data.description);
      }
    });
  }
}
