export const INVALID_EMAIL_PASSWORD = 100;
export const VALIDATION_ERROR = 101;
export const NOT_AUTHENTICATED = 102;
export const ACCESS_TOKEN_INVALID = 103;
export const TOKEN_EXPIRED = 107;
export const NOT_AUTHORIZED = 104;
export const EMAIL_FAILED = 108;
export const INVALID_FILE = 109;
export const FILE_MISSING = 110;
export const USER_NOT_FOUND = 111;
export const COLLEAGUE_NOT_FOUND = 112;
export const SCHOOL_NOT_FOUN = 113;
export const SUBJECT_NOT_FOUND = 114;
export const WORK_NOT_FOUND = 115;
export const TAG_NOT_FOUND = 116;
export const USER_ALREADY_EXISTS = 117;
export const COLLEAGUE_ALREADY_EXISTS = 118;
export const EMAIL_ALREADY_EXISTS = 119;
export const LEARNING_LEVEL_NOT_FOUND = 120;
export const COMPETENCE_RASTER_NOT_FOUND = 121;
export const LEARNING_PLAN_NOT_FOUND = 122;
export const FILTER_NOT_FOUND = 123;
export const KID_NOT_FOUND = 124;
export const OLD_PASSWORD_INVALID = 125;
export const TEACHING_ALREADY_EXISTS = 126;
export const TEACHING_NOT_FOUN = 127;
