import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app-core/services/app.service';
import { markFormAsTouched } from '@app-core/services/helpers/helpers';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-not-my-child',
  templateUrl: './not-my-child.component.html',
  styleUrls: ['./not-my-child.component.scss']
})
export class NotMyChildComponent implements OnInit {
  noMyChildForm: FormGroup;
  code: string;
  kidName: string;

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private dialog: MatDialog
  ) {
    this.code = this.route.snapshot.paramMap.get('code');
  }

  ngOnInit() {
    this.noMyChildForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      message: [null, Validators.required]
    });
    this.appService.getElement('/parents/kids/' + this.code).subscribe((response: any) => {
      this.kidName = response.data.firstName + ' ' + response.data.lastName;
    });
  }

  onSendMessageSubmit() {
    markFormAsTouched(this.noMyChildForm);

    let toast = {} as Toast;
    if (this.noMyChildForm.valid) {
      const data = {
        name: this.noMyChildForm.value.name,
        email: this.noMyChildForm.value.email,
        code: this.code,
        message: this.noMyChildForm.value.message
      };

      this.appService.newElement('/parents/not-my-child', data).subscribe(() => {
        this.showMessageDialog();
        this.snackBarService.addCustom(toast);
      });
    } else {
      toast = {
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast;

      this.snackBarService.addCustom(toast);
    }
  }
  showMessageDialog() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant(`general.note`),
        message: this.translate.instant(`general.send_message`),
        buttonText: {
          cancel: this.translate.instant(`general.close`)
        }
      }
    });
  }

  onBackToSignUp() {
    this.router.navigate([`../register-parent/${this.code}`]);
  }
}
