import { AnalyzeService } from './../../../../features/analyze/analyze/services/analyze.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppService } from '@app-core/services/app.service';

@Component({
  selector: 'app-navigate-card',
  templateUrl: './navigate-card.component.html',
  styleUrls: ['./navigate-card.component.scss']
})
export class NavigateCardComponent implements OnInit {
  @Input() title: string;
  @Input() color = '#1f838e';
  @Input() subTitle: number;
  @Input() learningGroups: string[];
  @Input() bookmarked = 0;
  @Input() isBookmarked: boolean;
  @Input() total: number;
  @Input() topic: string;
  @Input() type: string;
  @Input() id: number;
  @Input() learningGroup: string;
  @Input() image: string;
  @Input() rotateArrow: false;

  @Input() isDisabled = false;

  @Output() onSelectCardClick = new EventEmitter();
  @Output() groupCardTitleClick = new EventEmitter();

  constructor(private appService: AppService, private sharedService: SharedService, private analyzeService: AnalyzeService) {}

  ngOnInit() {}

  onSelectCardCLick() {
    this.onSelectCardClick.emit();
  }

  onBookmarkClick() {
    if (this.isBookmarked) {
      this.unsetBookmark();
    }
  }

  unsetBookmark() {
    const url = this.analyzeService.getBookmarkApiUrl({
      type: this.type,
      id: this.id,
      gradeClassId: this.id,
      learningGroup: this.learningGroup,
      isDelete: true
    });

    this.appService.deleteElement(url).subscribe(response => {
      this.isBookmarked = !this.isBookmarked;
      this.sharedService.bookmarkListChange();
    });
  }

  onGroupCardTitleClick() {
    this.groupCardTitleClick.emit();
  }
}
