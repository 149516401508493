import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-navigation-box',
  templateUrl: './subject-navigation-box.component.html',
  styleUrls: ['./subject-navigation-box.component.scss']
})
export class SubjectNavigationBoxComponent implements OnInit {
  @Input() title = '';
  @Input() color = '#D7E4E0';
  @Input() learningGroups: string[];

  constructor() {}

  ngOnInit() {}
}
