import { Injectable, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
declare var $: any;

@Injectable()
export class SettingsService {
  @Output() languageChange = new EventEmitter();
  user: any;
  app: any;
  errorTranslation: any;
  layout: any;

  constructor(private overlayContainer: OverlayContainer, private translate: TranslateService, private router: Router) {
    this.errorTranslation = this.translate.get('error');
    // User Settings
    // -----------------------------------
    this.user = {
      name: '',
      job: '',
      picture: 'assets/img/user/02.jpg'
    };

    // App Settings - footer
    // -----------------------------------
    this.app = {
      name: 'Soluzione 1',
      description: 'Archetipo Soluzione 1 Frontend',
      year: new Date().getFullYear()
    };

    // Layout Settings - basic settings for TeacherLayoutFeedback & Co.
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: 'ng-fadeInUp',
      isWithFold: true
    };
  }

  getAppSetting(name: string | number) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name: string | number) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name: string | number) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name: string | number, value: any) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }
  setUserSetting(name: string | number, value: any) {
    if (typeof this.user[name] !== 'undefined') {
      this.user[name] = value;
    }
  }
  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return (this.layout[name] = value);
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

  sessionExpired() {
    localStorage.clear();
  }

  manageErrorMsg(outcome) {
    this.errorTranslation = this.translate.get('error');
    let message = '';
    switch (outcome.code) {
      case '0001':
        message = this.errorTranslation.value.error_0001;
        break;
      case '0002':
        message = this.errorTranslation.value.error_0002;
        break;
      case '0003':
        message = this.errorTranslation.value.bad_credential;
        break;
      case '0004':
        this.sessionExpired();
        this.router.navigate(['/login']);
        break;
      case '0005':
        message = this.errorTranslation.value.error_0005;
        break;
      case '0006':
        message = this.errorTranslation.value.account_disabled;
        break;
      case '0007':
        message = this.errorTranslation.value.auth_required;
        break;
      case '0008':
        message = this.errorTranslation.value.error_0008;
        break;
      case '0010':
        this.sessionExpired();
        this.router.navigate(['/login']);
        message = this.errorTranslation.value.activation_token_expired;
        break;
      default:
        message = this.errorTranslation.value.generic_error;
        break;
    }
    return message;
  }
  changeTheme(themeClass: string) {
    // remove old theme class and add new theme class
    // we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(themeClass);
  }

  setLanguage(language: string) {
    if (language) localStorage.setItem('language', language);
    this.languageChange.emit();
  }
}
