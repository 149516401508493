import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { actions, fontSizes, storageKeys } from '@app-core/services/helpers/config-data';
import { setStorageItem } from '@app-core/services/helpers/helpers';

@Component({
  selector: 'app-font-size-controller',
  templateUrl: './font-size-controller.component.html',
  styleUrls: ['./font-size-controller.component.scss']
})
export class FontSizeControllerComponent implements OnInit {
  @Input() fontSize: number;
  @Output() fontSizeChange = new EventEmitter<number>();

  actions = actions;
  fontSizes = fontSizes;

  constructor() {}

  ngOnInit() {}

  onFontSizeChange(action: string) {
    if (action === actions.decrement) {
      this.fontSize--;
    } else {
      this.fontSize++;
    }
    setStorageItem(storageKeys.fontSize, this.fontSize);
    this.fontSizeChange.emit(this.fontSize);
  }
}
