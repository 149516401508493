import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { addIntroOptions } from '@app-core/services/helpers/demo';
import { TranslateService } from '@ngx-translate/core';

interface IStep {
  element: string;
  intro: string;
  position: string;
}

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit {
  @Input() steps: IStep[];
  @Input() showGuideDirectly = false;
  @Input() isPhaseSection: boolean;
  @Input() type: string;

  isHidden = true;

  constructor(private sharedService: SharedService, private translate: TranslateService) {}

  ngOnInit() {
    this.sharedService.onActiveDemoPage.subscribe(steps => {
      this.steps = steps;
      this.onActivateDemoClick();
    });
  }

  onActivateDemoClick() {
    if (this.type === 'phase') {
      this.sharedService.closePhaseInfo(false);
    }
    const options = {
      steps: this.steps.map(step => {
        return {
          ...step,
          tooltipClass: 'demo-buttons'
        };
      }),
      skipLabel: this.translate.instant(`general.cancel`),
      prevLabel: this.translate.instant(`general.back`),
      nextLabel: this.translate.instant(`general.next`),
      doneLabel: this.translate.instant(`general.finish`),
      showStepNumbers: false,
      tooltipPosition: 'bottom',
      exitOnOverlayClick: false,
      showProgress: false,
      scrollToElement: true,
      hideNext: true,
      hidePrev: true,
      disableInteraction: true
    };
    addIntroOptions(options);
  }

  onActivateVideoTutorial() {
    window.open('https://www.youtube.com/watch?v=8U4FcXwSMnI&list=PLv-TCf3Fq218pzlbcScyFsGB55nzw7QOZ&index=2', '_blank');
  }

  onOpenPdfClick() {
    window.open('assets/files/Scobees_Flyer.pdf', '_blank');
  }
}
