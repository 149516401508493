import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mobile-loader',
  templateUrl: './mobile-loader.component.html',
  styleUrls: ['./mobile-loader.component.scss']
})
export class MobileLoaderComponent implements OnInit {
  @Input() content: TemplateRef<any>;
  @Input() items: number;
  @Input() isLoading = true;
  @Input() message = 'Placeholder';
  @Input() icon = 'info';
  isReloading = false;

  @Output() reload = new EventEmitter();
  noInternet = false;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      if (this.isLoading) {
        this.message = 'general.no-internet';
        this.noInternet = true;
        this.isLoading = false;
      }
    }, 30000);
  }

  reloadPage() {
    this.isReloading = true;
    this.reload.emit();
  }

  hideReload() {
    setTimeout(() => {
      this.isReloading = false;
    }, 1000);
  }
}
