import { panelClass, storageKeys } from '@app-core/services/helpers/config-data';
import { NewUpdateTeacherSettingsComponent } from './../../../features/teacher/teacher-settings/new-update-teacher-settings/new-update-teacher-settings.component';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { MenuService } from '@app-core/services/menu/menu.service';
import { AuthService } from '@app-core/services/auth.service';
import { AppService } from '@app-core/services/app.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingsService } from '../../settings/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { getStorageObjectItem, isDefined } from '@app-core/services/helpers/helpers';
import { isDemo } from '@app-core/services/helpers/helpers';
import { MatDialog } from '@angular/material';
import { ContactToSwitchDialogComponent } from 'src/app/features/admin/dialogs/contact-to-switch/contact-to-switch-dialog/contact-to-switch-dialog.component';
import _ from 'lodash';
import { AuthorizationService } from '@app-core/services/authorization/authorization.service';
import { SchoolEntity } from '../../../features/admin/models/school.model';
import { IServService } from '../../../shared/services/iserv/iserv.service';

@Component({
  selector: 'app-qa-filter',
  templateUrl: './qa-filter.component.html',
  styleUrls: ['./qa-filter.component.scss']
})
export class QaFilterComponent implements OnInit, OnDestroy {
  @Output() onClickMenu = new EventEmitter();
  @Output() onFilterLoaded = new EventEmitter();

  title: string;
  backPath: string;

  activeMenuPath: string;
  filtersGrades = [];
  selectedGrade: any;
  selectedSubject: any;
  language = 'de';
  isComputer: boolean;
  languageChangeSubscription: Subscription;
  iServConnectSubscription: Subscription;

  readonly isDemo = isDemo();
  readonly school: SchoolEntity = getStorageObjectItem(storageKeys.school);

  user: User = getStorageObjectItem(storageKeys.user);

  homeSteps = [
    {
      element: '#scobeesWelcome',
      intro: this.translate.instant('demo.scobees.welcome'),
      position: 'bottom'
    },
    {
      element: '#chooseGradeAndSubject',
      intro: this.translate.instant('demo.chooseGradeAndSubject'),
      position: 'bottom'
    },
    {
      element: '#kidsTab',
      intro: this.translate.instant('demo.kidsTab'),
      position: 'right'
    },
    {
      element: '#learningContentTab',
      intro: this.translate.instant('demo.learningContentTab'),
      position: 'right'
    },
    {
      element: '#competenceRasterTab',
      intro: this.translate.instant('demo.competenceRasterTab'),
      position: 'right'
    },
    {
      element: '#analyzeTab',
      intro: this.translate.instant('demo.analyzeTab'),
      position: 'right'
    },
    {
      element: '#mySchoolDayTab',
      intro: this.translate.instant('demo.mySchoolDayTab'),
      position: 'right'
    },
    {
      element: '.settings-menu',
      intro: this.translate.instant('demo.settings.menu'),
      position: 'right'
    },
    {
      element: '#help-menu',
      intro: this.translate.instant('demo.help.menu'),
      position: 'right'
    }
  ];
  manageKidsSteps = [
    {
      element: '#chooseGradeAndSubject',
      intro: this.translate.instant('demo.manageKid.selectGradeAndSubject'),
      position: 'bottom'
    },
    {
      element: '.manageKidButton',
      intro: this.translate.instant('demo.manageKidButton'),
      position: 'bottom'
    },
    {
      element: '#selectAllKids',
      intro: this.translate.instant('demo.selectAllKids'),
      position: 'top'
    },
    {
      element: '#kidFirstName',
      intro: this.translate.instant('demo.kidFirstName'),
      position: 'right'
    }
  ];
  topicSteps = [
    {
      element: '.newTopicButton',
      intro: this.translate.instant('demo.newTopicButton'),
      position: 'bottom'
    },
    {
      element: '.topic-card',
      intro: this.translate.instant('demo.openTopic'),
      position: 'bottom'
    }
  ];
  competenceRaterSteps = [
    {
      element: '.createCompetenceRasterButton',
      intro: this.translate.instant('demo.competence.add'),
      position: 'bottom'
    },
    {
      element: '#competenceRasterEditButton',
      intro: this.translate.instant('demo.competence.edit'),
      position: 'right'
    }
  ];
  steps = [];

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private iServService: IServService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private settingsService: SettingsService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.putStepsOnDemo();
      }
    });

    this.putStepsOnDemo();

    const storedLanguage = localStorage.getItem('language');

    if (storedLanguage !== null) {
      this.language = storedLanguage;
    } else {
      this.language = 'de';
    }
    this.isComputer = this.deviceService.isDesktop();

    this.languageChangeSubscription = this.settingsService.languageChange.subscribe(() => {
      this.steps = [];
      setTimeout(() => {
        this.stepsValues();
        this.putStepsOnDemo();
      }, 1000);
    });
    this.iServConnectSubscription = this.authorizationService.onIServConnect.subscribe(() => {
      this.user = getStorageObjectItem(storageKeys.user);
    });

    this.getAllFilters();
  }
  ngOnDestroy() {
    this.languageChangeSubscription.unsubscribe();
    this.iServConnectSubscription.unsubscribe();
  }

  stepsValues() {
    this.homeSteps = [
      {
        element: '#scobeesWelcome',
        intro: this.translate.instant('demo.scobees.welcome'),
        position: 'bottom'
      },
      {
        element: '#chooseGradeAndSubject',
        intro: this.translate.instant('demo.chooseGradeAndSubject'),
        position: 'bottom'
      },
      {
        element: '#kidsTab',
        intro: this.translate.instant('demo.kidsTab'),
        position: 'right'
      },
      {
        element: '#learningContentTab',
        intro: this.translate.instant('demo.learningContentTab'),
        position: 'right'
      },
      {
        element: '#competenceRasterTab',
        intro: this.translate.instant('demo.competenceRasterTab'),
        position: 'right'
      },
      {
        element: '#analyzeTab',
        intro: this.translate.instant('demo.analyzeTab'),
        position: 'right'
      },
      {
        element: '#mySchoolDayTab',
        intro: this.translate.instant('demo.mySchoolDayTab'),
        position: 'right'
      },
      {
        element: '.settings-menu',
        intro: this.translate.instant('demo.settings.menu'),
        position: 'right'
      },
      {
        element: '#help-menu',
        intro: this.translate.instant('demo.help.menu'),
        position: 'right'
      }
    ];

    this.manageKidsSteps = [
      {
        element: '#chooseGradeAndSubject',
        intro: this.translate.instant('demo.manageKid.selectGradeAndSubject'),
        position: 'bottom'
      },
      {
        element: '.manageKidButton',
        intro: this.translate.instant('demo.manageKidButton'),
        position: 'bottom'
      },
      {
        element: '#selectAllKids',
        intro: this.translate.instant('demo.selectAllKids'),
        position: 'top'
      },
      {
        element: '#kidFirstName',
        intro: this.translate.instant('demo.kidFirstName'),
        position: 'right'
      }
    ];

    this.topicSteps = [
      {
        element: '.newTopicButton',
        intro: this.translate.instant('demo.newTopicButton'),
        position: 'bottom'
      },
      {
        element: '.topic-card',
        intro: this.translate.instant('demo.openTopic'),
        position: 'bottom'
      }
    ];

    this.competenceRaterSteps = [
      {
        element: '.createCompetenceRasterButton',
        intro: this.translate.instant('demo.competence.add'),
        position: 'bottom'
      },
      {
        element: '#competenceRasterEditButton',
        intro: this.translate.instant('demo.competence.edit'),
        position: 'right'
      }
    ];
  }

  putStepsOnDemo() {
    if (this.router.url === '/teacher/quickaccess/home') {
      this.steps = this.homeSteps;
    } else if (this.router.url === '/teacher/quickaccess/kids') {
      this.steps = this.manageKidsSteps;
    } else if (this.router.url === '/teacher/quickaccess/learning-content') {
      this.steps = this.topicSteps;
    } else if (this.router.url === '/teacher/quickaccess/competence-raster') {
      this.steps = this.competenceRaterSteps;
    } else {
      this.steps = [];
    }
  }

  getAllFilters() {
    this.appService.getAll('/filters').subscribe(
      response => {
        this.filtersGrades = response.data;
        this.handleGradeSubjectShared();
        this.onFilterLoaded.emit();
      },
      () => {
        this.onFilterLoaded.emit();
      }
    );
  }

  handleGradeSubjectShared() {
    if (isDefined(this.sharedService.gradeSelected)) {
      const selectedGradeData = _.find(this.filtersGrades, ['grade_id', this.sharedService.gradeSelected.grade_id]);

      if (isDefined(selectedGradeData)) {
        if (isDefined(this.sharedService.gradeSelected.subject_id)) {
          this.setSelectedSubjectBySubjectId(selectedGradeData);
        } else {
          this.setSelectedSubjectByWorkId(selectedGradeData);
        }
        this.setSelectedGrade(selectedGradeData, this.selectedSubject);
        this.sharedService.saveGradeSelected(this.selectedGrade);
      } else {
        this.sharedService.gradeSelected = null;
      }
    }
  }
  setSelectedSubjectBySubjectId(selectedGradeData) {
    this.selectedSubject = _.find(selectedGradeData.subjects, ['id', this.sharedService.gradeSelected.subject_id]);
  }
  setSelectedSubjectByWorkId(selectedGradeData) {
    this.selectedSubject = _.find(selectedGradeData.subjects, ['work_id', this.sharedService.gradeSelected.work_id]);
    if (!this.selectedSubject) {
      this.selectedSubject = selectedGradeData.subjects[0];
    }
  }
  setSelectedGrade(grade, subject) {
    this.selectedGrade = {
      grade_id: grade.grade_id,
      subject_id: subject.id,
      subject_name: subject.name,
      work_id: subject.work_id,
      work_name: subject.work_name
    };
  }

  onLogout() {
    this.authService.logOut().subscribe(() => {});
  }

  onChange(event: any) {
    if (!isDefined(event.value)) {
      this.selectedGrade = null;
      this.selectedSubject = null;
      this.sharedService.removeGradeSelected();
    }
  }

  onChangeLanguage(language) {
    this.language = language;
    this.settingsService.setLanguage(language);
    this.putStepsOnDemo();
  }

  onOptionClicked(grade, subject) {
    this.selectedSubject = subject;
    this.setSelectedGrade(grade, this.selectedSubject);
    this.sharedService.shareGradeSelected(this.selectedGrade);
  }

  onSendEmailClick() {
    this.dialog
      .open(ContactToSwitchDialogComponent, {
        width: '400px',
        data: {}
      })
      .afterClosed()
      .subscribe();
  }

  onNewUpdateTeacherSettings() {
    this.appService.getAll('/school/subjects').subscribe(response => {
      this.dialog
        .open(NewUpdateTeacherSettingsComponent, {
          width: '75vw',
          panelClass: panelClass.roundDialog,
          data: {
            works: response.data,
            isUpdate: false
          },
          disableClose: true
        })
        .afterClosed()
        .subscribe(areSettingsChanged => {
          if (areSettingsChanged) {
            this.getAllFilters();
          }
        });
    });
  }

  onIServClick(): void {
    this.iServService.handleIServRedirect();
  }

  get isIServEnable(): boolean {
    return this.iServService.isIServEnable;
  }
}
