import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '@app-core/services/app.service';

@Component({
  selector: 'app-kid-tests-dialog',
  templateUrl: './kid-tests-dialog.component.html',
  styleUrls: ['./kid-tests-dialog.component.scss']
})
export class KidTestsDialogComponent implements OnInit {
  testResults = [] as any;

  constructor(private dialogRef: MatDialogRef<KidTestsDialogComponent>, private appService: AppService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.getResults();
  }

  getResults() {
    const data = { topicIds: this.data.topicId, ...this.data.dataRange };
    if (this.data.isYearly) {
      const url = this.data.belongsToParent ? `/exams-tests-many?kidId=${this.data.kidId}` : '/exams-tests-many';
      this.appService.newElement(url, data).subscribe(response => {
        if (response) {
          this.testResults = response.data.tests;
        }
      });
    } else {
      const url = this.data.kidId && this.data.belongsToParent ? `/exams-tests?kidId=${this.data.kidId}` : '/exams-tests';
      this.appService.getElement(url, { topicId: this.data.topicId, ...this.data.dataRange }).subscribe(response => {
        if (response) {
          this.testResults = response.data.tests;
        }
      });
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
