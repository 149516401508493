import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuService } from '../../services/menu/menu.service';
import { AuthService } from '../../services/auth.service';
import { SettingsService } from '../../settings/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material';
import { AuthGuardService } from '../../services/authguard/auth-guard.service';
import { routeAnimations } from '@app-core/animations/route.animations';
import { SharedService } from 'src/app/shared/services/shared.service';
import { defaultImage } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [routeAnimations]
})
export class AppLayoutComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  user: any = {};
  menu = [];
  options: FormGroup;
  language = 'en';
  hideScrollbar = false;

  defaultImage = defaultImage;

  constructor(
    private sharedService: SharedService,
    private authGuardService: AuthGuardService,
    private settingsService: SettingsService,
    public menuService: MenuService,
    private authService: AuthService,
    fb: FormBuilder
  ) {
    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0
    });
  }

  ngOnInit() {
    this.menu = this.menuService.getMenu();
    this.sharedService.toggledFullscreen.subscribe(() => {
      this.hideScrollbar = !this.hideScrollbar;
    });

    if (localStorage.getItem('language')) this.language = localStorage.getItem('language');

    this.menuService.setSidenav(this.sidenav);
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logout() {
    this.authService.logOut().subscribe(() => {});
  }

  onChangeLanguage(language) {
    this.language = language;
    this.settingsService.setLanguage(language);
  }
}
