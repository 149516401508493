import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { MatButtonModule, MatMenuModule, MatIconModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [EmojiPickerComponent],
  exports: [EmojiPickerComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, FlexLayoutModule, MatIconModule, TranslateModule, PickerModule]
})
export class EmojiPickerModule {}
