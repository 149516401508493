import { LOGO } from '@shared/images/logo';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ARROW = 'assets/icons/left-arrow.png';

export class QrCodeToPdf {
  static async downloadPdf(element, { title, body, text, kidName, fileName }) {
    const document = {
      content: [
        {
          image: LOGO,
          width: 120,
          marginLeft: 350,
          marginTop: 25
        },
        {
          text: `${title}`,
          style: 'header'
        },
        {
          text: `${kidName}`,
          style: 'bold',
          marginTop: 15
        },
        {
          text: `${text}`,
          style: 'small',
          marginTop: 100
        },

        {
          image: `${element.src}`,
          width: 150,
          marginTop: 10
        },
        {
          image: await getBase64ImageFromURL(ARROW),
          width: 60,
          marginLeft: 175,
          marginTop: -95
        },

        {
          text: `${body}`,
          style: 'subheader'
        }
      ],
      styles: {
        header: {
          fontSize: 22,
          color: '#00838f',
          marginTop: 10
        },
        subheader: {
          fontSize: 15,
          bold: true,
          marginLeft: 200,
          color: '#00838f'
        },
        bold: {
          bold: true,
          fontSize: 22,
          color: '#00838f'
        },
        small: {
          fontSize: 10,
          color: '#00838f'
        }
      }
    };
    pdfMake.createPdf(document).download(`${fileName}`);
  }
}

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const contex = canvas.getContext('2d');
      contex.drawImage(image, 0, 0);

      const dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    image.onerror = error => {
      reject(error);
    };

    image.src = url;
  });
}
