import { SharedModule } from './../../../../shared/shared.module';
import { ContactToSwitchDialogComponent } from './contact-to-switch-dialog/contact-to-switch-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ContactToSwitchDialogComponent],
  entryComponents: [ContactToSwitchDialogComponent],
  imports: [CommonModule, SharedModule]
})
export class ContactToSwitchModule {}
