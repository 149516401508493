import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { AuthService } from '@app-core/services/auth.service';
import { CustomValidators } from '@app-core/validators/custom-validators';
import { styleClass } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  visible = false;
  confirmPasswordVisible = false;
  registerForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        surname: [null, Validators.required],
        email: [null, Validators.compose([Validators.required, Validators.email])],
        password: [
          null,
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // 5. check whether the entered password has a special character
            // CustomValidators.patternValidator(/[!@#$%^&*(),.?":{}|<>]/, {
            //   hasSpecialCharacters: true
            // }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  onRegistrationSubmit() {
    for (const i of Object.keys(this.registerForm.controls)) {
      this.registerForm.controls[i].markAsTouched();
    }

    let toast = {} as Toast;
    if (this.registerForm.valid) {
      const loginData = {
        NAME: this.registerForm.value.name,
        SURNAME: this.registerForm.value.surname,
        EMAIL: this.registerForm.value.email,
        PASSWORD: this.registerForm.value.password
      };
      this.authService.register(loginData).subscribe((res: any) => {
        if (res.success) {
          toast = {
            message: this.translate.instant(`register.done`),
            styleClass: styleClass.success
          } as Toast;
          this.router.navigate(['../login']);
        } else {
          toast = {
            message: this.translate.instant(`error.${res.error.code}`),
            styleClass: styleClass.error
          } as Toast;
        }
        this.snackBarService.addCustom(toast);
      });
    } else {
      toast = {
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast;
      this.snackBarService.addCustom(toast);
    }
  }
}
