import { NativeDateAdapter } from '@angular/material';
import { formatDate } from '@app-core/services/helpers/helpers';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object) {
    return formatDate(date, 'DD/MM/YYYY');
  }
  getFirstDayOfWeek(): number {
    return 1;
  }
}

export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === 'string') {
      if (value.indexOf('/') > -1) {
        return this.dateFormatSeparator(value, '/');
      } else if (value.indexOf('.') > -1) {
        return this.dateFormatSeparator(value, '.');
      } else if (value.indexOf('-') > -1) {
        return this.dateFormatSeparator(value, '-');
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  dateFormatSeparator(value: string, separator: string): Date {
    const str = value.split(separator);
    const year = Number(str[2]);
    const month = Number(str[1]) - 1;
    const day = Number(str[0]);
    return new Date(year, month, day);
  }
}
