import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Colleague } from '../../manage-schools/new-entity/colleagues/colleagues/colleagues.component';

@Component({
  selector: 'app-add-colleague-dialog',
  templateUrl: './add-colleague-dialog.component.html',
  styleUrls: ['./add-colleague-dialog.component.scss']
})
export class AddColleagueDialogComponent {
  profileForm: FormGroup = this.formBuilder.group({
    first_name: [null, Validators.required],
    last_name: [null, Validators.required],
    email: [null, Validators.compose([Validators.required, Validators.email])]
  });
  title: '';
  message: '';
  confirmButtonText = '';
  cancelButtonText = '';

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: Colleague, private dialogRef: MatDialogRef<AddColleagueDialogComponent>) {
    if (data) {
      this.profileForm.patchValue(data);
    }
  }

  onSubmit() {
    if (this.profileForm.valid) {
      this.dialogRef.close({
        id: this.data ? this.data.id : null,
        first_name: this.profileForm.value.first_name,
        last_name: this.profileForm.value.last_name,
        email: this.profileForm.value.email,
        is_lead: this.data ? this.data.is_lead : false
        // verified: false
      } as Colleague);
    } else {
      for (const c in this.profileForm.controls) {
        if (this.profileForm.controls[c]) {
          this.profileForm.controls[c].markAsTouched();
        }
      }
    }
  }
}
