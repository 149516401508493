import { Injectable } from '@angular/core';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { styleClass } from '../helpers/config-data';
export class SnackBarMessage {
  message: string;
  action: string = null;
  styleClass = 'warning';
  duration: 5000;
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  getConfig() {
    return {
      duration: '2000',
      panelClass: [this.styleClass],
      verticalPosition: 'bottom',
      horizontalPosition: 'center'
    };
  }
}
export interface Toast {
  message: string;
  description: string;
  styleClass: string;
  action: string;
  metaData: any;
}
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private toastsQueue = [];
  private isInstanceVisible = false;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  showSuccessMessage(message: string) {
    this.addCustom({
      message: this.translate.instant(message),
      styleClass: styleClass.success
    } as Toast);
  }
  showErrorMessage(message: string) {
    this.addCustom({
      message: this.translate.instant(message),
      styleClass: styleClass.error
    } as Toast);
  }

  addCustom(toast: Toast) {
    const sbMessage = new SnackBarMessage();
    this.translate.get(toast.message).subscribe((res: string) => {
      sbMessage.message = res;
    });

    if (toast.action)
      this.translate.get(toast.action).subscribe((res: string) => {
        sbMessage.action = res;
      });
    sbMessage.styleClass = toast.styleClass;

    this.toastsQueue.push(sbMessage);
    if (!this.isInstanceVisible) {
      this.showNext(toast);
    }
  }
  showNext(toast) {
    if (this.toastsQueue.length === 0) {
      return;
    }
    const thisToast = this.toastsQueue.shift();

    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.open(thisToast.message, thisToast.action, thisToast.getConfig());
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isInstanceVisible = false;
      if (this.toastsQueue.length > 0) this.showNext(toast);
    });
    this.snackBarRef.onAction().subscribe(() => {
      // this.openModal(toast)
      this.showNext(toast);
    });
  }
}
