import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app-core/services/auth.service';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  logout() {
    this.authService.logOut().subscribe(() => {});
  }
}
