import { Injectable } from '@angular/core';
import { groupTypes, apiUrl } from '../../analyze.config';
import { fillStringData, isDefined } from '@app-core/services/helpers/helpers';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

export interface BookmarkApiUrl {
  type: string;
  id: number | string;
  gradeClassId?: number;
  learningGroup: string;
  isDelete?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyzeService {
  constructor(private sharedService: SharedService, private translate: TranslateService) {}

  getBookmarkParameters(type: string, givenParameters?: any) {
    let parameters = {};

    switch (type) {
      case groupTypes.LEARNING_GROUPS:
        parameters = {
          grade: this.sharedService.analyzeGrade
        };
        break;

      case groupTypes.TOPICS:
        parameters = {
          topic_ids: [givenParameters.topic_id]
        };
        break;

      default:
        break;
    }
    return parameters;
  }

  getBookmarkApiUrl(data: BookmarkApiUrl) {
    let url: string;

    switch (data.type) {
      case groupTypes.GRADE_CLASSES:
        url = fillStringData(apiUrl[data.type], {
          gradeClassId: data.id
        });
        break;

      case groupTypes.TEACHER_TAGS:
        url = fillStringData(apiUrl[data.type], {
          tagId: data.id
        });
        break;

      case groupTypes.LEARNING_GROUPS:
        url = fillStringData(apiUrl[data.type], {
          gradeClassId: data.gradeClassId,
          learningGroup: data.learningGroup
        });

        url += data.isDelete ? `?grade=${this.sharedService.analyzeGrade}` : ``;

        break;

      case groupTypes.TOPICS:
        url = fillStringData(apiUrl[data.type], {
          topicId: data.id
        });
        break;

      default:
        break;
    }
    return url;
  }

  getGroupCombinationsTitle(group) {
    return `${group.name}${isDefined(group.learning_group_name) ? `, ${group.learning_group_name}` : ``}`;
  }

  saveGroup(group) {
    localStorage.setItem('group', JSON.stringify(group));
  }

  removeGroup() {
    localStorage.removeItem('group');
  }

  getGroupSelected() {
    return JSON.parse(localStorage.getItem('group'));
  }

  mergeGroupKidsParameters(parameters) {
    const group = JSON.parse(localStorage.getItem('group'));

    if (isDefined(group) && isDefined(group.type)) {
      switch (group.type.toUpperCase()) {
        case groupTypes.LEARNING_GROUPS:
          parameters.gradeClass = group.grade_class_id;
          parameters.learningGroups = group.learning_group;
          delete parameters.tags;
          break;

        case groupTypes.GRADE_CLASSES:
          parameters.gradeClass = group.id;
          delete parameters.tags;
          delete parameters.learningGroups;
          break;

        case groupTypes.TEACHER_TAGS:
          parameters.tags = group.name;
          delete parameters.gradeClass;
          delete parameters.learningGroups;
          break;

        default:
          break;
      }
    } else {
      delete parameters.tags;
      delete parameters.gradeClass;
      delete parameters.learningGroups;
    }

    return parameters;
  }
}
