import { Injectable } from '@angular/core';

export interface PasswordGeneratorOptions {
  lowercase: boolean;
  uppercase: boolean;
  numbers: boolean;
  symbols: boolean;
  length: number;
}
@Injectable({
  providedIn: 'root'
})
export class PasswordGeneratorService {
  dictionary: Array<String>;

  // lowercase = true;
  // uppercase = true;
  // numbers = true;
  // symbols = true;

  lowercaseLibrary = 'abcdefghijklmnopqrstuvwxyz';
  uppercaseLibrary = 'ABCDEFGHIJKLMNOPWRSTUVWXYZ';
  numbersLibrary = '0123456789';
  symbolsLibrary = '!@#$%^&*-_=+\\|:;",.<>/?~';

  newPassword: string;

  constructor() {}

  // Generate password
  public generate(options: PasswordGeneratorOptions): string {
    if (options.lowercase === false && options.uppercase === false && options.numbers === false && options.symbols === false) {
      return (this.newPassword = '...');
    }

    // Create array from chosen checkboxes
    this.dictionary = [].concat(
      options.lowercase ? this.lowercaseLibrary.split('') : [],
      options.uppercase ? this.uppercaseLibrary.split('') : [],
      options.numbers ? this.numbersLibrary.split('') : [],
      options.symbols ? this.symbolsLibrary.split('') : []
    );

    // Generate random password from array
    let newPassword = '';
    for (let i = 0; i < options.length; i++) {
      newPassword += this.dictionary[Math.floor(Math.random() * this.dictionary.length)];
    }
    return newPassword;
  }
}
