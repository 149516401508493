import { iconType } from '@app-core/services/helpers/config-data';
import { getSchoolIcon } from '@app-core/services/helpers/helpers';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analyze-progress-bar',
  templateUrl: './analyze-progress-bar.component.html',
  styleUrls: ['./analyze-progress-bar.component.scss']
})
export class AnalyzeProgressBarComponent implements OnInit {
  @Input() tasks: number;
  @Input() extra: number;
  @Input() stars: number;
  @Input() color: string;
  @Input() percentage: number;
  @Input() lastTopic = {
    topic_id: 0,
    topic_name: '',
    learning_groups: [],
    learning_level: '',
    expert_at: '',
    progress: 0
  };
  description: string;
  @Input() showBar = true;
  @Input() badge: any;

  topic = localStorage.getItem('favorite');

  owlIcon = getSchoolIcon(iconType.owl);

  constructor() {}

  ngOnInit() {
    this.percentage = this.percentage * 100;
    // this.description = this.getDescription();
  }

  // getDescription() {

  //   let description = ""
  //   if (isDefined(this.lastTopic)) {
  //     if (this.lastTopic.learning_groups.length > 0) {
  //       description = this.lastTopic.learning_groups.join(',')
  //     }
  //     if(isDefined(this.lastTopic.learning_level)){
  //       description += `,${this.lastTopic.learning_level}`;
  //     }
  //   }
  //   return description;
  // }
}
