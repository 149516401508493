import { PlyrModule } from 'ngx-plyr';
import { AudioRecorderService } from './services/audio-recorder.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatDialogModule,
  MatListModule,
  MatProgressBarModule,
  MatIconModule,
  MatStepperModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from './services/upload.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FileUploaderComponent } from './file-uploader.component';
import { TranslateModule } from '@ngx-translate/core';
import { TokenInterceptor } from '@app-core/services/token-interceptor/token-interceptor.service';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CDocumentViewerComponent } from './file-viewer/c-document-viewer/c-document-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatStepperModule,
    FlexLayoutModule,
    HttpClientModule,
    MatProgressBarModule,
    TranslateModule,
    MatIconModule,

    PlyrModule,
    NgxDocViewerModule,
    PdfJsViewerModule,
    MatProgressSpinnerModule
  ],
  declarations: [FileUploaderComponent, AudioRecorderComponent, FileViewerComponent, CDocumentViewerComponent],
  exports: [AudioRecorderComponent, FileViewerComponent],
  entryComponents: [FileUploaderComponent, FileViewerComponent],
  providers: [
    UploadService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class FileUploaderModule {}
