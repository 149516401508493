import { AuthorizationService } from './../../../core/services/authorization/authorization.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app-core/services/auth.service';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { AppService } from '@app-core/services/app.service';
import { CustomValidators } from '@app-core/validators/custom-validators';
import { styleClass } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  token: any;
  visible = false;
  confirmPasswordVisible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.changePasswordForm = this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // 5. check whether the entered password has a special character
            // CustomValidators.patternValidator(/[!@#$%^&*(),.?":{}|<>]/, {
            //   hasSpecialCharacters: true
            // }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, [Validators.required]]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }
  resetPassword() {
    let toast;
    if (this.changePasswordForm.valid) {
      const data = {
        password: this.changePasswordForm.get('password').value,
        token: this.token
      };
      this.appService.newElement('/profile/change-password', data).subscribe(() => {
        toast = {
          message: this.translate.instant(`change-password.reseted`),
          styleClass: styleClass.success
        } as Toast;
        this.snackBarService.addCustom(toast);
        this.handleUserAuthorization();
      });
    } else {
      toast = {
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast;
      this.snackBarService.addCustom(toast);
    }
  }

  handleUserAuthorization() {
    if (this.authorizationService.isAuthenticated()) {
      this.authService.logOut().subscribe(() => {
        this.navigateToLogin();
      });
    } else {
      this.navigateToLogin();
    }
  }
  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
