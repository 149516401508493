import { Subscription } from 'rxjs';
import { SharedService } from './../../../../../../shared/services/shared.service';
import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app-core/animations/route.animations';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { AppService } from '@app-core/services/app.service';
import { Toast, SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { exportExcel } from '@app-core/services/helpers/helpers';
import { translateEnum, styleClass } from '@app-core/services/helpers/config-data';
import { AddColleagueDialogComponent } from 'src/app/features/admin/dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { DEFAULT_PAGEINDEX, DEFAULT_PAGINATION_SIZE } from 'src/app/features/admin/models/admin.config';
import { ILeadEmailData } from '@shared/Interfaces/ParentInterface';
import { ParentsExportService } from '@shared/services/parents-export/parents-export.service';

export interface Colleague {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  verified: boolean;
  is_lead: boolean;
}

@Component({
  selector: 'app-colleagues',
  templateUrl: './colleagues.component.html',
  styleUrls: ['./colleagues.component.scss']
})
export class ColleaguesComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;

  @Input() idSchool: string;
  @Input() is_demo: number;
  @Input() belongsToLead = false;
  isFocusedSearch = false;
  element: any;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<Colleague>([]);
  selection = new SelectionModel<Colleague>(true, []);
  name: string;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  myControl = new FormControl();
  getAllColleaguesParams: any = {};
  isCallingApi = false;
  colleaguesToAdd = [];
  colleaguesToShow = [];
  total_requests_for_account: number;
  leadEmailForm: FormGroup;
  translateEnum = translateEnum;
  disable = false;

  refreshColleagueSubscription: Subscription;

  constructor(
    private snackBarService: SnackBarService,
    private appService: AppService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: ActivatedRoute,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private parentsExportService: ParentsExportService
  ) {
    this.router.parent.params.subscribe(params => {});
  }
  ngOnInit() {
    this.leadEmailForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    });
    this.refreshColleagueSubscription = this.sharedService.refreshColleague.subscribe(() => {
      this.setDefaultPaginator();
      this.handleGetAll();
    });

    this.dataSource = new MatTableDataSource<Colleague>([]);
    this.dataSource.sort = this.sort;
    this.manageDemoAccount();
    this.setDefaultPaginator();
    this.getLeadEmail();

    if (this.idSchool) {
      this.handleGetAll();
    }
    this.onDisableParentExport();
  }

  ngOnDestroy() {
    this.refreshColleagueSubscription.unsubscribe();
  }

  setDefaultPaginator() {
    this.paginator.pageIndex = DEFAULT_PAGEINDEX;
    this.paginator.pageSize = DEFAULT_PAGINATION_SIZE;
    this.dataSource.paginator = this.paginator;
  }

  manageDemoAccount() {
    if (this.is_demo) {
      this.displayedColumns = ['first_name_demo_account', 'activity', 'is_lead', 'ts_modified', 'has_requested_active_account', 'verified', 'action'];
      this.getDemoCount();
    } else {
      this.displayedColumns = ['first_name', 'is_lead', 'verified', 'ts_verified', 'action'];
    }
  }

  getDemoCount() {
    this.appService.getElement(`/schools/${this.idSchool}/colleagues/demo/count`).subscribe((res: any) => {
      this.total_requests_for_account = res.data.total_requests_for_account;
    });
  }

  getLeadEmail() {
    this.appService.getElement(`/schools/lead-email/${this.idSchool}`).subscribe((response: ILeadEmailData) => {
      if (response.data) {
        this.leadEmailForm.controls['email'].setValue(response.data.email);
      }
    });
  }

  onAddLeadEmail() {
    if (this.leadEmailForm.valid) {
      const payload = {
        email: this.leadEmailForm.value.email
      };

      this.appService.newElement(`/schools/lead-email/${this.idSchool}`, payload).subscribe(() => {
        const toast: Toast = {
          message: this.translate.instant(`parent.email_added`),
          styleClass: styleClass.success
        } as Toast;

        this.snackBarService.addCustom(toast);
      });
    }
  }
  /**
   * This is a function that extract the string from event of search
   * and makes a request to getAllSchools
   * @param event
   */
  onSearch(event) {
    if (event.search) {
      this.getAllColleaguesParams.search = event.search;
    } else {
      delete this.getAllColleaguesParams.search;
    }
    this.getAllColleagues();
  }

  openDialog(colleague: Colleague, index?: number) {
    this.dialog
      .open(AddColleagueDialogComponent, {
        data: colleague
      })
      .afterClosed()
      .subscribe((data: Colleague) => {
        if (data) {
          if (!colleague) {
            this.insertColleague(data);
          } else {
            this.updateColleague(data);
          }
        }
      });
  }
  /**
   * This is a function that opens an entryComponent for
   * confirmin the deletion
   * @param colleague
   */
  resendEmail(colleague: Colleague) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: this.translate.instant(`general.resendemail`),
          message: this.translate.instant(`general.resendemailmessage`),
          buttonText: {
            ok: this.translate.instant(`general.resend`),
            cancel: this.translate.instant(`general.cancel`)
          },
          okBtnColor: 'primary'
        }
      })
      .afterClosed()
      .subscribe((action: any) => {
        if (action === 'confirm') {
          this.appService.newElement('/users/invite', { email: colleague.email }).subscribe(response => {
            const toast = {
              message: this.translate.instant(`general.emailresended`),
              styleClass: styleClass.success
            } as Toast;
            this.snackBarService.addCustom(toast);
          });
        }
      });
  }

  /**
   * This is a function that opens an entryComponent for
   * confirmin the deletion
   * @param colleague
   */
  deleteColleague(colleague: Colleague) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: this.translate.instant(`general.delete`),
          message: this.translate.instant(`general.deletemessage`),
          buttonText: {
            ok: this.translate.instant(`general.archive`),
            sure: this.translate.instant(`general.delete`),
            cancel: this.translate.instant(`general.cancel`)
          },
          okBtnColor: 'primary',
          okSureBtnColor: 'warn'
        }
      })
      .afterClosed()
      .subscribe((action: any) => {
        let purge = '';
        if (['delete', 'confirm'].indexOf(action) !== -1) {
          if (action === 'delete') {
            purge += 'purge/';
          }
          this.appService.deleteElement(`/schools/${this.idSchool}/colleagues/${purge}${colleague.id}`, { id: colleague.id }).subscribe(() => {
            const toast = {
              message: this.translate.instant(`admin.colleagues.deleted`),
              styleClass: styleClass.success
            } as Toast;
            this.getAllColleagues();
            this.snackBarService.addCustom(toast);
          });
        }
      });
  }

  insertColleague(colleague: Colleague) {
    delete colleague.id;
    this.appService.newElement(`/schools/${this.idSchool}/colleagues`, colleague).subscribe(() => {
      const toast = {
        message: this.translate.instant(`general.invitation_sended`),
        styleClass: styleClass.success
      } as Toast;
      this.snackBarService.addCustom(toast);
      this.setDefaultPaginator();
      this.handleGetAll();
    });
  }

  updateColleague(colleague: Colleague) {
    const colleagueId = colleague.id;
    delete colleague.id;
    this.appService.patchElement(`/schools/${this.idSchool}/colleagues/${colleagueId}`, colleague).subscribe(() => {
      const toast = {
        message: this.translate.instant(`general.updated`),
        styleClass: styleClass.success
      } as Toast;
      this.snackBarService.addCustom(toast);
      this.handleGetAll();
    });
  }

  markAsLead(event: any, colleagueItem: any) {
    const colleague = {
      id: colleagueItem.id,
      email: colleagueItem.email,
      first_name: colleagueItem.first_name,
      last_name: colleagueItem.last_name,
      is_lead: event.checked
    } as Colleague;

    this.updateColleague(colleague);
  }

  /**
   * This is an event that handles the sort on headers of mat tables
   * @param event
   */
  sortTable(event) {
    if (event.direction === '') {
      this.sort.direction = null;
      this.sort.active = null;
    }
    this.handleGetAll();
  }

  /**
   * This is a central function that handles query params
   * about pagination and sorting for the api call
   */
  handleGetAll() {
    this.getAllColleaguesParams.limit = this.paginator.pageSize;
    this.getAllColleaguesParams.page = this.paginator.pageIndex + 1;
    if (this.sort.active) {
      this.getAllColleaguesParams.orderBy = this.sort.active;
    }
    if (this.sort.direction) {
      this.getAllColleaguesParams.orderDir = this.sort.direction.toUpperCase();
    }
    this.getAllColleagues();
  }

  /**
   * This funcrion is about getting all Colleagues from db that are inserted
   */

  getAllColleagues() {
    this.isCallingApi = true;
    this.appService.getElement(`/schools/${this.idSchool}/colleagues`, this.getAllColleaguesParams).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<Colleague>(res.data.page);
      this.paginator.pageIndex = res.data.pagination.current_page - 1;
      this.paginator.pageSize = res.data.pagination.limit;
      this.paginator.length = res.data.pagination.total_count;
      this.isCallingApi = false;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Colleague): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  onColleguesExport() {
    if (this.is_demo) {
      this.demoColleaguesExport();
    } else {
      this.colleaguesExport();
    }
  }

  demoColleaguesExport() {
    this.appService.getAll(`/schools/${this.idSchool}/colleagues/demo/export`).subscribe(res => {
      const header = {
        username: this.translate.instant('general.username'),
        first_name: this.translate.instant('general.firstname'),
        last_name: this.translate.instant('general.lastname'),
        requested_demo_account: this.translate.instant('admin.manage-schools.requesed_demo_account'),
        has_requested_active_account: this.translate.instant('admin.manage-schools.requested_active_account'),
        verified: this.translate.instant('general.status'),
        organisation: this.translate.instant('general.organisation'),
        field_of_activity_activity: this.translate.instant('general.activity'),
        city: this.translate.instant('general.city'),
        heard_from: this.translate.instant('general.found_from'),
        Phone: this.translate.instant('general.telefon')
      };

      exportExcel(this.translate.instant('general.colleagues'), res.data, header);
    });
  }

  colleaguesExport() {
    this.appService.getAll(`/schools/${this.idSchool}/teachers/export`).subscribe(res => {
      const header = {
        id: 'ID',
        school_name: this.translate.instant('general.school_name'),
        first_name: this.translate.instant('general.firstname'),
        last_name: this.translate.instant('general.lastname'),
        ts_created: this.translate.instant('general.date_adding'),
        email: this.translate.instant('general.email'),
        verified: this.translate.instant('general.status'),
        ts_verified: this.translate.instant('general.date_verified'),

        activity: this.translate.instant('general.activity'),
        state: this.translate.instant('general.state'),
        city: this.translate.instant('general.city'),
        Phone: this.translate.instant('general.telefon')
      };

      exportExcel(this.translate.instant('general.colleagues'), this.handleStatusLabel(res.data), header);
    });
  }

  onParentExport() {
    this.parentsExportService.onParentsExport(`/schools/${this.idSchool}/parents/export`);
  }

  onDisableParentExport() {
    this.appService.getAll(`/schools/${this.idSchool}/parents/export`).subscribe(res => {
      if (res.data.length > 0) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    });
  }

  handleStatusLabel(data = [], parentExport = false) {
    if (!parentExport) {
      data.map(item => (item.verified = this.translate.instant(item.verified ? 'general.verified' : 'general.not_verified')));
    }
    return data;
  }
}
