import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@app-core/validators/custom-validators';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app-core/services/auth.service';
import { formatDate, setFormFieldOptional } from '@app-core/services/helpers/helpers';
import { markFormAsTouched } from '@app-core/services/helpers/helpers';
import { IParentPayload, IParentResponse } from '@shared/Interfaces/ParentInterface';
import { AppService } from '@app-core/services/app.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { CONFIRM } from '../../admin/models/admin.config';

@Component({
  selector: 'app-register-parent',
  templateUrl: './register-parent.component.html',
  styleUrls: ['./register-parent.component.scss']
})
export class RegisterParentComponent implements OnInit {
  visible = false;
  confirmPasswordVisible = false;
  registerParentForm: FormGroup;
  code: string;
  kidName: string;
  hasKid = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private dialog: MatDialog
  ) {
    this.code = this.route.snapshot.paramMap.get('code');
  }

  ngOnInit() {
    this.appService.getElement('/parents/kids/' + this.code).subscribe((response: any) => {
      this.kidName = response.data.firstName + ' ' + response.data.lastName;
    });
    this.onParentFormGenerate();
  }
  onParentFormGenerate() {
    this.registerParentForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        surname: [null, Validators.required],
        email: [null, Validators.compose([Validators.required, Validators.email])],
        password: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
        childBirthdate: [null, Validators.required],
        termsAndConditions: [false, Validators.requiredTrue],
        permisionForContent: false
      },
      {
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  onRegistrationSubmit() {
    this.submitted = true;
    if (this.hasKid) {
      setFormFieldOptional(this.registerParentForm.get('confirmPassword'));
    }

    const registerData: IParentPayload = {
      name: this.registerParentForm.value.name,
      surname: this.registerParentForm.value.surname,
      email: this.registerParentForm.value.email,
      password: this.registerParentForm.value.password,
      kidBirthDate: formatDate(this.registerParentForm.value.childBirthdate),
      code: this.code,
      termsAndConditions: this.registerParentForm.value.termsAndConditions,
      permisionForContent: this.registerParentForm.value.permisionForContent
    };

    if (this.registerParentForm.valid) {
      this.registerParent(registerData);
    } else {
      this.handleFormValidateMessage();
    }
  }

  handleFormValidateMessage() {
    let toast = {} as Toast;
    markFormAsTouched(this.registerParentForm);
    toast = {
      message: this.translate.instant(`general.complete_fields`),
      styleClass: styleClass.error
    } as Toast;

    this.snackBarService.addCustom(toast);
  }

  openParentConfirmDialog() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: this.translate.instant(`general.note`),
          message: this.translate.instant(`parent.already_registered`),
          buttonText: {
            ok: this.translate.instant(`general.parent_login`),
            cancel: this.translate.instant(`general.close`)
          },
          okBtnColor: 'primary',
          okSureBtnColor: 'warn'
        }
      })
      .afterClosed()
      .subscribe((action: string) => {
        if (action === CONFIRM) {
          this.hasKid = true;
          this.registerParentForm.reset();
          Object.keys(this.registerParentForm.controls).forEach(key => {
            this.registerParentForm.get(key).setErrors(null);
          });
        }
      });
  }

  registerParent(registerData: IParentPayload) {
    if (this.hasKid) {
      delete registerData.permisionForContent;
      delete registerData.termsAndConditions;
    }
    this.authService.registerParent(registerData, this.hasKid).subscribe(
      (response: IParentResponse) => {
        const toast: Toast = {
          message: this.translate.instant(`register.parent`),
          styleClass: styleClass.success
        } as Toast;

        this.authService.doLogoutUser();
        this.router.navigate(['../login']);
        this.snackBarService.addCustom(toast);
      },

      error => {
        if (error.error.error_code === 174) {
          const toast: Toast = {
            message: this.translate.instant(`error.${error.error.error_code}`),
            styleClass: styleClass.error
          } as Toast;
          this.snackBarService.addCustom(toast);
        } else if (error.error.error_code === 119) {
          this.openParentConfirmDialog();
        } else if (error.error.error_code === 175) {
          const toast: Toast = {
            message: this.translate.instant(`error.${error.error.error_code}`),
            styleClass: styleClass.error
          } as Toast;
          this.snackBarService.addCustom(toast);
        }
      }
    );
  }

  onNotMyChildClick() {
    this.router.navigate([`../not-my-child/${this.code}`]);
  }
}
