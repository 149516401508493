import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { domain } from 'process';

import { AuthorizationService } from '../../../core/services/authorization/authorization.service';
import { storageKeys } from '../../../core/services/helpers/config-data';
import { getStorageObjectItem } from '../../../core/services/helpers/helpers';
import { SchoolEntity } from '../../../features/admin/models/school.model';
import { User } from '../../models/user/user.model';

@Injectable()
export class IServService {
  constructor(private authorizationService: AuthorizationService, private router: Router) {}

  readonly school: SchoolEntity = getStorageObjectItem(storageKeys.school);
  user: User = getStorageObjectItem(storageKeys.user);

  handleIServRedirect() {
    this.user = getStorageObjectItem(storageKeys.user);
    if (this.user.iserv_email) {
      window.open(this.school.iServDomain, '_blank');
    } else {
      this.authorizationService.initOpenIdAuthenticate(`${window.location.origin}${this.router.url}`, this.getSanitizedIServDomain(this.school.iServDomain));
    }
  }

  get isIServEnable(): boolean {
    return environment.openIdAuthMode && this.school.has_iserv_integration && Boolean(this.school.iServDomain);
  }

  private getSanitizedIServDomain(iservDomain?: string): string {
    if (!iservDomain) return null;
    const [, domainWithoutProtocol] = iservDomain.startsWith('https://') ? iservDomain.split('https://') : iservDomain.split('http://');
    const [sanitizedUrl] = domainWithoutProtocol.split('/');
    return sanitizedUrl;
  }
}
