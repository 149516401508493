import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-title',
  templateUrl: './icon-title.component.html',
  styleUrls: ['./icon-title.component.scss']
})
export class IconTitleComponent implements OnInit {
  @Input() title = 'No title';
  @Input() icon;
  @Input() size = '24px';
  @Input() img;
  constructor() {}

  ngOnInit() {}
}
