import { iconType } from '@app-core/services/helpers/config-data';
import { getSchoolIcon, isLead } from '@app-core/services/helpers/helpers';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { NavigationModel } from 'src/app/shared/models/navigation/navigation.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private sidenav: MatSidenav;

  navigationModel: NavigationModel;
  constructor() {
    this.navigationModel = new NavigationModel();
  }

  getMenu() {
    const role = localStorage.getItem('role');
    return this.navigationModel.model[role];
  }

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open() {
    return this.sidenav.open();
  }

  close() {
    return this.sidenav.close();
  }

  toggle(): void {
    this.sidenav.toggle();
  }

  getTeacherMenu() {
    const menu = [
      {
        id: 'home',
        title: 'menu.home',
        icon: 'home',
        image: 'assets/icons/Teacher/NavBar/dashboard.svg',
        routerLink: '/teacher/quickaccess/home'
      },
      {
        id: 'kidsTab',
        title: 'menu.manage-kids',
        icon: 'mood',
        image: 'assets/icons/Teacher/NavBar/kids.svg',
        routerLink: '/teacher/quickaccess/kids'
      },
      {
        id: 'learningContentTab',
        title: 'menu.learning-content',
        icon: 'ballot',
        image: getSchoolIcon(iconType.bee),
        routerLink: '/teacher/quickaccess/learning-content'
      },
      {
        id: 'competenceRasterTab',
        title: 'menu.competence-raster',
        icon: 'grid_on',
        image: 'assets/icons/Teacher/NavBar/competence.svg',
        routerLink: '/teacher/quickaccess/competence-raster'
      },
      {
        id: 'analyzeTab',
        title: 'menu.analyze',
        icon: 'multiline_chart',
        image: 'assets/icons/Teacher/NavBar/analyze.svg',
        routerLink: '/teacher/quickaccess/new-analyze/grades'
      }
      //  Todo, hidden
      // {
      //   id: 'mySchoolDayTab',
      //   title: 'menu.my-school-day',
      //   icon: 'filter_vintage',
      //   image: 'assets/icons/Teacher/NavBar/myschoolday.svg',
      //   routerLink: '/teacher/quickaccess/my-school-day'
      // }
    ];

    if (isLead()) {
      menu.push({
        id: 'leadTab',
        title: 'menu.lead',
        icon: 'filter_vintage',
        image: 'assets/icons/Teacher/NavBar/lead.svg',
        routerLink: '/teacher/quickaccess/lead'
      });
    }

    return menu;
  }

  getKidMenu() {
    return [
      {
        id: 'kidSchool',
        title: 'menu.my_school',
        image: 'assets/icons/Kids/my_school.svg',
        routerLink: '/new-kid/school'
      },
      {
        id: 'kidProfile',
        title: 'menu.my_profile',
        image: 'assets/icons/Kids/settings.svg',
        routerLink: '/new-kid/profile'
      },
      {
        id: 'logout',
        title: 'general.logout',
        image: 'assets/icons/Kids/bye.svg',
        logoutAction: true
      }
    ];
  }
  getParentMenu() {
    return [
      {
        id: 'parentProfile',
        title: 'menu.my_profile',
        image: 'assets/icons/Kids/settings.svg',
        routerLink: '/parent/profile'
      },
      {
        id: 'logout',
        title: 'general.logout',
        image: 'assets/icons/Kids/bye.svg',
        logoutAction: true
      }
    ];
  }
}
