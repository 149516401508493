import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable()
export class IsLoggedIn {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  resolve(): void {
    if (this.authorizationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }
}
