import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { hasBrowserSupport } from '@app-core/services/helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportGuard implements CanActivate, CanActivateChild {
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (hasBrowserSupport(state.url)) {
      return true;
    }
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (hasBrowserSupport(state.url)) {
      return true;
    }
  }
}
