import { Roles } from 'src/app/shared/models/roles';

export class NavigationModel {
  model = {
    [Roles.admin]: [
      {
        label: 'Manage Schools',
        link: '/admin/manage-schools'
      },
      {
        label: 'Admin Section',
        link: '/admin/admin-section'
      },
      {
        label: 'Subject Settings',
        link: '/admin/subject-settings'
      }
    ],
    [Roles.teacher]: [],
    [Roles.leadTeacher]: [],
    [Roles.kid]: [],
    [Roles.parent]: []
  };
}

export interface KidMenu {
  id: string;
  title: string;
  icon: string;
  image: string;
  routerLink: string;
  logoutAction?: boolean;
}
