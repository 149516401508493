import * as introJs from 'intro.js/intro';

export function addIntroOptions(options) {
  setTimeout(() => {
    introJs()
      .setOptions(options)
      .onchange(function(targetElement) {
        if (targetElement.className === 'mat-horizontal-stepper-header-container') {
          const objDiv = document.getElementById('page-cnt');
          objDiv.scrollTop = objDiv.scrollHeight / 2;
        } else if (targetElement.id === 'taskImportance') {
          const objDiv = document.getElementById('page-cnt');
          objDiv.scrollTop = objDiv.scrollHeight / 3;
        } else if (targetElement.id === 'addNewVariation') {
          const objDiv = document.getElementById('page-cnt');
          objDiv.scrollTop = 50;
        }
      })
      .start();
  }, 1000);
}
