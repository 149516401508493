import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['./list-select.component.scss']
})
export class ListSelectComponent implements OnInit {
  @Input() items = [];
  @Input() title: string;
  @Input() required: boolean;
  @Input() hasTranslate = false;
  @Input() translateAppendix: string;
  @Output() onSelect = new EventEmitter();
  constructor() {}
  isSubmited = false;
  selected = [];
  ngOnInit() {}

  toggleSelected(index) {
    this.items[index]['selected'] = !this.items[index]['selected'];
    this.selected = this.items.filter(item => item.selected);

    this.onSelect.emit(this.getIds(this.selected));
  }

  /**
   * This function is used to get ids from an array
   * @param array
   */
  private getIds(array): Array<number> {
    const ids = [];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (!element.id) {
        console.error('Iterated array most have an id:', array);
        return;
      }
      ids.push(element.id);
    }
    return ids;
  }
  public validate() {
    this.isSubmited = true;
  }
}
