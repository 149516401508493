import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';

interface TimerRefreshConfigs {
  checkInterval: number;
  increaseInterval: number;
  maxAttempts: number;
}

@Component({
  selector: 'app-c-document-viewer',
  templateUrl: './c-document-viewer.component.html',
  styleUrls: ['./c-document-viewer.component.scss']
})
export class CDocumentViewerComponent implements OnInit, OnDestroy {
  @Input() fileUrl: string;

  readonly assetsUrl = environment.assetsUrl;

  configs = {
    checkInterval: 4000,
    increaseInterval: 1.25,
    maxAttempts: 12
  } as TimerRefreshConfigs;

  fileLoadTimmer: NodeJS.Timer;
  countAtttempts = 0;
  loadFile = true;
  isFileLoaded = false;
  fileLoadFail = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.checkIsFileLoaded();
  }

  ngOnDestroy() {
    if (this.fileLoadTimmer) {
      clearTimeout(this.fileLoadTimmer);
    }
  }

  onFileLoad() {
    this.isFileLoaded = true;
  }

  checkIsFileLoaded() {
    this.fileLoadTimmer = setTimeout(() => {
      if (this.isFileLoaded) {
        return;
      }
      if (this.configs.maxAttempts <= this.countAtttempts) {
        this.fileLoadFail = true;
        return;
      }
      this.countAtttempts++;
      this.refeshFile();
      this.checkIsFileLoaded();
    }, this.refreshTimeInterval);
  }

  refeshFile() {
    this.loadFile = false;
    this.cd.detectChanges();
    this.loadFile = true;
  }

  get refreshTimeInterval() {
    return this.configs.checkInterval * Math.pow(this.configs.increaseInterval, this.countAtttempts);
  }
}
