import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '@app-core/services/app.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { isDefined } from '@app-core/services/helpers/helpers';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { KidGoal, NewUpdateKidGoal } from '../../../../models/overview.model';

@Component({
  selector: 'app-new-update-kid-goal',
  templateUrl: './new-update-kid-goal.component.html',
  styleUrls: ['./new-update-kid-goal.component.scss']
})
export class NewUpdateKidGoalComponent implements OnInit {
  @Input() isYearly = false;
  @Input() goal: KidGoal;
  @Output() change = new EventEmitter();
  @Output() cancel = new EventEmitter();

  today = new Date();
  constructor(private appService: AppService, private snackBarService: SnackBarService, private translate: TranslateService) {}

  ngOnInit() {}

  onGoalCreateUpdate(description: string) {
    const goal = { description: description, isYearly: this.isYearly } as NewUpdateKidGoal;
    if (isDefined(this.goal)) {
      this.doGoalUpdate(goal);
    } else {
      this.doGoalCreate(goal);
    }
  }
  doGoalCreate(goal: NewUpdateKidGoal) {
    this.appService.newElement('/kid-goal', goal).subscribe(() => {
      this.change.emit();
      this.snackBarService.showSuccessMessage('general.inserted');
    });
  }
  doGoalUpdate(goal: NewUpdateKidGoal) {
    this.appService.patchElement(`/kid-goal/${this.goal.id}`, goal).subscribe(() => {
      this.change.emit();
      this.snackBarService.showSuccessMessage('general.updated');
    });
  }

  onCancelAdding() {
    this.cancel.emit();
  }
}
