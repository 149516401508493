import { Router, ActivationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { getKeys, isDefined } from '@app-core/services/helpers/helpers';
import { pagesHistory } from 'src/app/features/analyze/analyze.config';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const FIRSTPAGE = 'firstPage';

interface PageHistory {
  id: number;
  key: string;
  url?: string;
  label?: string;
  extra?: string;
  backRoutes: number[];
}

export interface RouterData {
  key?: string;
  title?: string;
  hasBackNavigation?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private pagesHistory: PageHistory[] = pagesHistory;
  public pagesVisited: PageHistory[] = [];
  public previousPage = {} as PageHistory;

  routerData: BehaviorSubject<RouterData> = new BehaviorSubject<RouterData>({});

  constructor(public router: Router) {
    this.getPagesVisited();
  }

  updateRouterData(urlData: RouterData = {}) {
    Object.assign(this.routerData.value, urlData);
    this.routerData.next(this.routerData.value);
  }

  managePagesHistory(urlData: RouterData) {
    this.routerData.next(urlData);
    if (urlData.key) {
      this.resetHistoryOnFirstPage(urlData.key);
      const currentPage = this.pagesHistory.find(page => page.key === urlData.key);
      if (currentPage) {
        this.managePageVisited(currentPage);
        this.setPreviousPage();
        this.setPagesVisited();
      }
    } else {
    }
  }

  managePageVisited(currentPage: PageHistory) {
    const visitedPageIndex = this.pagesVisited.findIndex(page => page.key === currentPage.key);

    if (visitedPageIndex === -1) {
      this.manageBackRoutes(currentPage);
      this.pagesVisited.push(currentPage);
    } else if (currentPage.id !== this.pagesVisited[this.pagesVisited.length - 1].id) {
      this.pagesVisited.splice(visitedPageIndex + 1, this.pagesVisited.length);
    }
  }

  manageBackRoutes(currentPage: PageHistory) {
    for (const route of currentPage.backRoutes) {
      const index = this.pagesVisited.findIndex(page => page.id === route);

      if (index !== -1) {
        this.pagesVisited.splice(index + 1, this.pagesVisited.length);
        break;
      }
    }
  }

  setPreviousPage() {
    if (this.pagesVisited.length > 1) {
      this.previousPage = this.pagesVisited[this.pagesVisited.length - 2];
    } else {
      this.previousPage = this.pagesHistory[0];
    }
  }

  setPagesVisited() {
    localStorage.setItem('pagesIds', JSON.stringify(getKeys(this.pagesVisited, 'id')));
  }

  getPagesVisited() {
    if (localStorage.hasOwnProperty('pagesIds')) {
      const pagesIds = JSON.parse(localStorage.getItem('pagesIds'));
      // Added Only Previous Pages
      for (let i = 0; i < pagesIds.length - 1; i++) {
        const pageVisited = this.pagesHistory.find(page => page.id === pagesIds[i]);
        this.pagesVisited.push(pageVisited);
      }
    }
  }

  resetHistoryOnFirstPage(pageKey) {
    if (pageKey === FIRSTPAGE) {
      this.pagesVisited = [];
      this.previousPage = {} as PageHistory;
      localStorage.removeItem('pagesIds');
    }
  }

  savePreviousUrl(url: string) {
    localStorage.setItem('previosUrl', url);
  }

  getPreviousUrl() {
    return localStorage.getItem('previosUrl');
  }

  removePreviousUrl() {
    if (isDefined(localStorage.removeItem('previosUrl'))) {
      localStorage.removeItem('previosUrl');
    }
  }
}
