import { OidcConnectInfoDialogComponent } from './../oidc-login/oidc-connect-info-dialog/oidc-connect-info-dialog.component';
import { MatDialog } from '@angular/material';
import { map, filter } from 'rxjs/operators';
import { markFormAsTouched, getSafe } from '@app-core/services/helpers/helpers';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app-core/services/auth.service';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { Roles } from 'src/app/shared/models/roles';
import { SwPush } from '@angular/service-worker';
import { environment } from '@env/environment';
import { AppService } from '@app-core/services/app.service';
import { SharedService } from '../../../shared/services/shared.service';
import { actions, INVALID_ISERVE_CREDENTIALS, styleClass } from '@app-core/services/helpers/config-data';
import { AuthorizationService } from '@app-core/services/authorization/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = environment.vapidPublicKey;
  passwordVisible = false;
  isLoggingIn = false;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private swPush: SwPush,
    private appService: AppService,
    private translate: TranslateService,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loginForm = this.createSignInForm();
    this.handleOpenIdAuthenticate();
  }

  createSignInForm(): FormGroup {
    return this.formBuilder.group({
      username: [null],
      password: [null, Validators.required]
    });
  }

  handleOpenIdAuthenticate() {
    if (environment.openIdAuthMode) {
      this.route.queryParams
        .pipe(
          map(params => params.code),
          filter(Boolean)
        )
        .subscribe(code => {
          this.doLogin('/login-iserv', { code });
        });
    }
  }

  onLogin() {
    if (this.loginForm.valid) {
      this.doLogin('/login', this.loginForm.value);
    } else {
      this.snackBarService.addCustom({
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast);
      markFormAsTouched(this.loginForm);
    }
  }
  doLogin(url: string, loginData: any) {
    this.isLoggingIn = true;
    this.authService.login(url, loginData).subscribe(
      () => {
        const user = this.authorizationService.guideUserToHome();
        if (user === Roles.kid) {
          this.subscribeToNotifications();
          this.getNotifications();
        }
      },
      error => {
        this.isLoggingIn = false;
        if (getSafe(() => error.error.message)) {
          if (error.error.message !== INVALID_ISERVE_CREDENTIALS) {
            this.snackBarService.addCustom({
              message: error.error.message,
              styleClass: styleClass.error
            } as Toast);
          } else {
            this.showConnectToIServDialog();
          }
        }
      }
    );
  }

  showConnectToIServDialog() {
    this.dialog.open(OidcConnectInfoDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant(`general.iserv_connect_info_header`),
        message: this.translate.instant(`general.iserv_connect_info_body`),
        buttonText: {
          cancel: this.translate.instant(`general.close`)
        }
      }
    });
  }

  getNotifications() {
    this.appService.getAll('/notifications?isRead=0').subscribe(response => {
      if (response) {
        this.sharedService.manageNotification(actions.update, response.data.pagination.total_count);
      }
    });
  }

  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
      .then(sub => {
        this.appService.newElement('/subscriptions', sub).subscribe(response => {
          localStorage.setItem('subscriptionId', response.data.subscription_id);
        });
      })
      .catch(err => console.error('Could not subscribe to notifications', err));
  }
  onPasswordToggle() {
    this.passwordVisible = !this.passwordVisible;
  }
}
