import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppService } from '@app-core/services/app.service';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { FileUploaderComponent } from '@shared/features/file-uploader/file-uploader.component';
import { FileViewerComponent } from '@shared/features/file-uploader/file-viewer/file-viewer.component';
import { IDateRange } from '@shared/Interfaces/DateRange';
import { DialogActions } from '@shared/models/dialogActions';
import { SharedService } from '@shared/services/shared.service';
import { Subscription } from 'rxjs-compat/Subscription';
import * as ckEditor from 'src/app/core/libs/ckeditor5/build/ckeditor';

@Component({
  selector: 'app-c-ckeditor',
  templateUrl: './c-ckeditor.component.html',
  styleUrls: ['./c-ckeditor.component.scss']
})
export class CCkeditorComponent implements OnInit {
  @ViewChild('ckeditor', { static: false })
  ckeditor: CKEditorComponent;
  @Input() isForTeacherNotification;
  @Input() placeholder: string;
  @Input() content: string;
  @Output() contentChange = new EventEmitter();

  @Input() disabled = false;
  @Input() showActions = true;
  @Input() isEditing = false;
  @Input() isAutoToggle = false;
  @Input() isTeacher;
  @Input() showImageUploader = false;
  @Output() isEditingChange = new EventEmitter();
  @Output() isUploadingImage = new EventEmitter();

  @Output() onNewContent = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @Input() evaluationId: number;
  dataRange: IDateRange;
  onKidWeeklyEvaluationChange: Subscription;
  onDateChangeSubscription: Subscription;
  attachment: string;

  public Editor = ckEditor;

  ckeConfig = {
    allowedContent: false,
    forcePasteAsPlainText: true,
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'fontsize', 'bulletedList', 'numberedList', 'indent', 'outdent', 'blockQuote', 'link']
  };

  isFullScreen = false;

  constructor(private dialog: MatDialog, private appService: AppService, private sharedService: SharedService) {}

  ngOnInit() {
    this.onDateChangeSubscription = this.sharedService.onSelectDateRange.subscribe(date => {
      this.dataRange = date;

      this.getImageUploaded();
    });

    this.onKidWeeklyEvaluationChange = this.sharedService.kidWeeklyEvaluationChanged.subscribe(() => {
      this.getImageUploaded();
    });
  }
  ngOnDestroy() {
    this.onKidWeeklyEvaluationChange.unsubscribe();
  }
  onToggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.content = this.content;
  }

  onToggleEditing() {
    if (this.disabled) {
      return;
    }
    this.isEditing = !this.isEditing;
  }

  onAddContent() {
    this.handleAutoToggle();
    this.onNewContent.emit(this.content);
  }

  onCancelContent() {
    this.handleAutoToggle();
    this.onCancel.emit();
  }

  handleAutoToggle() {
    if (this.isAutoToggle) {
      this.isEditing = true;
    }
  }

  onReady() {
    this.ckeditor.writeValue(this.content);
    this.ckeditor.editorInstance.editing.view.focus();
  }

  onCkEditorChange() {
    this.contentChange.emit(this.content);
  }

  getImageUploaded() {
    if (this.showImageUploader) {
      this.appService.getAll('/weekly-evaluations', this.dataRange).subscribe(response => {
        if (response.data && response.data.url) {
          this.attachment = response.data.url;
        } else {
          this.attachment = null;
        }
      });
    }
  }

  onImageAdd(event: MouseEvent) {
    event.stopPropagation();
    if (this.evaluationId) {
      this.dialog
        .open(FileUploaderComponent, {
          width: '320px',
          data: {
            url: `/weekly-evaluations?id=${this.evaluationId}`,
            accept: '.png,.jpg,jpeg',
            size: 1
          }
        })
        .afterClosed()
        .subscribe(() => {
          this.appService.getAll('/weekly-evaluations', this.dataRange).subscribe(response => {
            this.attachment = response.data.url;
            this.isUploadingImage.emit('IMAGE_ADDED');
          });
        });
    }
  }

  onImageRemove(event: MouseEvent) {
    event.stopPropagation();
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px'
      })
      .afterClosed()
      .subscribe((action: string) => {
        if (action === DialogActions.DELETE) {
          this.appService.deleteElement(`/weekly-evaluations/${this.evaluationId} `).subscribe(() => {
            this.attachment = null;
            this.isUploadingImage.emit('IMAGE_REMOVE');
          });
        }
      });
  }

  onOpenFileViewer(event: MouseEvent, files) {
    event.stopPropagation();
    this.dialog
      .open(FileViewerComponent, {
        width: '100vw',
        data: { files: files }
      })
      .afterClosed()
      .subscribe();
  }
}
