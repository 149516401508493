import { TranslateService } from '@ngx-translate/core';
import { map, filter } from 'rxjs/operators';
import { isDefined, getStorageItem, setStorageItem, getSafe } from '@app-core/services/helpers/helpers';
import { authConfig } from './../../../features/auth/auth.config';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Roles } from 'src/app/shared/models/roles';
import { environment } from '@env/environment';
import { deepClone } from '../helpers/helpers';
import { SnackBarService, Toast } from '../snackbar/snack-bar.service';
import { storageKeys, styleClass } from '../helpers/config-data';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  @Output() onIServConnect = new EventEmitter();

  authConfig = authConfig;

  constructor(
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private authService: AuthService,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {
    this.handleOpenIdConnection();
  }

  initOpenIdAuthenticate(redirectUri?: string, iServDomain?: string): void {
    if (environment.openIdAuthMode) {
      this.handleOpenIdConfigurations(redirectUri, iServDomain);
      this.oauthService.loadDiscoveryDocument(`${window.location.origin}/assets/openid/openid-configuration-${environment.envName}.json`).then(doc => {
        this.oauthService.tryLoginCodeFlow();
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  handleOpenIdConfigurations(redirectUri?: string, iServDomain?: string): void {
    const authConfigData: AuthConfig = deepClone(this.authConfig);

    if (isDefined(redirectUri)) {
      Object.assign(authConfigData, { redirectUri });
      setStorageItem(storageKeys.redirectUri, redirectUri);
    }

    if (isDefined(iServDomain)) {
      const login_hint = iServDomain.startsWith('domain') ? iServDomain : `domain:${iServDomain}`;
      const customQueryParams = { login_hint };
      Object.assign(authConfigData, { customQueryParams });
      setStorageItem(storageKeys.iServDomain, iServDomain);
    }
    this.oauthService.configure(authConfigData);
  }

  handleOpenIdConnection() {
    if (environment.openIdAuthMode && this.authService.isLoggedIn()) {
      this.route.queryParams
        .pipe(
          map(params => params.code),
          filter(Boolean)
        )
        .subscribe((code: string) => {
          this.doConnection(code);
        });
    }
  }

  doConnection(code: string): void {
    const connectData = {
      code: code,
      redirectUri: getStorageItem(storageKeys.redirectUri),
      loginHint: getStorageItem(storageKeys.iServDomain)
    };

    this.authService.connectAccountToIServ(connectData).subscribe(
      (response: any) => {
        if (isDefined(response)) {
          this.authService.saveLoginData({ data: { user: response.data } });
        }
        this.onIServConnect.emit();
        this.clearIServCode();
        this.snackBarService.addCustom({
          message: this.translate.instant('kid-task.feedback_successful'),
          styleClass: styleClass.success
        } as Toast);
      },
      error => {
        this.clearIServCode();
        if (getSafe(() => error.error.message)) {
          this.snackBarService.addCustom({
            message: error.error.message,
            styleClass: styleClass.error
          } as Toast);
        }
      }
    );
  }

  clearIServCode(): void {
    setStorageItem(storageKeys.redirectUri, null);
    setStorageItem(storageKeys.iServDomain, null);
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
  }

  isAuthorized(allowedRoles: string[]): boolean {
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    // get token from local storage or state management
    const token = localStorage.getItem('token');
    // decode token to read the payload details
    const decodeToken = this.jwtHelperService.decodeToken(token);

    const role = localStorage.getItem('role');
    // check if it was decoded successfully, if not the token is not valid, deny access
    if (!decodeToken) {
      return false;
    }
    // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
    return allowedRoles.includes(role);
  }

  isAuthenticated(): boolean {
    const refreshToken = this.authService.getRefreshToken();
    if (refreshToken) {
      return true;
    } else {
      return false;
    }
  }

  guideUserToHome(): string {
    const role = localStorage.getItem('role');
    switch (role) {
      case Roles.admin:
        this.router.navigate(['admin']);
        break;
      case Roles.teacher:
        this.router.navigate(['teacher/quickaccess/home']);
        break;
      case Roles.leadTeacher:
        this.router.navigate(['teacher/quickaccess/home']);
        break;
      case Roles.kid:
        // this.router.navigate(['kid/home']);
        this.router.navigate(['new-kid/home']);
        break;
      case Roles.parent:
        this.router.navigate(['parent/home']);
        break;
      default:
        break;
    }
    return role;
  }
}
