import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-expert-dialog',
  templateUrl: './expert-dialog.component.html',
  styleUrls: ['./expert-dialog.component.scss']
})
export class ExpertDialogComponent {
  constructor(private translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ExpertDialogComponent>) {}

  onConfirmClick(): void {
    this.dialogRef.close('confirm');
  }
  onClose() {
    this.dialogRef.close('cancel');
  }
  onConfirmSureClick() {
    this.dialogRef.close('delete');
  }
}
