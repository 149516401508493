import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppService } from '@app-core/services/app.service';
import { attachmentTypes, styleClass } from '@app-core/services/helpers/config-data';
import { FileUploaderComponent } from '@shared/features/file-uploader/file-uploader.component';
import { iconType } from '@app-core/services/helpers/config-data';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { DialogActions } from '@shared/models/dialogActions';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { SchoolMaterials } from '@shared/models/school-settings/school-settings';
import { FileViewerComponent } from '@shared/features/file-uploader/file-viewer/file-viewer.component';

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent implements OnInit {
  @Input() schoolMaterials: SchoolMaterials[] = [];
  @Input() material = {} as SchoolMaterials;

  @Output() refreshMaterials = new EventEmitter();

  readonly attachmentTypes = attachmentTypes;

  attachmentType: string;

  constructor(private dialog: MatDialog, private appService: AppService, private snackBarService: SnackBarService, private translate: TranslateService) {}
  iconType = iconType;
  ngOnInit() {}

  onFileUploaderOpen() {
    this.dialog
      .open(FileUploaderComponent, {
        width: '320px',
        data: {
          url: '/school-material',
          accept: '.pdf',
          size: 1
        }
      })
      .afterClosed()
      .subscribe(response => {
        this.refreshMaterials.emit();
      });
  }

  onFileDeleteConfirm(id) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px'
      })
      .afterClosed()
      .subscribe((action: any) => {
        if (action === DialogActions.DELETE) {
          this.deleteFile(id);
        }
      });
  }
  deleteFile(id) {
    this.appService.deleteElement(`/school-material/${id}`).subscribe(() => {
      const toast = {
        message: this.translate.instant('general.attachments-deleted'),
        styleClass: styleClass.success
      } as Toast;
      this.snackBarService.addCustom(toast);
      this.refreshMaterials.emit();
    });
  }

  onOpenFileViewer() {
    this.dialog
      .open(FileViewerComponent, {
        width: '100vw',
        data: { files: this.schoolMaterials }
      })
      .afterClosed()
      .subscribe();
  }
}
