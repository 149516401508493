import { RegisterInfoDialogComponent } from './register-info-dialog/register-info-dialog.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app-core/validators/custom-validators';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { AuthService } from '@app-core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { markFormAsTouched } from '@app-core/services/helpers/helpers';
import { activities, found_from_options, styleClass } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-register-demo',
  templateUrl: './register-demo.component.html',
  styleUrls: ['./register-demo.component.scss']
})
export class RegisterDemoComponent implements OnInit {
  visible = false;
  registerForm: FormGroup;

  readonly activities = activities;
  readonly found_from_options = found_from_options;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.registerForm = this.createRegisterDemoForm();
  }

  createRegisterDemoForm() {
    return this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      phone_no: [null, Validators.required],
      organisation: [null, Validators.required],
      activity: [null, Validators.required],
      city: [null, Validators.required],
      found_from: [null]
    });
  }

  onRegisterSubmit() {
    if (this.registerForm.valid) {
      this.authService.registerDemoAccount(this.registerForm.value).subscribe((response: any) => {
        this.snackBarService.addCustom({
          message: this.translate.instant(`register.done`),
          styleClass: styleClass.success
        } as Toast);
        this.showRegisterInfoDialog();
      });
    } else {
      this.snackBarService.addCustom({
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast);
      markFormAsTouched(this.registerForm);
    }
  }

  showRegisterInfoDialog() {
    this.dialog.open(RegisterInfoDialogComponent, {
      width: '450px',
      data: {}
    });
  }
}
