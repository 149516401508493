import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-rate-lesson-dialog',
  templateUrl: './rate-lesson-dialog.component.html',
  styleUrls: ['./rate-lesson-dialog.component.scss']
})
export class RateLessonDialogComponent implements OnInit {
  rate = 1;
  rateCtl = new FormControl(1);
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<RateLessonDialogComponent>) {}

  ngOnInit() {}
  onConfirm() {
    if (this.rateCtl.valid) {
      this.dialogRef.close(this.rate);
    } else {
      this.rateCtl.markAsTouched();
    }
  }
}
