import { defaultImage } from '@app-core/services/helpers/config-data';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppService } from '@app-core/services/app.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
interface Response {
  data: Data;
}
const ADD = 1;
const REMOVE = -1;

interface Data {
  id: number;
  name: string;
  type: string;
  grades: string[];
  titles: string[];
  categories: Category[];
  subjects: number[];
  ts_created: string;
  ts_last_modified: string;
}

interface Category {
  id: number;
  title: string;
  description: string;
  icon?: string;
  competences: Competence[];
}

interface Competence {
  id: number;
  description: string;
}
@Component({
  selector: 'app-chooser-grid',
  templateUrl: './chooser-grid.component.html',
  styleUrls: ['./chooser-grid.component.scss']
})
export class ChooserGridComponent implements OnInit {
  competenceRaster = {
    categories: []
  } as Data;
  competenceRasters = [];
  competenceSelected: any;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<div class="owl-navs-rounds"><</div>', '<div class="owl-navs-rounds">></div>'],

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
  mainCompetenceRasterId: any;
  phases = [
    {
      title: 'Phase 1'
    },
    {
      title: 'Phase 2'
    },
    {
      title: 'Phase 3'
    },
    {
      title: 'Phase 4'
    },
    {
      title: 'Phase 5'
    },
    {
      title: 'Phase 6'
    },
    {
      title: 'Phase 7'
    },
    {
      title: 'Phase 8'
    },
    {
      title: 'Phase 2'
    }
  ];
  tasks = [
    {
      title: 'Task 1'
    },
    {
      title: 'Task 2'
    },
    {
      title: 'Task 3'
    },
    {
      title: 'Task 4'
    },
    {
      title: 'Task 5'
    },
    {
      title: 'Task 6'
    },
    {
      title: 'Task 7'
    },
    {
      title: 'Task 8'
    },
    {
      title: 'Task 2'
    }
  ];

  topics = [];
  selectedPhase: any;

  defaultImage = defaultImage;

  constructor(private appService: AppService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChooserGridComponent>) {}

  ngOnInit() {
    if (this.data.topicId && !this.data.isSingleCompetenceRaster) {
      this.getTopic();
    } else if (this.data.isSingleCompetenceRaster) {
      this.getCompetenceRastersForSingle();
    }
  }

  getCompetenceRastersForSingle() {
    this.appService.getElement(`/competence-rasters/${this.data.competenceRasterId}/preview`).subscribe(response => {
      this.competenceRaster = response.data[0];
    });
  }

  private getTopic(): void {
    this.appService.getElement(`/topics/${this.data.topicId}`).subscribe((response: any) => {
      const topic = response.data;
      const filterTopicId = this.data.filterByTopicId ? this.data.filterByTopicId : null;
      this.getCompetenceRasters(topic.grade, topic.subject_id, filterTopicId);
      this.mainCompetenceRasterId = topic.main_competence_raster_id;
    });
  }

  /**
   * This is a function that gets dropdown items from service
   */
  getCompetenceRasters(gradeId, subjectId, filterTopicId) {
    const params: any = {
      grades: gradeId,
      subjects: subjectId,
      include_grid: 1
    };

    if (filterTopicId) {
      params['topics'] = filterTopicId;
    }

    if (this.data.soft_skills) {
      params.soft_skills = this.data.soft_skills;
    }
    this.appService.getAll(`/options/competence-rasters`, params).subscribe(response => {
      this.competenceRasters = response.data;
      if (this.data.isUpdate || this.data.onlyView) {
        this.getSelectedCompetences();
      }
      let foundCompetenceRaster = null;
      if (this.mainCompetenceRasterId) {
        foundCompetenceRaster = this.competenceRasters.find(item => item.id === this.mainCompetenceRasterId);
        if (foundCompetenceRaster) {
          this.competenceRaster = foundCompetenceRaster;
        }
      }

      if (!foundCompetenceRaster || !this.mainCompetenceRasterId) {
        if (this.competenceRasters[0]) {
          this.competenceRaster = this.competenceRasters[0];
        }
      }
    });
  }

  chooseCompetenceRaster(competenceRaster) {
    this.competenceRaster = competenceRaster;
  }

  toggleCompetence(category, competence) {
    if (this.data && this.data.isSingleCompetenceRaster) {
      this.competenceSelected = competence;
      this.getTopics(competence);
      return;
    }

    if (!this.data.onlyView) {
      const isCheckedCategory = category['checked'];
      if (isCheckedCategory) {
        let allCompetencesAreChecked = true;
        for (let i = 0; i < category.competences.length; i++) {
          const competenece = category.competences[i];
          if (!competenece['checked']) {
            allCompetencesAreChecked = false;
            break;
          }
        }

        if (allCompetencesAreChecked) {
          category['checked'] = category['checked'] = false;
        }
        competence['checked'] = !competence['checked'];
      } else {
        competence['checked'] = !competence['checked'];
      }
      competence['checked'] ? this.handleCounter(ADD, 1) : this.handleCounter(REMOVE, 1);
    }
  }

  toggleCategory(category) {
    if (!this.data.onlyView) {
      category['checked'] = !category['checked'];
      let nrOfCellsThatChangedState = 0;
      for (let i = 0; i < category.competences.length; i++) {
        const competenece = category.competences[i];
        if (competenece['checked'] !== category['checked']) {
          nrOfCellsThatChangedState++;
          competenece['checked'] = category['checked'];
        }
      }
      category['checked'] ? this.handleCounter(ADD, nrOfCellsThatChangedState) : this.handleCounter(REMOVE, nrOfCellsThatChangedState);
    }
  }
  handleCounter(action, amount) {
    if (isNaN(this.competenceRaster['counter'])) {
      this.competenceRaster['counter'] = 0;
    }
    if (action === ADD) {
      this.competenceRaster['counter'] += amount;
    } else if (action === REMOVE) {
      this.competenceRaster['counter'] -= amount;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirmClick() {
    const competencesSelected = [];

    for (let i = 0; i < this.competenceRasters.length; i++) {
      const competenceRaster = this.competenceRasters[i];

      for (let j = 0; j < competenceRaster.categories.length; j++) {
        const category = competenceRaster.categories[j];

        for (let k = 0; k < category.competences.length; k++) {
          const competence = category.competences[k];
          if (competence['checked']) {
            competencesSelected.push(competence.id);
          }
        }
      }
    }
    this.dialogRef.close(competencesSelected);
  }

  selectPhase(phase) {
    this.selectedPhase = phase;
  }

  getSelectedCompetences() {
    let url;
    if (this.data.taskId) {
      url = `/topics/${this.data.topicId}/phases/${this.data.phaseId}/tasks/${this.data.taskId}/competences`;
    } else {
      if (!this.data.onlyView) {
        url = `/topics/${this.data.topicId}/phases/${this.data.phaseId}/competences`;
      } else {
        url = `/topics/${this.data.topicId}/competences`;
      }
    }
    this.appService.getAll(url).subscribe(response => {
      const competences = response.data;
      for (let i = 0; i < this.competenceRasters.length; i++) {
        const competenceRaster = this.competenceRasters[i];

        for (let j = 0; j < competenceRaster.categories.length; j++) {
          const category = competenceRaster.categories[j];

          for (let k = 0; k < category.competences.length; k++) {
            const competence = category.competences[k];
            const indexOfCompetence = competences.indexOf(competence.id);
            if (indexOfCompetence !== -1) {
              competence['checked'] = true;

              if (isNaN(competenceRaster['counter'])) {
                competenceRaster['counter'] = 0;
              }
              competenceRaster['counter'] += 1;
            }
          }
        }
      }
    });
  }

  getTopics(competence) {
    this.appService.getAll(`/competence-rasters/${this.data.competenceRasterId}/competences/${competence.id}/preview`).subscribe(response => {
      this.topics = response.data;
    });
  }
}
