import { OwlOptions } from 'ngx-owl-carousel-o';

const INVALID_ISERVE_CREDENTIALS = 'Invalid IServ credentials';

const defaultImage = 'assets/img/placeholder.png';
const dashboardImage = 'assets/img/dashboard.svg';
const attachmentImage = 'assets/icons/Kids/attachment.svg';
const sliderScrollDistance = 80;
const UNDEFINED = 'undefined';
const noChoice = 'no_choice';
const emptyCards = [1, 2, 3, 4];
const notificationTimeInterval = 4000;
const imageAspectRatio = 4 / 4;
const activities = [
  { value: 'Grundschule', viewValue: 'activities.primary_school' },
  {
    value: 'Gesamtschule',
    viewValue: 'activities.comprehensive_school'
  },
  { value: 'Gymnasium', viewValue: 'activities.high_school' },
  { value: 'Realschule', viewValue: 'activities.secondary_school' },
  { value: 'Hauptschule', viewValue: 'activities.secondary_schools' },
  { value: 'Förderschule', viewValue: 'activities.special_school' },
  {
    value: 'Berufsbildende Schulen',
    viewValue: 'activities.vocational_schools'
  },
  {
    value: 'Anderer außerschulischer Lernort',
    viewValue: 'activities.another_extracurricular_learning_location'
  },
  { value: 'Berichterstattung', viewValue: 'activities.reporting' },
  {
    value: 'IT-Dienstleister',
    viewValue: 'activities.IT_service_provider'
  },
  { value: 'Schüler/In', viewValue: 'activities.student' },
  { value: 'Eltern', viewValue: 'activities.parents' },
  { value: 'Bildungsträger', viewValue: 'activities.education_provider' },
  { value: 'Stadt/Kommune', viewValue: 'activities.city' },
  { value: 'Sonstiges', viewValue: 'activities.other' }
];

const states = [
  'Baden-Württemberg',
  'Bayern',
  'Berlin',
  'Brandenburg',
  'Bremen',
  'Hamburg',
  'Hessen',
  'Mecklenburg-Vorpommern',
  'Niedersachsen',
  'Nordrhein-Westfalen',
  'Rheinland-Pfalz',
  'Sachsen',
  'Saarland',
  'Sachsen-Anhalt',
  'Schleswig-Holstein',
  'Thüringen',
  'Sonstiges'
];

const found_from_options = [
  { value: 'Internet', viewValue: 'found_from.internet' },
  { value: 'By referal', viewValue: 'found_from.by_referal' },
  { value: 'Internship at a Scobees school', viewValue: 'found_from.scobeesSchool' },
  { value: 'Social Media', viewValue: 'found_from.socialMedia' },
  { value: 'Press', viewValue: 'found_from.press' },
  { value: 'Events', viewValue: 'found_from.events' },
  { value: 'Other', viewValue: 'found_from.other' }
];

const translateEnum = {
  Grundschule: 'activities.primary_school',
  Gesamtschule: 'activities.comprehensive_school',
  Gymnasium: 'activities.high_school',
  Realschule: 'activities.secondary_school',
  Hauptschule: 'activities.secondary_schools',
  Förderschule: 'activities.special_school',
  'Berufsbildende Schulen': 'activities.vocational_schools',
  'Anderer außerschulischer Lernort': 'activities.another_extracurricular_learning_location',
  Berichterstattung: 'activities.reporting',
  'IT-Dienstleister': 'activities.IT_service_provider',
  'Schüler/In': 'activities.student',
  Eltern: 'activities.parents',
  Sonstiges: 'activities.other',
  Bildungsträger: 'activities.education_provider',
  'Stadt/Kommune': 'activities.city'
};

const taskStepsTypes = {
  selfRating: 'selfRating',
  result: 'result',
  solution: 'solution',
  correction: 'correction',
  selfCorrection: 'selfCorrection',
  comments: 'comments'
};

const completeTaskSteps = [
  {
    id: 'selfRating',
    conditionKey: 'allow_self_rating',
    isShown: false
  },
  {
    id: 'result',
    conditionKey: 'upload_results',
    isShown: false
  },
  {
    id: 'comments',
    conditionKey: 'kids_personal_comments',
    isShown: false
  }
];
const redoTaskSteps = [
  {
    id: 'result',
    conditionKey: 'upload_results',
    isShown: false
  },
  {
    id: 'solution',
    conditionKey: 'hasSolution',
    isShown: false
  },
  {
    id: 'correction',
    conditionKey: 'enable_self_correction_feedback',
    isShown: false
  },
  {
    id: 'selfCorrection',
    conditionKey: 'enable_self_correction_feedback',
    isShown: false
  },
  {
    id: 'comments',
    conditionKey: 'kids_personal_comments',
    isShown: false
  }
];
const teacherRatings = ['top_rated', 'medal', 'trophy', 'diamond', 'clover', 'high_five', 'clapping', 'like', 'sun', 'rainbow'];

const styleClass = {
  error: 'error',
  success: 'success',
  successOverlay: 'success-notification-overlay',
  errorOverlay: 'error-notification-overlay'
};

const notificationType = {
  lessonFeedback: 'lesson_feedback',
  teacherTaskFeedback: 'teacher_task_feedback',
  teacherQuickFeedback: 'teacher_quick_feedback',
  teacherVariationFeedback: 'teacher_variation_feedback',
  teacherCommentGoal: 'weekly_goal_comment',
  teacherSharedNotification: 'sharable_notification',
  teacherExamNotification: 'exmas_notification',
  teacherTestNotification: 'tests_notification'
};

export const tasks = [
  {
    variationId: 95869,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,3,11&la=4,5,7'
  },
  {
    variationId: 95879,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,8,31&la=4,5,7&se=1'
  },
  {
    variationId: 95880,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,8,32&la=4,5,7'
  },
  {
    variationId: 95882,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,8,32&la=4,5,7'
  },
  {
    variationId: 95886,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,5,17&la=4,5,7&se=1'
  },
  {
    variationId: 95878,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,6,20&la=4,5,7&se=1'
  },
  {
    variationId: 95881,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,6,22&la=4,5,7&se=1'
  },
  {
    variationId: 95883,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,3,1,5&la=4,5,7&se=1'
  },
  {
    variationId: 95884,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,3,1,4&la=4,5,7&se=1'
  },
  {
    variationId: 95888,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,11,73&la=4,5,7&se=1'
  },
  {
    variationId: 95891,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,11,77&la=4,5,7&se=1'
  },
  {
    variationId: 95892,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,11,60&la=4,5,7&se=1'
  },
  {
    variationId: 95895,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,10,46&la=4,5,7&se=1'
  },
  {
    variationId: 95900,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,1,10,51&la=4,5,7&se=1'
  },
  {
    variationId: 95896,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,2,1,23&la=4,5,7&se=1'
  },
  {
    variationId: 95898,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,2,1,15&la=4,5,7&se=1'
  },
  {
    variationId: 95903,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,2,2,26&la=4,5,7&se=1'
  },
  {
    variationId: 95904,
    starkUrl: 'https://stark.mymathlabglobal.com/info/exercise.aspx?id=st61500e09,2,2,35&la=4,5,7&se=1'
  }
];

const actions = {
  block: 'BLOCK',
  unblock: 'UNBLOCK',
  skip: 'SKIP',
  unskip: 'UNSKIP',
  undone: 'undone',

  update: 'update',
  remove: 'remove',
  decrement: 'decrement',
  increment: 'increment'
};

const pages = {
  categoriesPage: 'categoriesPage'
};

const iconType = {
  logo: 'logo_image',
  bee: 'bee_image',
  owl: 'owl_image',
  minus: 'minus',
  heart: 'heart_image',
  birne: 'birne_image',
  kompass: 'kompass_image'
};

const fileType = {
  result: 'result',
  correction: 'correction',
  solution: 'solution'
};

const panelClass = {
  roundDialog: 'custom-round-dialog'
};

const buttonColors = {
  warn: 'warn'
};
const assetIcons = {
  delete: 'assets/icons/trash.svg'
};
const keyItems = {
  analyzeGrade: 'analyzeGrade',
  analyzeWork: 'analyzeWork',
  category: 'category',
  grade_selected: 'grade_selected',
  dateRange: 'dateRange'
};

const attachmentTypes = {
  attachments: 'attachments',
  solution: 'solution',
  audios: 'audios',
  videos: 'videos',
  audio_descriptions: 'audio_descriptions'
};
const schoolDomains = {
  works: 'works',
  subjects: 'subjects',
  learningLevels: 'learning-levels',
  learningGroups: 'learning-groups'
};
const schoolKeys = {
  learningLevels: 'learning_levels',
  learningGroups: 'learning_groups'
};
const fontSizes = {
  min: 14,
  max: 32
};

const settingDisplay = {
  name: 'name',
  image: 'image',
  name_image: 'name_image'
};

const storageKeys = {
  user: 'user',
  school: 'school',
  redirectUri: 'redirectUri',
  iServDomain: 'iServDomain',
  fontSize: 'fontSize',
  language: 'language'
};

const customOptionsBasicSlider: OwlOptions = {
  mouseDrag: true,
  touchDrag: true,
  pullDrag: false,
  dots: true,
  navSpeed: 300,
  items: 1,
  nav: false,
  loop: false
};

const customOptionsFileSlider: OwlOptions = {
  navText: [
    `<img class="icon-50 img-contain" src="assets/icons/Kids/arrow-left.svg">`,
    `<img class="icon-50 img-contain" src="assets/icons/Kids/arrow-right.svg">`
  ],
  loop: false,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: false,
  dots: true,
  navSpeed: 300,
  items: 1,
  nav: false,
  slideBy: 'page',
  responsive: {
    0: {
      items: 1
    },
    700: {
      items: 2
    },
    1200: {
      items: 3
    }
  }
};

const kidQuickResponse = [
  { type: 'happy', icon: 'assets/icons/quick_response/happy.svg' },
  { type: 'good', icon: 'assets/icons/quick_response/good.svg' },
  { type: 'neutral', icon: 'assets/icons/quick_response/neutral.svg' },
  { type: 'sad', icon: 'assets/icons/quick_response/sad.svg' }
];

const kidWeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const kidWeekDaysMap = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5
};
const teacherProtocolTypes = [
  {
    id: 1,
    name: 'Documentation',
    displayName: 'menu.documentation',
    shareType: [
      { id: 0, name: 'Private', displayName: 'analyze.note_private' },
      { id: 1, name: 'Colleagues', displayName: 'general.colleagues' }
    ]
  },
  {
    id: 2,
    name: 'Coaching',
    displayName: 'menu.coaching',
    shareType: [
      { id: 0, name: 'Private', displayName: 'analyze.note_private' },
      { id: 1, name: 'Student', displayName: 'analyze.kids' }
    ]
  }
];
const parentActivitySortBy = [
  { name: 'Date', displayName: 'general.date' },
  { name: 'Name', displayName: 'general.name' }
];
const WEEKLY = 'WEEKLY';
const YEARLY = 'YEARLY';

const feedbackSectionErrors = {
  key: 'teacher_feedbacks',
  message: '"description" must be a string'
};

const socialFormsError = {
  key: 'social_forms',
  message: '"name" must be a string'
};

const taskSectionErrors = {
  key: 'tasks_type',
  message: '"name" must be a string'
};

export {
  INVALID_ISERVE_CREDENTIALS,
  activities,
  states,
  found_from_options,
  taskStepsTypes,
  completeTaskSteps,
  redoTaskSteps,
  teacherRatings,
  defaultImage,
  dashboardImage,
  attachmentImage,
  UNDEFINED,
  noChoice,
  emptyCards,
  notificationTimeInterval,
  imageAspectRatio,
  sliderScrollDistance,
  translateEnum,
  styleClass,
  notificationType,
  actions,
  pages,
  iconType,
  fileType,
  panelClass,
  buttonColors,
  assetIcons,
  keyItems,
  attachmentTypes,
  schoolDomains,
  schoolKeys,
  fontSizes,
  settingDisplay,
  storageKeys,
  customOptionsBasicSlider,
  customOptionsFileSlider,
  kidQuickResponse,
  teacherProtocolTypes,
  kidWeekDays,
  kidWeekDaysMap,
  parentActivitySortBy,
  WEEKLY,
  YEARLY,
  feedbackSectionErrors,
  taskSectionErrors,
  socialFormsError
};
