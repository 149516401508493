import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { LoggingService } from './log.service';
import { throwError, Observable, of } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { environment } from '@env/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from './helpers/config-data';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { getFileType } from './helpers/helpers';

@Injectable()
export class AppService {
  paging = true;
  itemsPerPage = environment.rows; // server pagination
  page = 1;
  numPages = 1;
  totalItems = 1;

  users: Array<object> = [
    // Add employee object
    {
      id: '1',
      name: 'Jane',
      role: 'Designer',
      pokemon: 'Blastoise'
    },
    {
      id: '2',
      name: 'Bob',
      role: 'Developer',
      pokemon: 'Charizard'
    },
    {
      id: '3',
      name: 'Jim',
      role: 'Developer',
      pokemon: 'Venusaur'
    },
    {
      id: '4',
      name: 'Adam',
      role: 'Designer',
      pokemon: 'Yoshi'
    }
  ];

  private _learningGroupsTopic = [];
  private _learningGroupsPhase = [];
  private _learningGroupsTask = [];

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private sharedService: SharedService,
    private settings: SettingsService,
    private snackBarService: SnackBarService
  ) {}

  returnPhaseLearningGroups() {
    return _.cloneDeep(this._learningGroupsPhase);
  }

  returnTaskLearningGroups() {
    return _.cloneDeep(this._learningGroupsTask);
  }

  setTopicLearningGroups(learningGroups = []) {
    this._learningGroupsTopic = _.cloneDeep(learningGroups);
  }

  setPhaseLearningGroups(learningGroups: any[]) {
    this._learningGroupsPhase = [];
    if (learningGroups.length > 0) {
      for (const itemPhase of learningGroups) {
        const learningGroup = this._learningGroupsTopic.find(item => item.id === itemPhase.id);
        if (learningGroup) {
          this._learningGroupsPhase.push(learningGroup);
        }
      }
    }
  }

  setTaskLearningGroups(learningGroups: any[]) {
    this._learningGroupsTask = [];
    if (learningGroups.length > 0) {
      for (const itemTask of learningGroups) {
        const learningGroup = this._learningGroupsPhase.find(item => item.id === itemTask.id);
        if (learningGroup) {
          this._learningGroupsTask.push(learningGroup);
        }
      }
    }
  }

  all(): Observable<Array<object>> {
    return of(this.users);
  }

  findOne(id: string): Observable<object> {
    const user = this.users.find((u: any) => {
      return u.id === id;
    });
    return of(user);
  }

  // Rest Items List Service
  getList(path, filters) {
    // stringa per chiamata da fare
    let queryStr = '';
    let i = 0;
    let stringAmp = '';
    // imposto i parametri del filtro
    if (filters != null) {
      Object.keys(filters).forEach(function(key) {
        if (filters[key] != null && filters[key].toString() !== '') {
          if (i > 0) {
            stringAmp = '&';
          }
          queryStr = queryStr + stringAmp + key + '=' + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr = environment.restBaseUrl + path + '?' + queryStr + '&paging=' + this.paging + '&page=' + this.page + '&rows=' + this.itemsPerPage;

    // clean url
    queryStr = queryStr.replace('?&', '?');

    this.logger.log('Chiamata URL lista:' + queryStr, '', 200);

    return this.http.get<any[]>(queryStr, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const data = response['data'];
        // this.totalItems = data['total'];

        if (outcome.success === true) {
          this.logger.log('Service:', 'SUCCESS', 200);
          return data;
        } else {
          this.logger.log('Service:', 'FAILURE', 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // Rest Items Service: Read one element (detail)
  getElement(path, params = null) {
    path = environment.restBaseUrl + path;
    return this.http.get<any[]>(path, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        // if (res.body.success === true) {
        // this.updateAuthorizationHeader(response);
        this.logger.log('Service:', 'SUCCESS', 200);
        // } else {
        //   this.logger.log('Service:', 'FAILURE', 200);
        // res.message = this.settings.manageErrorMsg(res.body.error);
        // }
        return res.body;
      })
    );
  }

  // Rest Items Service: Read all REST Items
  getAll(path, params?: any) {
    path = environment.restBaseUrl + path;
    return this.http.get<any[]>(path, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        // if (res.body.success === true) {
        //   // this.updateAuthorizationHeader(response);
        //   this.logger.log('Service:', 'SUCCESS', 200);
        // } else {
        //   this.logger.log('Service:', 'FAILURE', 200);
        //   res.message = this.settings.manageErrorMsg(res.body.error);
        //   res.message = this.settings.manageErrorMsg(res.body.error);
        // }
        return res.body;
      })
      // catchError((errorResponse: HttpErrorResponse) => {
      //   const res: any = errorResponse;
      //   if (res.body.code === '0004') {
      //     this.settings.sessionExpired();
      //   }
      //   return throwError(errorResponse);
      // })
    );
  }

  // get element list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http.post<any[]>(path, requestData, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log('Service:', 'SUCCESS', 200);
          return dataResponse;
        } else {
          this.logger.log('Service:', 'FAILURE', 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log('Error Response:', errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // creation of new element
  newElement(path, elementRequest, params?: any): any {
    path = environment.restBaseUrl + path;
    return this.http.post<any[]>(path, elementRequest, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        return res.body;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse;
        const toast = {
          message: res.error.message,
          styleClass: styleClass.error
        } as Toast;
        this.snackBarService.addCustom(toast);
        return throwError(errorResponse);
      })
    );
  }

  // modify of an element
  editElement(path, elementRequest, params?): any {
    path = environment.restBaseUrl + path;
    return this.http.put<any[]>(path, elementRequest, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        // if (res.body.success === true) {
        //   // this.updateAuthorizationHeader(response);
        //   this.logger.log('UPDATE:', 'SUCCESS', 200);
        // } else {
        //   this.logger.log('UPDATE:', 'FAILURE', 200);
        //   res.message = this.settings.manageErrorMsg(res.body.error);
        // }
        return res.body;
      })

      // catchError((errorResponse: HttpErrorResponse) => {
      // const res: any = errorResponse;
      // if (res.body.code === '0004') {
      //   this.settings.sessionExpired();
      // }
      // return throwError(errorResponse);
      // })
    );
  }

  // patch of an element
  patchElement(path, elementRequest, params?): any {
    path = environment.restBaseUrl + path;
    return this.http.patch<any[]>(path, elementRequest, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        // if (res.body.success === true) {
        //   // this.updateAuthorizationHeader(response);
        //   this.logger.log('UPDATE:', 'SUCCESS', 200);
        // } else {
        //   this.logger.log('UPDATE:', 'FAILURE', 200);
        //   res.message = this.settings.manageErrorMsg(res.body.error);
        // }
        return res.body;
      })
      // catchError((errorResponse: HttpErrorResponse) => {
      //   return throwError(errorResponse);
      // })
    );
  }
  // modify of an element
  deleteElement(path, params?): any {
    path = environment.restBaseUrl + path;
    return this.http.delete<any[]>(path, this.getRequestOptionArgs(params)).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        // if (res.body.success === true) {
        //   // this.updateAuthorizationHeader(response);
        //   this.logger.log('Delete:', 'SUCCESS', 200);
        // } else {
        //   this.logger.log('Delete:', 'FAILURE', 200);
        //   res.message = this.settings.manageErrorMsg(res.body.error);
        // }
        return res.body;
      })
      //   ,
      //   catchError((errorResponse: HttpErrorResponse) => {
      //     const res: any = errorResponse;
      //     if (res.body.code === '0004') {
      //       this.settings.sessionExpired();
      //     }
      //     return throwError(errorResponse);
      //   })
    );
  }

  invite(idUser: number): any {
    const path = environment.restBaseUrl + '/admin/user/invite/' + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http.put<any[]>(path, elementRequest, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log('Service:', 'SUCCESS', 200);
          return response;
        } else {
          this.logger.log('Service:', 'FAILURE', 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log('Error Response:', errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // updateAuthorizationHeader(response) {
  //   const jwtToken = response.headers.get('Authorization');
  //   localStorage.setItem('token', jwtToken);
  // }

  getRequestOptionArgs(params?: HttpParams): any {
    const httpOptions = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // Authorization:
        //   localStorage.getItem('token') != null
        //     ? localStorage.getItem('token')
        //     : ''
      }),
      params: params
    };
    return httpOptions;
  }

  downloadFile(filePath): Promise<boolean> {
    return new Promise(resolve => {
      this.http.get(filePath, this.getHttpOptionsBlob()).subscribe(
        (blob: HttpResponse<Blob>) => {
          if (blob) {
            saveAs(blob.body, this.getFilename(blob.headers, getFileType(filePath)));
            resolve(true);
          }
        },
        () => {
          resolve(true);
        }
      );
    });
  }

  getHttpOptionsBlob() {
    return {
      headers: this.getBasicHttpHeaders(),
      observe: 'response' as 'response',
      responseType: 'blob' as 'json'
    };
  }

  getBasicHttpHeaders() {
    return new HttpHeaders({});
  }
  getFilename(headers, fileType: string) {
    const contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
      return `${contentDisposition.split('=')[1].replace(/"/g, '')}.${fileType}`;
    } else {
      return `${new Date().getTime()}.${fileType}`;
    }
  }
}
