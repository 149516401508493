import { environment } from './../../../../environments/environment';
import { getSchoolIcon, getStorageObjectItem } from '@app-core/services/helpers/helpers';
import { actions, styleClass, iconType, storageKeys, notificationTimeInterval } from '@app-core/services/helpers/config-data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '@app-core/services/app.service';
import { MenuService } from '@app-core/services/menu/menu.service';
import { AuthService } from '@app-core/services/auth.service';
import { slider } from '@app-core/animations/route.animations';
import { Toast, SnackBarService } from '@app-core/services/snackbar/snack-bar.service';

import { User } from 'src/app/shared/models/user/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AddTodoKidDialogComponent } from 'src/app/features/kid/dialogs/add-todo-kid-dialog/add-todo-kid-dialog.component';

import { AddReflectionKidDialogComponent } from 'src/app/features/kid/dialogs/add-reflection-kid-dialog/add-reflection-kid-dialog.component';
import { SimpleDialogComponent } from 'src/app/shared/features/simple-dialog/simple-dialog.component';
import { RateLessonDialogComponent } from 'src/app/features/kid/dialogs/rate-lesson-dialog/rate-lesson-dialog.component';

@Component({
  selector: 'app-bottom-sheet-overview-example-sheet',
  templateUrl: 'bottom-sheet/bottom-sheet.component.html'
})
export class BottomSheetOverviewExampleSheetComponent {
  options = [
    {
      title: 'general.goal',
      description: 'general.create_goal',
      id: 'goal'
    },
    {
      title: 'general.reflection',
      description: 'general.insert',
      id: 'reflection'
    },
    {
      title: 'general.todo',
      description: 'general.create_todo',
      id: 'todo'
    }
  ];

  constructor(
    private sharedService: SharedService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private appService: AppService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheetComponent>
  ) {}

  openReflection(): void {
    const redirectUrl = '/kid/heart';
    this.bottomSheet
      .open(AddReflectionKidDialogComponent, {
        data: {
          redirectUrl: redirectUrl
        }
      })
      .afterDismissed()
      .subscribe(todo => {
        if (todo) {
          this.insertReflection(todo, redirectUrl);
        }
      });
  }

  openLink(event, option): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
    this.manageInsert(option);
  }

  manageInsert(option) {
    switch (option.id) {
      case 'goal':
        this.openTodo(true);
        break;
      case 'todo':
        this.openTodo(false);
        break;
      case 'reflection':
        this.openReflection();
        break;
      default:
        break;
    }
  }

  openTodo(isGoal): void {
    const redirectUrl = '/kid/todo';
    this.bottomSheet
      .open(AddTodoKidDialogComponent, {
        data: {
          redirectUrl: redirectUrl,
          is_weekly_goal: isGoal
        }
      })
      .afterDismissed()
      .subscribe(todo => {
        if (todo) {
          this.insertTodo(todo, redirectUrl);
        }
      });
  }

  insertTodo(todo, redirectUrl) {
    this.appService.newElement('/todos', todo).subscribe(response => {
      const toast = {
        message: this.translate.instant(`todos.todo-created`),
        styleClass: styleClass.success
      } as Toast;
      if (this.router.url !== redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.sharedService.onTodoInserted.emit();
      }
      this.snackBarService.addCustom(toast);
    });
  }

  insertReflection(todo, redirectUrl) {
    this.appService.newElement('/proud-of', todo).subscribe(response => {
      const toast = {
        message: this.translate.instant(`todos.todo-reflection`),
        styleClass: styleClass.success
      } as Toast;
      if (this.router.url !== redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.sharedService.onReflectionInserted.emit();
      }
      this.snackBarService.addCustom(toast);
    });
  }
}

@Component({
  selector: 'app-kid-layout',
  templateUrl: './kid-layout.component.html',
  styleUrls: ['./kid-layout.component.scss'],
  animations: [slider]
})
export class KidLayoutComponent implements OnInit, OnDestroy {
  title: string;
  backPath: string;
  today = new Date();
  user = {} as User;
  menuItems = [];
  notifications = [];
  layout = {
    withBottomBar: true,
    navLinks: [
      {
        path: 'home',
        icon: getSchoolIcon(iconType.bee)
      },
      {
        path: 'todo',
        icon: getSchoolIcon(iconType.owl)
      },

      {
        path: 'plus',
        icon: 'assets/icons/Kids/BottomNav/plus.svg'
      }
      // {
      //   path: 'settings',
      //   icon: 'assets/icons/Kids/BottomNav/settings.svg'
      // }
    ]
  };
  url = '';
  readonly school = getStorageObjectItem(storageKeys.school);
  hasBigIcons = false;

  notificationInterval: NodeJS.Timer;

  constructor(
    private swPush: SwPush,
    private bottomSheet: MatBottomSheet,
    private appService: AppService,
    private router: Router,
    private menuService: MenuService,
    private authService: AuthService,
    private sharedService: SharedService,
    private location: Location,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.url = this.router.url;

    setInterval(() => {
      this.today = new Date();
    }, 1000);

    this.setTitle(this.router.routerState.snapshot.root);

    this.menuItems = this.menuService.getMenu();

    this.router.events.subscribe(e => {
      this.url = this.router.url;
      if (e instanceof NavigationEnd) {
        this.setTitle(this.router.routerState.snapshot.root);
      }
    });

    this.handleNavBar();
    this.handleNotifications();
  }

  ngOnDestroy() {
    clearInterval(this.notificationInterval);
    if (this.swPush.isEnabled) {
      this.swPush.unsubscribe().then();
    }
  }

  handleNotifications() {
    if (!this.swPush.isEnabled && environment.production) {
      this.getNotificationsByInterval();
      return;
    }

    this.getNotifications();
    this.swPush.messages.subscribe((res: any) => {
      if (res.notification.data.type === 'lesson_feedback') {
        this.rateLesson(res.notification.data);
      }
      this.getNotifications();
    });

    this.swPush.notificationClicks.subscribe(() => {
      this.router.navigate(['kid/home']);
    });
  }

  handleNavBar() {
    if (this.school.navbar_size === 'big') {
      this.hasBigIcons = true;
    }
  }

  getNotificationsByInterval() {
    clearInterval(this.notificationInterval);
    this.notificationInterval = setInterval(() => {
      this.getNotifications();
    }, notificationTimeInterval);
  }

  getNotifications() {
    this.appService.getAll('/notifications?isRead=0').subscribe(response => {
      if (response) {
        this.notifications = response.data.page;
        this.sharedService.manageNotification(actions.update, response.data.pagination.total_count);
      }
    });
  }

  setTitle(snapshot: ActivatedRouteSnapshot) {
    let lastChild = snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    this.title = lastChild.data.title;
    this.backPath = lastChild.data.backPath;
  }

  logout() {
    this.authService.logOut().subscribe(() => {});
  }

  openBottomSheet(): void {
    this.bottomSheet.open(BottomSheetOverviewExampleSheetComponent);
  }

  back() {
    if (this.backPath !== 'STACK_BACK') {
      this.router.navigate([this.backPath]);
    } else {
      this.location.back();
    }
  }

  markAsRead(notification) {
    if (!notification.is_read) {
      notification.is_read = true;
      this.appService.editElement('/notifications/' + notification.id + '/read', {}).subscribe(response => {});
    } else {
      this.router.navigate(['/kid/notifications/' + notification.id]);
    }
  }

  rateLesson(notification) {
    this.dialog
      .open(RateLessonDialogComponent, {
        data: { message: notification }
      })
      .afterClosed()
      .subscribe(rate => {
        if (rate) {
          this.appService
            .newElement(`/rate/lessons/${notification.metadata.lessonId}`, {
              rate: rate,
              notification_id: notification.id
            })
            .subscribe(() => {
              const toast = {
                message: this.translate.instant(`rate.thanks`),
                styleClass: styleClass.success
              } as Toast;
              this.snackBarService.addCustom(toast);
              notification.metadata.lessonRate = rate;
              this.markAsRead(notification);
            });
        }
      });
  }

  requestLogout() {
    this.dialog
      .open(SimpleDialogComponent, {
        width: '250px',
        data: {
          icon: 'power_settings_new',
          title: 'general.logout',
          message: 'general.logout_dialog'
        },
        panelClass: 'simpledialog'
      })
      .afterClosed()
      .subscribe(decision => {
        if (decision === 'YES') {
          this.logout();
        }
      });
  }
}
