import { PlyrModule } from 'ngx-plyr';
import { EmptyCardComponent } from './features/cards/empty-card/empty-card.component';
import { MatStepperModule } from '@angular/material/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatSliderModule,
  MatRadioModule,
  MatProgressBarModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatTableModule,
  MatSortModule
} from '@angular/material/';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatAutocompleteModule, MatPaginatorModule } from '@angular/material';
import { PageErrorComponent } from './features/page-error/page-error.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchModule } from './features/search/search.module';
import { ListSelectComponent } from './features/list-select/list-select.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DebounceClickDirective } from '@app-core/directives/debounce-click.directive';
import { SanitizeHtmlPipe } from '@app-core/pipes/sanitize-html.pipe';
import { IconTitleModule } from './features/icon-title/icon-title.module';
import { SimpleDialogComponent } from './features/simple-dialog/simple-dialog.component';
import { MobileLoaderComponent } from './features/mobile-loader/mobile-loader.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { KidNavComponent } from '../features/kid/kid-nav/kid-nav.component';
import { RosetteIconComponent } from './features/rosette-icon/rosette-icon.component';
import { VariationTypeIconComponent } from './features/variation-type-icon/variation-type-icon.component';
import { HelpMenuComponent } from './features/help-menu/help-menu.component';
import { SubjectNavigationBoxComponent } from './features/subject-navigation-box/subject-navigation-box.component';
import { AnalyzeProgressBarComponent } from './features/analyze-progress-bar/analyze-progress-bar.component';
import { TeacherDropdownComponent } from './features/teacher-dropdown/teacher-dropdown.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AnalyzeToDoComponent } from './features/analyze-to-do/analyze-to-do.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { DateRangeComponent } from './features/date-range/date-range.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { LearningGroupsTagsComponent } from '../features/analyze/analyze/shared/learning-groups-tags/learning-groups-tags.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BreadcrumbsComponent } from './features/breadcrumbs/breadcrumbs.component';
import { NavigateCardComponent } from './features/cards/navigate-card/navigate-card.component';
import { DateRangeValueDirective } from './directives/date-range/date-range-value.directive';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FontSizeControllerComponent } from './features/font-size-controller/font-size-controller.component';
import { OverflowDirective } from './directives/overflow.directive';
import { EmojiPickerModule } from './features/emoji-picker/emoji-picker.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ShortNamePipe } from '@app-core/pipes/short-name/short-name.pipe';
import { PdfUploadComponent } from './features/pdf-upload/pdf-upload/pdf-upload.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FavoriteProgressChartComponent } from '../features/analyze/analyze/pages/favorites/progress-chart/progress-chart.component';
import { CCkeditorComponent } from '../features/new-kid/kid-components/c-ckeditor/c-ckeditor.component';
import { QuickResponseMenuComponent } from '../features/new-kid/kid-components/dialogs/quick-response-menu/quick-response-menu.component';
import { IconLabelComponent } from '../features/new-kid/kid-components/icon-label/icon-label.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DateAdapter } from '@angular/material';
import { DateFormat } from '../shared/custom-date-adapter';
import { KidYearlyAchievmentsComponent } from './features/kid-yearly-achievments/kid-yearly-achievments.component';
import { NewKidModule } from '../features/new-kid/new-kid.module';
import { KidAchievementTagComponent } from '../features/new-kid/kid-achievements/kid-weekly-achievements/kid-achievement-tag/kid-achievement-tag.component';

@NgModule({
  declarations: [
    PageErrorComponent,
    KidNavComponent,
    ListSelectComponent,
    DebounceClickDirective,
    SanitizeHtmlPipe,
    ShortNamePipe,
    SimpleDialogComponent,
    MobileLoaderComponent,
    RosetteIconComponent,
    VariationTypeIconComponent,
    HelpMenuComponent,
    SubjectNavigationBoxComponent,
    AnalyzeProgressBarComponent,
    TeacherDropdownComponent,
    ClickOutsideDirective,
    OverflowDirective,
    AnalyzeToDoComponent,
    DateRangeComponent,
    LearningGroupsTagsComponent,
    BreadcrumbsComponent,
    NavigateCardComponent,
    EmptyCardComponent,
    DateRangeValueDirective,
    FontSizeControllerComponent,
    OverflowDirective,

    PdfUploadComponent,
    FavoriteProgressChartComponent,

    CCkeditorComponent,
    QuickResponseMenuComponent,
    IconLabelComponent,
    KidYearlyAchievmentsComponent,
    KidAchievementTagComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDocViewerModule,
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonToggleModule,
    AngularFontAwesomeModule,
    FlexLayoutModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SearchModule,
    PerfectScrollbarModule,
    IconTitleModule,
    InfiniteScrollModule,
    ScrollingModule,
    RouterModule,
    SatDatepickerModule,
    SatNativeDateModule,
    VirtualScrollerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    PickerModule,
    EmojiPickerModule,
    PlyrModule,
    NgxDaterangepickerMd.forRoot(),
    CKEditorModule
  ],
  exports: [
    // modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconTitleModule,
    TranslateModule,
    KidNavComponent,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    AngularFontAwesomeModule,
    PickerModule,
    EmojiPickerModule,
    PlyrModule,
    NgxDaterangepickerMd,

    FlexLayoutModule,
    NgSelectModule,
    // components
    PageErrorComponent,
    ListSelectComponent,
    DebounceClickDirective,
    SubjectNavigationBoxComponent,
    AnalyzeProgressBarComponent,
    TeacherDropdownComponent,
    DateRangeComponent,
    DateRangeValueDirective,

    SearchModule,
    SanitizeHtmlPipe,
    ShortNamePipe,
    MobileLoaderComponent,
    RosetteIconComponent,
    VariationTypeIconComponent,
    HelpMenuComponent,
    ClickOutsideDirective,
    OverflowDirective,
    AnalyzeToDoComponent,
    InfiniteScrollModule,
    SatDatepickerModule,
    SatNativeDateModule,
    LearningGroupsTagsComponent,
    VirtualScrollerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BreadcrumbsComponent,
    NavigateCardComponent,
    EmptyCardComponent,
    FontSizeControllerComponent,
    PdfUploadComponent,
    FavoriteProgressChartComponent,
    CCkeditorComponent,
    QuickResponseMenuComponent,
    IconLabelComponent,
    KidYearlyAchievmentsComponent,
    KidAchievementTagComponent
  ],
  entryComponents: [SimpleDialogComponent],
  providers: [[{ provide: DateAdapter, useClass: DateFormat }]]
})
export class SharedModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('en-GB'); // DD/MM/YYYY
  }
}
