import { defaultImage } from '@app-core/services/helpers/config-data';
import { TaskImportance, TaskType } from 'src/app/shared/models/school-settings/school-settings';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-variation-type-icon',
  templateUrl: './variation-type-icon.component.html',
  styleUrls: ['./variation-type-icon.component.scss']
})
export class VariationTypeIconComponent implements OnInit {
  @Input() type: TaskType;
  @Input() importance: TaskImportance;

  @Input() size = 40;
  @Input() isAlwaysShown = false;

  readonly defaultImage = defaultImage;

  constructor() {}

  ngOnInit() {}
}
