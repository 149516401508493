import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material';
import { assetIcons } from '@app-core/services/helpers/config-data';
import { QuickResponse } from '../../../models/overview.model';

@Component({
  selector: 'app-quick-response-menu',
  templateUrl: './quick-response-menu.component.html',
  styleUrls: ['./quick-response-menu.component.scss']
})
export class QuickResponseMenuComponent implements OnInit {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  @Input() quickResponse: string;
  @Output() change = new EventEmitter();
  @Input() isNotification = false;

  kidQuickResponse = [
    { type: 'ok', icon: 'assets/icons/quick_response/ok.svg' },
    { type: 'happy', icon: 'assets/icons/quick_response/happy.svg' },
    { type: 'neutral', icon: 'assets/icons/quick_response/neutral.svg' },
    { type: 'really_sad', icon: 'assets/icons/quick_response/really_sad.svg' },
    { type: 'angry', icon: 'assets/icons/quick_response/angry.svg' }
  ];

  readonly assetIcons = assetIcons;

  constructor() {}

  ngOnInit() {}

  onQuickResponseChange(quickResponse?: QuickResponse) {
    this.change.emit(quickResponse ? quickResponse.type : null);
  }
}
