import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '@env/environment';
import { AuthService } from '@app-core/services/auth.service';

export interface UploadProgress {
  [key: string]: {
    progress: Observable<number>;
  };
}

@Injectable()
export class UploadService {
  constructor(private authService: AuthService, private http: HttpClient) {}
  public upload(files: Set<File>, url, method = 'POST'): { [key: string]: { progress: Observable<number> } } {
    // this will be the our resulting map
    const status = {} as UploadProgress;

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('attachment', file, file.name);

      // get token of user

      const token = localStorage.getItem('token');

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest(method, environment.restBaseUrl + url + `?ngsw-bypass=1`, formData, {
        reportProgress: true,
        headers: new HttpHeaders({
          Authorization: token
        })
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            // calculate the progress percentage
            const percentDone = Math.round((100 * event.loaded) / event.total);

            // pass the percentage into the progress-stream
            progress.next(percentDone);
          } else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            progress.complete();
          }
        },
        err => {}
      );

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }
}
