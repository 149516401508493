import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { styleClass } from '@app-core/services/helpers/config-data';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';

@Component({
  selector: 'app-add-one-dialog',
  templateUrl: './add-one-dialog.component.html',
  styleUrls: ['./add-one-dialog.component.scss']
})
export class AddOneDialogComponent implements OnInit {
  control = new FormControl(null, [Validators.required]);
  isTextAreaInput = false;

  constructor(
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddOneDialogComponent>
  ) {
    if (!data) {
      console.error('You need to pass data to dialog options');
    } else {
      if (data.value) {
        this.control.setValue(data.value);
      }
      this.isTextAreaInput = data.isTextAreaInput ? data.isTextAreaInput : false;
    }
  }

  ngOnInit() {}

  onSaveDialog() {
    if (this.control.valid) {
      this.dialogRef.close(this.control.value !== '' ? this.control.value : null);
    } else {
      this.control.markAllAsTouched();
      this.snackBarService.addCustom({
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast);
    }
  }
}
