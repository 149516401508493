import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogActions } from '../../../models/dialogActions';
import { DOCUMENT } from '@angular/common';
import { AppService } from '@app-core/services/app.service';
import { styleClass } from '@app-core/services/helpers/config-data';
import { Toast, SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { IKidInfo } from '@shared/Interfaces/ParentInterface';
import { QrCodeToPdf } from '@app-core/services/helpers/qrcode.helpers.ts';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteDialogComponent {
  invitationLink: string;
  email: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: IKidInfo,
    private dialogRef: MatDialogRef<InviteDialogComponent>,
    private appService: AppService,
    private translate: TranslateService,
    private snackBarService: SnackBarService
  ) {
    this.data = data;
    this.invitationLink = `${this.document.location.origin}/register-parent/${this.data.kidCode}`;
    this.email = data.parentEmail;
  }

  onLinkCopy(inputLink: HTMLInputElement) {
    inputLink.select();
    document.execCommand('copy');
  }

  onInviteViaEmail() {
    this.appService
      .newElement('/parents/invite', {
        email: this.email,
        code: this.data.kidCode
      })
      .subscribe((response: any) => {
        const toast = {
          message: this.translate.instant('parent.email_invitation_send'),
          styleClass: styleClass.success
        } as Toast;
        this.snackBarService.addCustom(toast);
      });
  }
  onEmailInsert() {
    this.appService
      .patchElement(`/kids/${this.data.id}`, {
        parent_email: this.email
      })
      .subscribe((response: any) => {
        const toast = {
          message: this.translate.instant('parent.email_added'),
          styleClass: styleClass.success
        } as Toast;
        this.snackBarService.addCustom(toast);
      });
  }
  onPdfExport() {
    const element = document.getElementById('qrCode').childNodes[0];

    return QrCodeToPdf.downloadPdf(element, {
      title: this.translate.instant('qrcode_as_pdf.title'),
      body: this.translate.instant('qrcode_as_pdf.body'),
      fileName: this.translate.instant('qrcode_as_pdf.qrCode_title'),
      text: this.translate.instant('qrcode_as_pdf.text'),
      kidName: this.data.kidName
    });
  }

  onClose() {
    this.dialogRef.close(DialogActions.CANCEL);
  }
}
