import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-learning-groups-tags',
  templateUrl: './learning-groups-tags.component.html',
  styleUrls: ['./learning-groups-tags.component.scss']
})
export class LearningGroupsTagsComponent implements OnInit {
  @Input() learningGroups: string | string[];

  constructor() {}

  ngOnInit() {
    if (this.learningGroups && typeof this.learningGroups === 'string') {
      this.learningGroups = [this.learningGroups];
    }
  }
}
