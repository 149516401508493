import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analyze-to-do',
  templateUrl: './analyze-to-do.component.html',
  styleUrls: ['./analyze-to-do.component.scss']
})
export class AnalyzeToDoComponent implements OnInit {
  @Input() weeklyGoal: number;
  @Input() completedTasks: number;
  @Input() totalTasks: number;

  constructor() {}

  calcCompletition() {
    if (this.completedTasks !== 0) {
      this.weeklyGoal = this.totalTasks - this.completedTasks;
      return this.weeklyGoal;
    }
  }

  ngOnInit() {}
}
