import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppService } from '@app-core/services/app.service';
import { formatDate } from '@app-core/services/helpers/helpers';
import { SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { ConfirmDialogComponent } from '@shared/features/dialog/confirm-dialog/confirm-dialog.component';
import { DialogActions } from '@shared/models/dialogActions';
import { KidGoal } from '../../models/overview.model';

@Component({
  selector: 'app-kid-goals',
  templateUrl: './kid-goals.component.html',
  styleUrls: ['./kid-goals.component.scss']
})
export class KidGoalsComponent implements OnInit {
  @Input() isYearly = false;
  @Input() kidGoals: KidGoal[] = [];
  @Output() refreshGoals = new EventEmitter();

  showGoalForm = false;
  goalSelected: KidGoal;

  constructor(private appService: AppService, private dialog: MatDialog, private snackBarService: SnackBarService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  onToggleForm() {
    this.showGoalForm = !this.showGoalForm;
    this.goalSelected = null;
  }

  onGoalUpdate(goal: KidGoal) {
    this.showGoalForm = false;
    this.cd.detectChanges();
    this.goalSelected = goal;
    this.showGoalForm = true;
  }

  onGoalReset(goal: KidGoal) {
    this.appService.patchElement(`/kid-goal/${goal.id}`, { startDate: formatDate(new Date()) } as KidGoal).subscribe(() => {
      this.refreshGoalList();
      this.snackBarService.showSuccessMessage('general.updated');
    });
  }

  onGoalConfirmDelete(goal: KidGoal) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px'
      })
      .afterClosed()
      .subscribe((action: string) => {
        if (action === DialogActions.DELETE) {
          this.deleteGoal(goal);
        }
      });
  }

  deleteGoal(goal: KidGoal) {
    this.appService.deleteElement(`/kid-goal/${goal.id}`).subscribe(() => {
      this.refreshGoals.emit();
    });
  }

  refreshGoalList() {
    this.showGoalForm = false;
    this.goalSelected = null;
    this.refreshGoals.emit();
  }
}
