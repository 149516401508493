import { iconType } from '@app-core/services/helpers/config-data';
import { Component, Input, OnInit } from '@angular/core';
import { getSchoolIcon } from '@app-core/services/helpers/helpers';

@Component({
  selector: 'app-rosette-icon',
  templateUrl: './rosette-icon.component.html',
  styleUrls: ['./rosette-icon.component.scss']
})
export class RosetteIconComponent implements OnInit {
  @Input() type = '';
  iconType = iconType;
  beeIcon = getSchoolIcon(iconType.bee);

  constructor() {}

  ngOnInit() {}
}
