import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { RegisterDemoComponent } from './register-demo/register-demo.component';
import { RegisterInfoDialogComponent } from './register-demo/register-info-dialog/register-info-dialog.component';
import { OidcLoginComponent } from './oidc-login/oidc-login.component';
import { RegisterParentComponent } from './register-parent/register-parent.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from 'saturn-datepicker';
import { CustomDateAdapter } from '@shared/custom-date-adapter';
import { DateAdapter } from '@angular/material';
import { OidcConnectInfoDialogComponent } from './oidc-login/oidc-connect-info-dialog/oidc-connect-info-dialog.component';
import { NotMyChildComponent } from './not-my-child/not-my-child.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AccessDeniedComponent,
    RegisterDemoComponent,
    RegisterInfoDialogComponent,
    OidcLoginComponent,
    RegisterParentComponent,
    OidcConnectInfoDialogComponent,
    NotMyChildComponent
  ],
  providers: [],
  imports: [CommonModule, SharedModule, RouterModule],
  entryComponents: [RegisterInfoDialogComponent, OidcConnectInfoDialogComponent]
})
export class AuthModule {}
