import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app-core/services/auth.service';
import { SnackBarService, Toast } from '@app-core/services/snackbar/snack-bar.service';
import { styleClass } from '@app-core/services/helpers/config-data';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    });
  }

  forgotPassword() {
    for (const i in this.forgotPasswordForm.controls) {
      if (!this.forgotPasswordForm.controls.hasOwnProperty(i)) {
        continue;
      }
      this.forgotPasswordForm.controls[i].markAsTouched();
    }
    let toast = {} as Toast;
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.email).subscribe((res: any) => {
        toast = {
          message: this.translate.instant(`forgot-password.email-sent`),
          styleClass: styleClass.success
        } as Toast;
        this.router.navigate(['../login']);
        this.snackBarService.addCustom(toast);
      });
    } else {
      toast = {
        message: this.translate.instant(`general.complete_fields`),
        styleClass: styleClass.error
      } as Toast;
      this.snackBarService.addCustom(toast);
    }
  }
}
